import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import { getMyProfile, logout } from '../../bll/login/operations'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import Main from './main'
import Loader from '../UIComponents/Loader/Loader'

const MainContainer = (props: any) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleExitApp, setIsVisibleExitApp] = React.useState<boolean>(false) // Видимость окна выхода из приложения

    React.useEffect(() => {
        props.getMyProfile().then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0009, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Функция выхода из приложения
    const exitApp = () => {
        props.logout(navigate).then((result: any) => {            
            switch (result?.code) {
                case "OK": navigate('/'); break
                case "ERR_QUERY_STRING": notifiMsg(props.msg.error.E0010, "ERROR"); break
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })

        setIsVisibleExitApp(false)
    }

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }
    
    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleExitApp, setIsVisibleExitApp, // Видимость окна выхода из приложения
        exitApp, // Функция выхода из приложения
    }

    return (
        <>
            {!props.isAppInitialized
                ? <Loader open={props.isAppInitialized ? false : true} color='#FF6700' title='Загрузка...' />
                : <Main {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        isAuthorized: state.auth.isAuthorized,
        isAppInitialized: state.auth.isAppInitialized,
        userProfile: state.auth.userProfile,
    }
}

export default compose(connect(mapState, { getMyProfile, logout }))(MainContainer)