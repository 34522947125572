import { Tooltip, Image } from 'antd'
import React from 'react'
import Button from '../../../UIComponents/Button/Button'
import { IExamStart } from './exam-start.interfaces'
import css from './exam-start.module.scss'
import Empty from '../../../UIComponents/Empty/Empty'
import imgEmpty from './../../../../images/empty_documents.svg'
import Input from '../../../UIComponents/Input/Input'
import { BeatLoader } from 'react-spinners'
import Modal from '../../../UIComponents/Modal/Modal'

const ExamStart: React.FC<IExamStart> = (props) => {
    const [visibleImg, setVisibleImg] = React.useState<boolean>(false) // Открыто или закрыто окно, просмотр всех изображений
    const [visibleSingleImg, setVisibleSingleImg] = React.useState<boolean>(false) // Открыто или закрыто окно, просмотр индивидуальный
    const [imgPath, setImgPath] = React.useState<string>('') // Путь к изображению, для индивидуального предпросмотра изображения

    return (
        <>
            <ExitApp {...props} />

            <header className='container-header'>
                <div className='container-header--title'>
                    <h4>{props.examInfo?.subjectName}</h4>
                    <h5>Экзамен от {new Date(props.examInfo?.examDateStart).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</h5>
                </div>

                <aside className='container-header--panel'>
                    {props.referenceMaterial?.length !== 0 &&
                        <Button variant='secondary' size='sm' onClick={() => setVisibleImg(true)}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 5.33325C17.4696 5.33325 16.9609 5.54397 16.5858 5.91904C16.2107 6.29411 16 6.80282 16 7.33325C16 7.86369 16.2107 8.37239 16.5858 8.74747C16.9609 9.12254 17.4696 9.33325 18 9.33325C18.5304 9.33325 19.0391 9.12254 19.4142 8.74747C19.7893 8.37239 20 7.86369 20 7.33325C20 6.80282 19.7893 6.29411 19.4142 5.91904C19.0391 5.54397 18.5304 5.33325 18 5.33325ZM17.52 11.6933C15.9333 11.8266 11.6 15.2799 11.6 15.2799C11.3333 15.4799 11.4133 15.4666 11.6267 15.8399C11.84 16.1999 11.8133 16.2266 12.0667 16.0533C12.3333 15.8799 12.7733 15.5999 13.5067 15.1466C16.3333 13.3333 13.96 17.5199 12.7467 24.5733C12.2667 28.0666 15.4133 26.2666 16.2267 25.7333C17.0267 25.2133 19.1733 23.7333 19.3867 23.5866C19.68 23.3866 19.4667 23.2266 19.24 22.8933C19.08 22.6666 18.92 22.8266 18.92 22.8266C18.0533 23.3999 16.4667 24.5999 16.2533 23.8399C16 23.0799 17.6267 17.8666 18.52 14.2799C18.6667 13.4266 19.0667 11.5599 17.52 11.6933Z" fill="currentColor" />
                            </svg>

                            <span>Справочный материал</span>
                        </Button>
                    }
                </aside>
            </header>
        
            <main className='container-main'>
                <article className={`container-main--body ${css['tBody']}`}>
                    {!props.localCorrectAnswers?.length
                        ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='300px' />
                        : props.localCorrectAnswers.map((item) => {
                            return (
                                <div key={item.examParticipantAnswerID} className={`${css['question']}`}>
                                    <img src={item.questionImgPath} alt={`Вопрос № ${item.questionNumber}`} onClick={(e) => { setImgPath(item.questionImgPath); setVisibleSingleImg(true); e.stopPropagation() }} />

                                    <Input variant='primary' type='text' placeholder={`Введите ответ на вопрос № ${item.questionNumber}`}
                                        value={item?.answer || ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.regexCorrectAnswer(item.answerRegular, item.examParticipantAnswerID, e.target.value)}
                                        prefix={
                                            <div style={{ width: '24px', height: '24px' }}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.26 3.60022L5.04997 12.2902C4.73997 12.6202 4.43997 13.2702 4.37997 13.7202L4.00997 16.9602C3.87997 18.1302 4.71997 18.9302 5.87997 18.7302L9.09997 18.1802C9.54997 18.1002 10.18 17.7702 10.49 17.4302L18.7 8.74022C20.12 7.24022 20.76 5.53022 18.55 3.44022C16.35 1.37022 14.68 2.10022 13.26 3.60022Z" stroke="currentColor" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.89 5.0498C12.32 7.8098 14.56 9.9198 17.34 10.1998" stroke="currentColor" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 22H21" stroke="currentColor" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        }
                                        suffix={
                                            props.sendCorrectAnswerID === item.examParticipantAnswerID && props.timerSendCorrectAnswerID !== null
                                                ? <BeatLoader color="#FF6700" size={14} />
                                                : item?.answer
                                                    ? <Tooltip title="Ответ принят" placement='bottomRight'><div style={{ color: '#32D732' }}>✔</div></Tooltip>
                                                    : <Tooltip title="Ответ отсутствует" placement='bottomRight'><div style={{ color: '#FF6700' }}>✘</div></Tooltip>
                                        }
                                    />

                                    <span className='dividerSecondary'></span>

                                    <div className={`${css['question-info']}`}>
                                        <span>{`${item.answerTypeName} (${item.answerTypeDescription})`}</span>
                                        <span>{`Вопрос № ${item.questionNumber}`}</span>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <Button variant='primary' size='lg' width={'100%'} onClick={() => props.setIsVisibleEndExam(true)}>
                        <span>ЗАВЕРШИТЬ ЭКЗАМЕН</span>
                    </Button>
                </article>

                <div style={{ gap: '5px', display: 'flex', flexDirection: 'column', fontSize: '20px', color: '#32D732', letterSpacing: '1px' }}>
                    <div>* Из <b>{props.localCorrectAnswers?.length}</b> вопросов, ответили на <b>{props.localCorrectAnswers?.reduce((count, question) => { return question.answer ? count + 1 : count }, 0)}</b>.</div>
                    <div>* До завершения экзамена осталось: <b>{props.examInfo?.examMinutes}</b> мин.</div>
                    <div>* Кликнув левой кнопкой мыши по вопросу, он откроется для возможности его увеличения.</div>
                </div>
            </main>

            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible: visibleImg, onVisibleChange: vis => setVisibleImg(vis) }}>
                    {!props.referenceMaterial ? 'УПС' : props.referenceMaterial.map((item: any) => {
                        return (
                            <Image key={item.referenceMaterialID} src={item.imgPath} />
                        )
                    })}
                </Image.PreviewGroup>

                <Image preview={{ visible: visibleSingleImg, src: imgPath, onVisibleChange: (value) => { setImgPath(''); setVisibleSingleImg(value) } }} />
            </div>

        </>
    )
}

// Подтверждение участником завершение экзамена
const ExitApp: React.FC<IExamStart> = (props) => {
    return (
        <Modal open={props.isVisibleEndExam}>
            <section className='modalPrimary' style={{ width: '550px' }}>
                <header className='modalHeader'>
                    <h5>Завершение экзамена...</h5>
                </header>

                <main className='modalContent'>
                    <div className='modalContainer'>
                        Внимание! Нажав на кнопку «ПОДТВЕРЖДАЮ», Вы даёте свое согласие на завершение экзамена.
                        Вернуться снова к экзамену будет не возможно!
                        <br/><br/>Вы действительно хотите завершить сдачу данного экзамена?
                    </div>

                    <footer className='modalFooter'>
                        <Button variant='secondary' size='md' onClick={() => props.setIsVisibleEndExam(false)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                            </svg>

                            <span>ОТМЕНА</span>
                        </Button>

                        <Button variant='primary' size='md' onClick={props.applyEndExam}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                            </svg>

                            <span>ПОДТВЕРЖДАЮ</span>
                        </Button>
                    </footer>
                </main>
            </section>
        </Modal>
    )
}

export default ExamStart