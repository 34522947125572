import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import { clearTestVariants, createTestVariant, deleteTestVariant, getTestVariants } from '../../bll/tests/operations'
import Loader from '../UIComponents/Loader/Loader'
import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import TestVariants from './testVariants'

const TestVariantsContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleModalCreateVariant, setIsVisibleModalCreateVariant] = React.useState<boolean>(false) // Видимость окна, создание варианта
    const [isVisibleModalPasswordConfirm, setIsVisibleModalPasswordConfirm] = React.useState<boolean>(false) // Видимость окна подтверждения для ввода пароля
    
    const [selectedVariantID, setSelectedVariantID] = React.useState<string | undefined>() // Выбранный идентификатор варианта
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string | undefined>() // Пароль оператора, для подтверждения

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getTestVariants(getLocation.state.testID) ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0019, "ERROR")
            // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
            // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0018, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearTestVariants()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Открытие окна подтверждения для ввода пароля
    const openModalPasswordConfirm = (isOpen: boolean, id: string = '') => {
        setSelectedVariantID(id)
        setValueLoginPassword('')

        setIsVisibleModalPasswordConfirm(isOpen)
    }

    // Создание варианта
    const createVariant = () => {
        props.createTestVariant(getLocation.state.testID).then((result: any) => {
            switch (result?.code) {
                case 'OK': setIsVisibleModalCreateVariant(false); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Удаление варианта
    const deleteVariant = () => {
        props.deleteTestVariant(getLocation.state.testID, selectedVariantID, valueLoginPassword).then((result: any) => {
            switch (result?.code) {
                case 'OK': openModalPasswordConfirm(false); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        testID: getLocation.state.testID,
        subjectName: getLocation.state.subject,
        basisTrainingName: getLocation.state.basisTraining,
        countQuestions: getLocation.state.countQuestions,

        isVisibleModalCreateVariant, setIsVisibleModalCreateVariant, // Видимость окна, создание варианта
        isVisibleModalPasswordConfirm, // Видимость окна подтверждения для ввода пароля

        openModalPasswordConfirm, // Открытие окна подтверждения для ввода пароля
        valueLoginPassword, setValueLoginPassword, // Пароль оператора, для подтверждения

        createVariant, // Создание варианта
        deleteVariant, // Удаление варианта
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <TestVariants {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        testsVariantsList: state.tests.testsVariantsList
    }
}

export default compose(connect(mapState, {
    getTestVariants, clearTestVariants, createTestVariant, deleteTestVariant
}))(TestVariantsContainer)