import React, { useEffect, useState } from 'react'

import { Tooltip } from 'antd'

import Button from '../Button/Button'
import Empty from '../Empty/Empty'
import css from './Select.module.scss'
import imgEmpty from './empty_documents.svg'

export type SelectOptionType = {
    id: string | number
    label: string
}

interface SelectPropsType {
    options: SelectOptionType[]
    value?: SelectOptionType
    onChange: (value: SelectOptionType | undefined) => void
    placeholder?: string
    size?: 'lg' | 'md' | 'sm' | 'xs'
    prefix?: React.ReactNode
    heightOptionsList?: string | number | undefined
}

const Select: React.FC<SelectPropsType> = ({options, value, onChange, placeholder, size = 'lg', prefix, heightOptionsList = '150px'}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = React.useState(0)

    const clearOptions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onChange(undefined)
    }

    const selectOption = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, option: SelectOptionType) => {
        e.stopPropagation()
        if (option !== value) onChange(option)
        setIsOpen(false)
    }

    const isOptionSelected = (option: SelectOptionType) => {
        return option.id === value?.id
    }

    useEffect(() => {
        if (isOpen) setHighlightedIndex(-1)
    }, [isOpen])

    return (
        <div onBlur={() => setIsOpen(false)} onClick={() => setIsOpen(e => !e)} tabIndex={0} className={`${css.container} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`}>
            {prefix}
            {/* <input className={css.value} autoFocus type="text" placeholder={value?.label} /> */}
            <span className={`${css.value} ${value ? css.secondary : css.primary}`}>{value ? value.label : placeholder}</span>
            
            {value &&
                <Tooltip title="Очистить" placement='left'>
                    <div>
                        <Button variant='default' size='xs' onClick={e => clearOptions(e)}>
                            <div style={{ width: '24px', height: '24px' }}>
                                <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_239_32)">
                                        <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_239_32">
                                            <rect width="26" height="26" fill="currentColor" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </Button>
                    </div>
                </Tooltip>
            }
            
            {/* <div className={css.iconOpen}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.8839 15.5303C12.3957 16.0185 11.6043 16.0185 11.1161 15.5303L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="currentColor" />
                </svg>
            </div> */}


            <div className={`${css.optionsMain} ${isOpen ? css.show : ""}`} style={{ height: heightOptionsList }}>
                <ul className={`${css.options}`}>
                    {!options.length
                        ? <Empty image={imgEmpty} size='100px' />
                        : options.map((option, index) => (
                            <li key={option.id}
                                onClick={(e) => selectOption(e, option)}
                                onMouseEnter={() => setHighlightedIndex(index)}
                                className={`
                                ${css.option} 
                                ${isOptionSelected(option) ? css.selected : ""} 
                                ${index === highlightedIndex ? css.highlighted : ""}
                            `}
                            >{option.label}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Select