import React from 'react'
import ParticipantResult from './participant-result'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AppStateType } from '../../../../bll/store'
import { clearParticipantResult, getParticipantResult } from '../../../../bll/exams/exams.operations'
import { useNavigate, useParams } from 'react-router-dom'
import { NotificationContext } from '../../../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../../../bll/errorHandler'
import Loader from '../../../UIComponents/Loader/Loader'

const ParticipantResultContainer: React.FC<any> = (props) => {
    const urlParams = useParams()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isResultNotFound, setIsResultNotFound] = React.useState<boolean>(false) // Метка о том что результат не найден
    const [isViolationParticipant, setIsViolationParticipant] = React.useState<boolean>(false) // Метка о том что участник удалет с экзамена


    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getParticipantResult('', urlParams.id) ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING' && item.code !== 'RESULT_NOT_FOUND' && item.code !== 'PARTICIPANT_IS_VIOLATION') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'RESULT_NOT_FOUND') { setIsResultNotFound(true) }
            if (result[0]?.code === 'PARTICIPANT_IS_VIOLATION') { setIsViolationParticipant(true) }

            if (result[0]?.code === 'ERR_QUERY_STRING') { notifiMsg(props.msg.error.E0032, "ERROR") }
            
            setIsVisibleLoader(false)
        })

        return () => {
            props.clearParticipantResult()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isResultNotFound, // Метка о том что результат не найден
        isViolationParticipant, // Метка о том что результат не найден
    }

    return (
        <>
        {isVisibleLoader
            ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
            : <ParticipantResult {...props} {...propsToComponent} />
        }
    </>
    )
}

const mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        participantResult: state.exams.participantResult,
    }
}

export default compose(connect(mapState, { getParticipantResult, clearParticipantResult }))(ParticipantResultContainer)