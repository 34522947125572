import React from 'react'

import css from './Button.module.scss'

type MyType = {
    id?: string | undefined,
    children: React.ReactNode,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    type?: 'button' | 'submit' | 'reset' | undefined,
    className?: string | undefined,
    variant: 'primary' | 'secondary' | 'default',
    size?: 'lg' | 'md' | 'sm' | 'xs',
    width?: string | number | undefined
    tooltip?: string
    disabled?: boolean
}

const Button: React.FC<MyType> = ({ id, type, children, onClick, className, variant, size = 'lg', width, disabled }) => {
    return (
        <button 
            id={id}
            onClick={onClick}
            type={type ? type: 'button'}
            disabled={disabled}
            className={className ? className : `${
                (variant === 'primary' && `${css.primary} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`) || 
                (variant === 'secondary' && `${css.secondary} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`) ||
                (variant === 'default' && `${css.default} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`)
            }`}
            style={{ width: width }}
        >{children}</button>
    )
}

export default Button