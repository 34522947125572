import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import { auth, checkAuth, clearStore } from '../../bll/login/operations'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import Login from './login'

const LoginContainer = (props: any) => {
    const navigate = useNavigate()

    const notification = React.useContext(NotificationContext)
    const [isPasswordVisible, setIsPasswordVisible] = React.useState<boolean>(false) // Видимость пароля в текстовом виде
    
    const [valueLogin, setValueLogin] = React.useState<string>('') // Значения поля, Логин пользователя
    const [valuePassword, setValuePasword] = React.useState<string>('') // Значения поля, Пароль пользователя


    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            props.checkAuth() // Проверка авторизации оператора
        } else { props.clearStore() }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Кнопкка войти в приложение
    const onClickInput = () => {
        if (valueLogin && valuePassword) { 
            props.auth(valueLogin, valuePassword).then((result: any) => {
                switch (result?.code) {
                    case 'OK': break
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0008, "ERROR")
                    case "OPERATOR_NOT_FOUND": { return notifiMsg(props.msg.info.I0001, "INFO")}

                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        } else {
            notifiMsg(props.msg.warning.W0001, 'WARNING')
        }
    }

    // При нажатии кнопки Enter на полях ввода
    const onKeyPressInput = (event: any) => {
        if (event.key === 'Enter') {
            onClickInput()
        }
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isPasswordVisible, setIsPasswordVisible, // Видимость пароля в текстовом виде
        
        valueLogin, setValueLogin, // Значения поля, Логин пользователя
        valuePassword, setValuePasword, // Значения поля, Пароль пользователя
    
        onClickInput, // Кнопкка войти в приложение
        onKeyPressInput, // При нажатии кнопки Enter на полях ввода
    }

    if (props.isAuthorized) {
        return <Navigate to="/main" />
    }

    return (
        <Login {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        isAuthorized: state.auth.isAuthorized
    }
}

export default compose(connect(mapState, { auth, checkAuth, clearStore }))(LoginContainer)