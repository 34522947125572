import { Tooltip } from 'antd'
import React from 'react'
import Button from '../../UIComponents/Button/Button'
import Drawer from '../../UIComponents/Drawer/Drawer'
import Empty from '../../UIComponents/Empty/Empty'
import Input from '../../UIComponents/Input/Input'
import Loader from '../../UIComponents/Loader/Loader'

import imgEmpty from './../../../images/empty_documents.svg'
import { IUserAccess } from './user-access.interfaces'

import css from './user-access.module.scss'

const UserAccess: React.FC<IUserAccess> = (props) => {
    return (
        <Drawer size={`900px`} isOpen={props.isOpen} placement='right'>
            <section className='drawerPrimary'>
                {props.isVisibleLoader
                    ? <Loader open={props.isVisibleLoader} color='#FF6700' title='Загрузка...' />
                    : <main className='drawerContent'>
                        <header className='drawerHeader'>
                            <div className='drawer-header--title'>
                                <h4>Допуск к экзамену</h4>
                                <h5>{props.testName}</h5>
                            </div>

                            <div className='drawer-header--nav'>
                                <Button variant='default' size='xs' onClick={() => props.onClose(false)}>
                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                                    </svg>
                                </Button>
                            </div>
                        </header>

                        <section className='drawerContainerSecondary'>
                            <UnassignedUsers {...props} />

                            <div className='dividerPrimary'></div>

                            <div className='drawer-nav'>
                                <Tooltip title="Добавить" placement='top'>
                                    <div>
                                        <Button variant='secondary' size='xs' onClick={() => props.addUser()} disabled={props.selectedUnassignedUserID ? false : true}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.3998 11.4001L5.9248 2.7751C5.5873 2.4376 5.0623 2.4376 4.7248 2.7751C4.3873 3.1126 4.3873 3.6376 4.7248 3.9751L12.5998 12.0001L4.7248 20.0251C4.3873 20.3626 4.3873 20.8876 4.7248 21.2251C4.8748 21.3751 5.0998 21.4501 5.3248 21.4501C5.5498 21.4501 5.7748 21.3751 5.9248 21.1876L14.3998 12.5626C14.7373 12.2626 14.7373 11.7376 14.3998 11.4001Z" fill="currentColor" />
                                                <path d="M19.2748 11.4001L10.7998 2.7751C10.4623 2.4376 9.9373 2.4376 9.5998 2.7751C9.2623 3.1126 9.2623 3.6376 9.5998 3.9751L17.4748 12.0001L9.5998 20.0251C9.2623 20.3626 9.2623 20.8876 9.5998 21.2251C9.7498 21.3751 9.9748 21.4501 10.1998 21.4501C10.4248 21.4501 10.6498 21.3751 10.7998 21.1876L19.2748 12.5626C19.6123 12.2626 19.6123 11.7376 19.2748 11.4001Z" fill="currentColor" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Удалить" placement='bottom'>
                                    <div>
                                        <Button variant='secondary' size='xs' onClick={() => props.deleteUser()} disabled={props.selectedAssignedUserID ? false : true}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.3999 12.0001L19.3124 3.9751C19.6499 3.6376 19.6499 3.1126 19.3124 2.7751C18.9749 2.4376 18.4499 2.4376 18.1124 2.7751L9.6374 11.4001C9.2999 11.7376 9.2999 12.2626 9.6374 12.6001L18.1124 21.2251C18.2624 21.3751 18.4874 21.4876 18.7124 21.4876C18.9374 21.4876 19.1249 21.4126 19.3124 21.2626C19.6499 20.9251 19.6499 20.4001 19.3124 20.0626L11.3999 12.0001Z" fill="currentColor" />
                                                <path d="M6.5249 12.0001L14.4374 3.9751C14.7749 3.6376 14.7749 3.1126 14.4374 2.7751C14.0999 2.4376 13.5749 2.4376 13.2374 2.7751L4.7624 11.4001C4.4249 11.7376 4.4249 12.2626 4.7624 12.6001L13.2374 21.2251C13.3874 21.3751 13.6124 21.4876 13.8374 21.4876C14.0624 21.4876 14.2499 21.4126 14.4374 21.2626C14.7749 20.9251 14.7749 20.4001 14.4374 20.0626L6.5249 12.0001Z" fill="currentColor" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>

                            <div className='dividerPrimary'></div>

                            <AssignedUsers {...props} />
                        </section>
                    </main>
                }
            </section>
        </Drawer>
    )
}


// Не назначенные пользователи
const UnassignedUsers: React.FC<IUserAccess> = (props) => {
    return (
        <div className='items-container'>
            <h5>Не допущенные</h5>

            <Input variant='primary' size='md' type='text' placeholder='Поиск...' paddingRight={3}
                value={props.valueSearchUnassignedUsers} disabled={!props.unassignedUsersList?.length ? true : false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setValueSearchUnassignedUsers(e.target.value)}
                prefix={
                    <div style={{ width: '24px', height: '24px' }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 3.75C6.77208 3.75 3.75 6.77208 3.75 10.5C3.75 14.2279 6.77208 17.25 10.5 17.25C12.3642 17.25 14.0506 16.4953 15.273 15.273C16.4953 14.0506 17.25 12.3642 17.25 10.5C17.25 6.77208 14.2279 3.75 10.5 3.75ZM2.25 10.5C2.25 5.94365 5.94365 2.25 10.5 2.25C15.0563 2.25 18.75 5.94365 18.75 10.5C18.75 12.5078 18.032 14.3491 16.8399 15.7793L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.7793 16.8399C14.3491 18.032 12.5078 18.75 10.5 18.75C5.94365 18.75 2.25 15.0563 2.25 10.5Z" fill="currentColor" />
                        </svg>
                    </div>
                }

                suffix={
                    <>
                        {props.valueSearchUnassignedUsers && <Tooltip title="Очистить" placement='bottom'>
                            <div>
                                <Button variant='default' size='xs' onClick={() => props.setValueSearchUnassignedUsers('')}>
                                    <div style={{ width: '24px', height: '24px' }}>
                                        <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_239_32)">
                                                <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_239_32">
                                                    <rect width="26" height="26" fill="currentColor" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </Button>
                            </div>
                        </Tooltip>}
                    </>
                }
            />

            <section className='drawerItems'>
                {!props.unassignedUsersList?.length
                    ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='150px' />
                    : props.unassignedUsersList.filter(val => {
                        const { lastName, firstName, secondName } = val
                        const searchValue = props.valueSearchUnassignedUsers.toLowerCase()
                        return (lastName.toLowerCase().includes(searchValue) || firstName.toLowerCase().includes(searchValue) || secondName.toLowerCase().includes(searchValue)) ? val : false
                    }).map((item) => {
                        return (
                            <div key={item.loginID} className={props.selectedUnassignedUserID === item.loginID ? `${css['row-item']} ${css['row-selected']}` : css['row-item']} onClick={() => props.setSelectedUnassignedUserID(item.loginID)}>
                                <div>{`${item.lastName} ${item.firstName} ${item.secondName}`}</div>
                                <div className={css['item--secondary']}>{item.accessLevelName}</div>
                            </div>
                        )
                    })
                }
            </section>
        </div>
    )
}

// Назначенные пользователи
const AssignedUsers: React.FC<IUserAccess> = (props) => {
    return (
        <div className='items-container'>
            <h5>Допущенные</h5>

            <Input variant='primary' size='md' type='text' placeholder='Поиск...' paddingRight={3}
                value={props.valueSearchAssignedUsers} disabled={!props.assignedUsersList?.length ? true : false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setValueSearchAssignedUsers(e.target.value)}
                prefix={
                    <div style={{ width: '24px', height: '24px' }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 3.75C6.77208 3.75 3.75 6.77208 3.75 10.5C3.75 14.2279 6.77208 17.25 10.5 17.25C12.3642 17.25 14.0506 16.4953 15.273 15.273C16.4953 14.0506 17.25 12.3642 17.25 10.5C17.25 6.77208 14.2279 3.75 10.5 3.75ZM2.25 10.5C2.25 5.94365 5.94365 2.25 10.5 2.25C15.0563 2.25 18.75 5.94365 18.75 10.5C18.75 12.5078 18.032 14.3491 16.8399 15.7793L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.7793 16.8399C14.3491 18.032 12.5078 18.75 10.5 18.75C5.94365 18.75 2.25 15.0563 2.25 10.5Z" fill="currentColor" />
                        </svg>
                    </div>
                }

                suffix={
                    <>
                        {props.valueSearchAssignedUsers && <Tooltip title="Очистить" placement='bottom'>
                            <div>
                                <Button variant='default' size='xs' onClick={() => props.setValueSearchAssignedUsers('')}>
                                    <div style={{ width: '24px', height: '24px' }}>
                                        <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_239_32)">
                                                <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_239_32">
                                                    <rect width="26" height="26" fill="currentColor" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </Button>
                            </div>
                        </Tooltip>}
                    </>
                }
            />

            <section className='drawerItems'>
                {!props.assignedUsersList?.length
                    ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='150px' />
                    : props.assignedUsersList.filter(val => {
                        const { lastName, firstName, secondName } = val
                        const searchValue = props.valueSearchAssignedUsers.toLowerCase()
                        return (lastName.toLowerCase().includes(searchValue) || firstName.toLowerCase().includes(searchValue) || secondName.toLowerCase().includes(searchValue)) ? val : false
                    }).map((item) => {
                        return (
                            <div key={item.loginID} className={props.selectedAssignedUserID === item.loginID ? `${css['row-item']} ${css['row-selected']}` : css['row-item']} onClick={() => props.setSelectedAssignedUserID(item.loginID)}>
                                <div>{`${item.lastName} ${item.firstName} ${item.secondName}`}</div>
                                <div className={css['item--secondary']}>{item.accessLevelName}</div>
                            </div>
                        )
                    })
                }
            </section>
        </div>
    )
}

export default UserAccess