import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppStateType } from '../../../bll/store'
import errorHandler from '../../../bll/errorHandler'
import TranslationScale from './translation-scale'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import { clearTranslationScale, getTranslationScale, updateTranslationScale } from '../../../bll/tests/operations'
import { TestsTranslationScaleListType } from '../../../bll/tests/types'

const TranslationScaleContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [localTranslationScale, setLocalTranslationScale] = React.useState<TestsTranslationScaleListType[] | null>(null) // Локальный state шкалы

    const [timerSendTranslationScaleID, setTimerSendTranslationScaleID] = React.useState<number | null>(null) // Идентификатор таймера отправки данных на сервер
    const [sendTranslationScaleID, setSendTranslationScaleID] = React.useState<string | number | null>(null) // Идентификатор редактируемого тестового балла


    React.useEffect(() => {
        if (props.isOpen) {
            setIsVisibleLoader(true)

            Promise.all([ props.getTranslationScale(props.testID) ]).then((result) => {
                let isGlobalError = false
    
                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })
    
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0027, "ERROR")
                // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
                // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")
    
                setIsVisibleLoader(false)
            })
        }

        return () => {
            props.clearTranslationScale()
            setLocalTranslationScale(null)
        }
    }, [props.isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.testsTranslationScaleList) {
            setLocalTranslationScale(props.testsTranslationScaleList)
        }
    }, [props.testsTranslationScaleList])

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Проверка по регулярке, вводимость тестового балла
    const regexTranslationScale = (translationScaleID: string | number, value: string) => {
        setSendTranslationScaleID(translationScaleID)

        const re: RegExp = new RegExp(/^[1-9][0-9]*$/, 'g')
        if (value === '' || re.test(value)) {
            if (Array.isArray(localTranslationScale)) {
                const updatedTranslationScale = localTranslationScale.map((item) => {
                    if (item.translationScaleID === translationScaleID) {
                        return { ...item, finalBal: value.length === 0 ? 1 : value }
                    }

                    return item
                })

                setLocalTranslationScale(updatedTranslationScale)

                // Если таймер уже запущен, то удаляем его
                if (timerSendTranslationScaleID) clearTimeout(timerSendTranslationScaleID)

                // Устанавливаем таймер на отправку данных через 2 секунды
                const timerID = window.setTimeout(() => {
                    sendDataToServer(updatedTranslationScale)
                    setTimerSendTranslationScaleID(null)
                }, 4000)

                setTimerSendTranslationScaleID(timerID)
            }
        }
    }

    // Отправляем данные на сервер
    const sendDataToServer = (data: any) => {
        props.updateTranslationScale(props.testID, data).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })

        if (timerSendTranslationScaleID) clearTimeout(timerSendTranslationScaleID)
        if (sendTranslationScaleID) setSendTranslationScaleID(null)
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleLoader, // Видимость загрузки, при получении данных
        localTranslationScale, // Локальный state шкалы
        regexTranslationScale, // Проверка по регулярке, вводимость тестового балла
        sendTranslationScaleID, // Идентификатор редактируемого тестового балла
        timerSendTranslationScaleID, // Идентификатор таймера отправки данных на сервер
    }

    return (
        <TranslationScale {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        testsTranslationScaleList: state.tests.testsTranslationScaleList
    }
}

export default compose(connect(mapState, { 
    getTranslationScale, clearTranslationScale, updateTranslationScale
}))(TranslationScaleContainer)