import React from 'react'
import Drawer from '../../UIComponents/Drawer/Drawer'
import { ReportExamType } from './reportExam.type'
import Button from '../../UIComponents/Button/Button'
import Loader from '../../UIComponents/Loader/Loader'
import Empty from '../../UIComponents/Empty/Empty'

import imgEmpty from './../../../images/empty_documents.svg'
import css from './reportExam.module.scss'

const ReportExam: React.FC<ReportExamType> = (props) => {
    return (
        <Drawer size={`600px`} isOpen={props.isOpen} placement='right'>
            <section className='drawerPrimary'>
                {props.isVisibleLoader
                    ? <Loader open={props.isVisibleLoader} color='#FF6700' title='Загрузка...' />
                    : <main className='drawerContent'>
                        <header className='drawerHeader'>
                            <div className='drawer-header--title'>
                                <h4>Отчеты экзамена</h4>
                                <h5>{props.examName}</h5>
                            </div>

                            <div className='drawer-header--nav'>
                                <Button variant='default' size='xs' onClick={() => props.onClose(false)}>
                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                                    </svg>
                                </Button>
                            </div>
                        </header>

                        <section className='drawerContainerPrimary'>
                            <Button variant='primary' size='md' onClick={props.createReport} width='100%'>
                                <span>СОЗДАТЬ НОВЫЙ ОТЧЁТ</span>
                            </Button>

                            <div className='drawerItems'>
                                {!props.examReportList?.length 
                                    ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='200px' />
                                    : props.examReportList.map((row) => {
                                        return (
                                            <a key={row.examReportID} href={row.filePath} target='_blank' rel='noreferrer' className={css['reportRow']}>
                                                <div>{new Date(row?.dateTimeCreate).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })} {new Date(row?.dateTimeCreate).toLocaleString('default', { hour: 'numeric', minute: 'numeric', second: 'numeric' })}</div>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </main>
                }
            </section>
        </Drawer>
    )
}

export default ReportExam