import { OperatorsActionTypes } from "./interfaces"
import { ParticipantsFileType, UsersListType } from "./types"

let initialState = { // Инициализационный State
    usersList: [] as Array<UsersListType> | null,
    participantsList: [] as Array<ParticipantsFileType> | null
}


// Reducer операторы
export const operatorsReducer = (state = initialState, action: OperatorsActionTypes): typeof initialState => {
    switch (action.type) {
        case 'LOGIN__LOGOUT_APP': { return { ...state = initialState } }

        case 'OPERATORS__SET_USERS': {
            return {
                ...state,
                usersList: action.usersList
            }
        }

        case 'OPERATORS__SET_PARTICIPANTS': {
            return {
                ...state,
                participantsList: action.participantsList
            }
        }

        default: return state
    }
}