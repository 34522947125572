import thunk from 'redux-thunk'
import { applyMiddleware, combineReducers, createStore } from 'redux'

import messagesReducer from './messages'
import { loginReducer } from './login/reducer'
import { operatorsReducer } from './operators/reducer'
import { dictReducer } from './dict/reducer'
import { testsReducer } from './tests/reducer'
import { examsReducer } from './exams/exams.reducer'

let reducers = combineReducers({
    msg: messagesReducer,
    auth: loginReducer,
    operators: operatorsReducer,
    dict: dictReducer,
    tests: testsReducer,
    exams: examsReducer,
})

export type AppStateType = ReturnType<typeof reducers>
export const store = createStore(reducers, applyMiddleware(thunk))

// @ts-ignore
// window._store = store