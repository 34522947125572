import api from './api'

export default class ApiExams {

    // Получение экзаменов
    static async getExams(): Promise<any> {
        try {
            return await api.get('/exams/getExams')
        } catch (error) {
            return error
        }
    }

    // Получение готовых тестов
    static async getReadyTests(): Promise<any> {
        try {
            return await api.get('/exams/getReadyTests')
        } catch (error) {
            return error
        }
    }

    // Создание (редактирование) экзамена
    static async createEditExams(action: string, examID: string, testID: string, examDate: string, isLockedExam: boolean): Promise<any> {
        try {
            return await api.post(`/exams/createEditExams?action=${action}&examID=${examID}&testID=${testID}&examDate=${examDate}&isLockedExam=${isLockedExam}`)
        } catch (error) {
            return error
        }
    }


    // Получение пользователей, которые не назначены на экзамен
    static async getUnassignedUsers(examID: string): Promise<any> {
        try {
            return await api.get(`/exams/getUnassignedUsers?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Получение пользователей, которые назначены на экзамен
    static async getAssignedUsers(examID: string, audience?: string): Promise<any> {
        try {
            return await api.get(`/exams/getAssignedUsers?examID=${examID}&audience=${audience}`)
        } catch (error) {
            return error
        }
    }

    // Добавление пользователей, на экзамен
    static async addUserOnExam(examID: string, loginID: string, audience?: string): Promise<any> {
        try {
            return await api.post(`/exams/addUserOnExam?examID=${examID}&loginID=${loginID}&audience=${audience}`)
        } catch (error) {
            return error
        }
    }

    // Удаление пользователей, с экзамена
    static async deleteUserOnExam(examID: string, loginID: string): Promise<any> {
        try {
            return await api.delete(`/exams/deleteUserOnExam?examID=${examID}&loginID=${loginID}`)
        } catch (error) {
            return error
        }
    }

    // Получение информации об экзамене для Оператора экзаменатора и Участника тестирования
    static async getExamInfo(examID: string): Promise<any> {
        try {
            return await api.get(`/exams/getExamInfo?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Изменение аудитории Оператором экзаменатором
    static async editExamAudience(examID: string, audience: string, oldAudience: string): Promise<any> {
        try {
            return await api.put(`/exams/editExamAudience?examID=${examID}&audience=${audience}&oldAudience=${oldAudience}`)
        } catch (error) {
            return error
        }
    }

    // Создание экзамена для участника
    static async createExamParticipant(examID: string): Promise<any> {
        try {
            return await api.post(`/exams/createExamParticipant?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Получение справочного материала по экзамену для участника тестирования
    static async getReferenceMaterial(examID: string): Promise<any> {
        try {
            return await api.get(`/exams/getReferenceMaterial?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Получение всех вопросов и ответов участника по экзамену
    static async getQuestionsAndAnswers(examID: string): Promise<any> {
        try {
            return await api.get(`/exams/getQuestionsAndAnswers?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование ответов участника
    static async updateAnswerParticipant(data: any): Promise<any> {
        try {
            return await api.put(`/exams/updateAnswerParticipant`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

    // Завершение экзамена участником
    static async endExamByParticipant(examID: string): Promise<any> {
        try {
            return await api.put(`/exams/endExamByParticipant?examID=${examID}`)
        } catch (error) {
            return error
        }
    }
    
    // Получение результата участника
    static async getParticipantResult(examID: string, loginID?: string): Promise<any> {
        try {
            return await api.get(`/exams/getParticipantResult?examID=${examID}&loginID=${loginID}`)
        } catch (error) {
            return error
        }
    }

    // Увеличение минут у участника
    static async editTimeIncrease(examParticipantID: string, oldMinute: number, newMinute: number): Promise<any> {
        try {
            return await api.put(`/exams/editTimeIncrease?examParticipantID=${examParticipantID}&oldMinute=${oldMinute}&newMinute=${newMinute}`)
        } catch (error) {
            return error
        }
    }

    // Удаление участника с экзамена за нарушение
    static async participantViolation(examParticipantID: string, valueViolation: string): Promise<any> {
        try {
            return await api.put(`/exams/participantViolation?examParticipantID=${examParticipantID}&valueViolation=${valueViolation}`)
        } catch (error) {
            return error
        }
    }


    // Создание excel отчетов по экзамену
    static async createReportExam(examID: string): Promise<any> {
        try {
            return await api.post(`/exams/createReportExam?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

    // Получение файлов отчета по экзамену
    static async getReportFileList(examID: string): Promise<any> {
        try {
            return await api.get(`/exams/getReportFileList?examID=${examID}`)
        } catch (error) {
            return error
        }
    }

}