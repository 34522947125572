import { LoginActionTypes } from "./interfaces"
import { UserProfileType } from "./types"


let initialState = { // Инициализационный State
    isAuthorized: false as boolean, // Установка метки, что авторизация выполнена
    isAppInitialized: false as boolean, // Установка метки, что инициализация приложения выполнена

    userProfile: {} as UserProfileType | null
}


// Reducer авторизации
export const loginReducer = (state = initialState, action: LoginActionTypes): typeof initialState => {
    switch (action.type) {
        case 'LOGIN__LOGOUT_APP': { return { ...state = initialState } }

        case 'LOGIN__IS_AUTHORIZED': {
            return {
                ...state,
                isAuthorized: action.isAuthorized
            }
        }

        case 'LOGIN__IS_APP_INITIALIZED': {
            return {
                ...state,
                isAppInitialized: action.isAppInitialized
            }
        }

        case 'LOGIN__SET_USER_PROFILE': {
            return {
                ...state,
                userProfile: action.userProfile
            }
        }
        
        default: return state
    }
}