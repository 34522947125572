import { IAssignedUsersListAction, IExamInfoAction, IExamReportListAction, IExamsListAction, IParticipantResultTypesAction, IQuestionsAndAnswersAction, IReadyTestsListAction, IReferenceMaterialAction, IUnassignedUsersListAction } from "./exams.interfaces"
import { AssignedUsersListTypes, ExamInfoTypes, ExamReportListTypes, ExamsListTypes, ParticipantResultTypes, QuestionsAndAnswersTypes, ReadyTestsListTypes, ReferenceMaterialTypes, UnassignedUsersListTypes } from "./exams.types"

const examsSetExamsListAction = (item: ExamsListTypes[] | undefined): IExamsListAction => ({ 
    type: 'EXAMS__SET_EXAMS_LIST', 
    examsList: item
})

const examsSetReadyTestsListAction = (item: ReadyTestsListTypes[] | undefined): IReadyTestsListAction => ({ 
    type: 'EXAMS__SET_READY_TESTS_LIST', 
    readyTestsList: item
})

const examsSetUnassignedUsersListAction = (item: UnassignedUsersListTypes[] | undefined): IUnassignedUsersListAction => ({ 
    type: 'EXAMS__SET_UNASSIGNED_USERS_LIST', 
    unassignedUsersList: item
})

const examsSetAssignedUsersListAction = (item: AssignedUsersListTypes[] | undefined): IAssignedUsersListAction => ({ 
    type: 'EXAMS__SET_ASSIGNED_USERS_LIST', 
    assignedUsersList: item
})

const examsSetExamInfoAction = (item: ExamInfoTypes | undefined): IExamInfoAction => ({ 
    type: 'EXAMS__SET_EXAM_INFO', 
    examInfo: item
})

const examsSetReferenceMaterialAction = (item: ReferenceMaterialTypes[] | undefined): IReferenceMaterialAction => ({ 
    type: 'exams__set_reference_material', 
    referenceMaterial: item
})

const examsSetQuestionsAndAnswersAction = (item: QuestionsAndAnswersTypes[] | undefined): IQuestionsAndAnswersAction => ({ 
    type: 'exams__set_questions_and_answers', 
    questionsAndAnswers: item
})

const examsSetParticipantResultAction = (item: ParticipantResultTypes | undefined): IParticipantResultTypesAction => ({ 
    type: 'exams__set_participant_result', 
    participantResult: item
})

const examReportListAction = (item: ExamReportListTypes[] | undefined): IExamReportListAction => ({ 
    type: 'exams__set_reports_list', 
    examReportList: item
})

const actions = {
    examsSetExamsListAction, // Список экзаменов
    examsSetReadyTestsListAction, // Список готовых тестов
    examsSetUnassignedUsersListAction, // Список не назначенных пользователей
    examsSetAssignedUsersListAction, // Список назначенных пользователей
    examsSetExamInfoAction, // Информация об экзамене для Оператора экзаменатора и Участника тестирования
    examsSetReferenceMaterialAction, // Справочный материал по экзамену
    examsSetQuestionsAndAnswersAction, // Список вопросов и ответов участника по экзамену
    examsSetParticipantResultAction, // Результат участника
    examReportListAction
}

export default actions