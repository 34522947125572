import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import { clearAccessLevels, getAccessLevels } from '../../bll/dict/operations'
import { clearOperators, clearParticipantsFile, createEditOperator, getOperators, uploadFileParticipants } from '../../bll/operators/operations'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import Operators from './operators'
import Loader from '../UIComponents/Loader/Loader'
import { SelectOptionType } from '../UIComponents/Select/Select'
import { createParticipants } from '../../bll/operators/operations'

const OperatorsContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleLoaderLoadParticipants, setIsVisibleLoaderLoadParticipants] = React.useState<boolean>(false) // Видимость загрузки, при загрузки файла с участниками
    const [isVisibleModalCreateEditOperator, setIsVisibleModalCreateEditOperator] = React.useState<boolean>(false) // Видимость окна, создание (редактирование) оператора
    const [isVisibleDrawerLoadParticipants, setIsVisibleDrawerLoadParticipants] = React.useState<boolean>(false) // Видимость окна, загрузка участников из файла

    const [actionName, setActionName] = React.useState<'create' | 'edit' | null>(null) // Действие, создание или редактирование оператора
    const [loginID, setLoginID] = React.useState<string>('') // Идентификатор редактируемого пользователя
    const [valueFilter, setValueFilter] = React.useState<string | undefined>(undefined)
    
    const [valueLoginName, setValueLoginName] = React.useState<string>('') // Значение, логин оператора
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string>('') // Значение, пароль оператора
    const [selectedAccessLevel, setSelectedAccessLevel] = React.useState<SelectOptionType | undefined>(undefined) // Выбранный уровень доступа
    const [valueLastName, setValueLastName] = React.useState<string>('') // Значение, фамилия оператора
    const [valueFirstName, setValueFirstName] = React.useState<string>('') // Значение, имя оператора
    const [valueSecondName, setValueSecondName] = React.useState<string>('') // Значение, отчество оператора
    const [valueSnils, setValueSnils] = React.useState<string>('') // Значение, СНИЛС участника
    const [valuePhone, setValuePhone] = React.useState<string>('') // Значение, телефон участника
    const [valueDocSeries, setValueDocSeries] = React.useState<string>('') // Значение, серия документа участника
    const [valueDocNumber, setValueDocNumber] = React.useState<string>('') // Значение, номер документа участника
    const [isLoginBlocked, setIsLoginBlocked] = React.useState<boolean>(false) // Значение, заблокирован ли пользователь или нет

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getOperators(), props.getAccessLevels() ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) {
                        isGlobalError = true
                        notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR")
                    }
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0011, "ERROR")
            if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0012, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearOperators()
            props.clearAccessLevels()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }


    // Открытие (Закрытие) окна, создание (редактирование) оператора
    const openModalCreateEditOperator = (
        isOpen: boolean,
        action: 'create' | 'edit' | null = null,
        loginID: string = '',
        loginName: string = '',
        loginPassword: string = '',
        accessLevel: SelectOptionType | undefined = undefined,
        userLastName: string = '',
        userFirstName: string = '',
        userSecondName: string = '',
        userSnils: string = '',
        userPhone: string = '',
        userDocSeries: string = '',
        userDocNumber: string = '',
        loginBlocked: boolean = false
    ) => {
        setActionName(action)
        setLoginID(loginID)
        setValueLoginName(loginName)
        setValueLoginPassword(loginPassword)
        setSelectedAccessLevel(accessLevel)
        setValueLastName(userLastName)
        setValueFirstName(userFirstName)
        setValueSecondName(userSecondName)
        setValueSnils(userSnils)
        setValuePhone(userPhone)
        setValueDocSeries(userDocSeries)
        setValueDocNumber(userDocNumber)
        setIsLoginBlocked(loginBlocked)

        setIsVisibleModalCreateEditOperator(isOpen)
    }

    // Открытие (Закрытие) окна, загрузка участников из файла
    const openDrawerLoadParticipant = (isOpen: boolean) => {
        props.clearParticipantsFile()
        setIsVisibleDrawerLoadParticipants(isOpen)
    }

    // Кнопка загрузить участников в базу данных
    const createParticipants = () => {
        setIsVisibleLoaderLoadParticipants(true)

        props.createParticipants(props.participantsList).then((result: any) => {
            switch (result?.code) {
                case "OK": break
                case "ERR_QUERY_STRING": return notifiMsg('Ошибка при добавлении данных', "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg('Переданные данные не корректны', "ERROR")
                default: return errorHandler(result?.code, props.msg, navigate)
            }
        }).finally(() => {
            setIsVisibleLoaderLoadParticipants(false)
        })
    }


    // Кнопка применить (создать) в окне создания (редактирования) оператора
    const applyButtonCreateEditOperator = () => {
        let isFieldsError: boolean = false

        if (valueLoginName.trim().length <= 3) { isFieldsError = true; notifiMsg(props.msg.warning.W0002, "WARNING") }
        if (selectedAccessLevel === undefined) { isFieldsError = true; notifiMsg(props.msg.warning.W0004, "WARNING") }
        if (valueLastName.trim().length < 2) { isFieldsError = true; notifiMsg(props.msg.warning.W0005, "WARNING") }
        if (valueFirstName.trim().length < 2) { isFieldsError = true; notifiMsg(props.msg.warning.W0006, "WARNING") }

        if (selectedAccessLevel?.id === 4) {
            if (valueSnils.trim().length === 0) { isFieldsError = true; notifiMsg(props.msg.warning.W0019, "WARNING") }
            if (valuePhone.trim().length === 0) { isFieldsError = true; notifiMsg(props.msg.warning.W0020, "WARNING") }
            if (valueDocNumber.trim().length === 0) { isFieldsError = true; notifiMsg(props.msg.warning.W0021, "WARNING") }
        }

        if ((actionName === 'create' && valueLoginPassword.length < 6) || 
            (actionName === 'edit' && (valueLoginPassword.length > 0 && valueLoginPassword.length < 6))
        ) { isFieldsError = true; notifiMsg(props.msg.warning.W0003, "WARNING") }

        if (!isFieldsError) {
            props.createEditOperator(actionName, loginID, valueLoginName, valueLoginPassword, valueLastName, valueFirstName, valueSecondName, valueSnils, valuePhone, valueDocSeries, valueDocNumber, selectedAccessLevel?.id, isLoginBlocked).then((result: any) => {
                switch (result?.code) {
                    case 'OK': openModalCreateEditOperator(false); break
                    case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Кнопка сгенерировать случайный пароль
    const generatePassword = () => {
        var length = 8, charset = "1234567890@!*$", retVal = ""

        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n))
        }

        setValueLoginPassword(retVal)
    }

    // Список уровней доступа
    let accessLevelsOptions: SelectOptionType[] = []
    props.accessLevelsList?.map((item: any) => (
        accessLevelsOptions.push({ id: item.accessLevelID, label: item.accessLevelName })
    ))

    // Загрузка файлов на сервер
    const fileUploader = (fileList: any) => {
        setIsVisibleLoaderLoadParticipants(true)

        fileList.forEach((file: any) => {
            props.uploadFileParticipants(file, file.name).then((result: any) => {
                switch (result?.code) {
                    case "OK": break
                    case "ERR_FILE_NOT_FOUND": return notifiMsg(props.msg.error.E0022, "ERROR")
                    case "ERR_FILE_EXTNAME": return notifiMsg(props.msg.error.E0033, "ERROR")
                    case "ERR_FILE_SIZE": return notifiMsg(props.msg.error.E0024, "ERROR")
                    default: return errorHandler(result?.code, props.msg, navigate)
                }
            }).finally(() => {
                setIsVisibleLoaderLoadParticipants(false)
            })
        })
    }

    // Проверка номера телефона
    const regexNumber = (value: string) => {
        const re = /^[7][0-9]*$/
        if (value === '' || re.test(value)) { 
            setValuePhone(value)
        }
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        openModalCreateEditOperator, // Открытие (Закрытие) окна, создание (редактирование) оператора
        applyButtonCreateEditOperator, // Кнопка применить (создать) в окне создания (редактирования) оператора
        generatePassword, // Кнопка сгенерировать случайный пароль
        
        isVisibleModalCreateEditOperator, // Видимость окна, создание (редактирование) оператора

        valueFilter, setValueFilter,
        actionName, // Действие, создание или редактирование оператора
        valueLoginName, setValueLoginName, // Значение, логин оператора
        valueLoginPassword, setValueLoginPassword, // Значение, пароль оператора
        selectedAccessLevel, setSelectedAccessLevel, // Выбранный уровень доступа
        valueLastName, setValueLastName, // Значение, фамилия оператора
        valueFirstName, setValueFirstName, // Значение, имя оператора
        valueSecondName, setValueSecondName, // Значение, отчество оператора
        valueSnils, setValueSnils, // Значение, СНИЛС участника
        valuePhone, setValuePhone, // Значение, телефон участника
        valueDocSeries, setValueDocSeries, // Значение, серия документа участника
        valueDocNumber, setValueDocNumber, // Значение, номер документа участника
        isLoginBlocked, setIsLoginBlocked, // Значение, заблокирован ли пользователь или нет
    
        accessLevelsOptions, // Список уровней доступа

        openDrawerLoadParticipant, isVisibleDrawerLoadParticipants,
        fileUploader,
        isVisibleLoaderLoadParticipants,
        createParticipants,
        regexNumber
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <Operators {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        usersList: state.operators.usersList,
        accessLevelsList: state.dict.accessLevelsList,
        participantsList: state.operators.participantsList
    }
}

export default compose(connect(mapState, { 
    getOperators, clearOperators, createEditOperator, 
    getAccessLevels, clearAccessLevels, uploadFileParticipants, clearParticipantsFile, createParticipants
}))(OperatorsContainer)