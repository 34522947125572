import React from 'react'
import { NavLink } from 'react-router-dom'

import { Tooltip } from 'antd'

import Button from '../UIComponents/Button/Button'
import Empty from '../UIComponents/Empty/Empty'
import { ITestVariantsInterface } from './testVariants.interface'
import css from './testVariants.module.scss'
import imgEmpty from './../../images/empty_documents.svg'
import Modal from '../UIComponents/Modal/Modal'
import ModalConfirmPassword from '../_modal-confirm-password/modal-confirm-password'

const TestVariants: React.FC<ITestVariantsInterface> = (props) => {
    return (
        <>
            <CreateNewVariant {...props} />

            <ModalConfirmPassword 
                isOpen={props.isVisibleModalPasswordConfirm} 
                closeModalMethod={props.openModalPasswordConfirm} 
                confirmModalMethod={props.deleteVariant}
                onLoginPasswordChange={(value: string) => props.setValueLoginPassword(value)}
            />

            <header className={css.header}>
                <Tooltip title="Назад к тестам" placement='bottomLeft'>
                    <NavLink to='/main/tests'>
                        <Button variant='secondary' size='sm'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.41421 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.41421L11.7071 17.2929C12.0976 17.6834 12.0976 18.3166 11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z" fill="currentColor" />
                            </svg>
                        </Button>
                    </NavLink>
                </Tooltip>

                <div className={css.header_title}>
                    <h4>Варианты теста</h4>
                    <h5>{props.subjectName} ({props.basisTrainingName})</h5>
                </div>

                <aside>
                    {props.userProfile.accessLevelID && props.userProfile.accessLevelID in {1: 1, 2: 2} && <Button variant='secondary' size='sm' onClick={() => props.setIsVisibleModalCreateVariant(true)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 8V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Создать</span>
                    </Button>}
                </aside>
            </header>

            <div className={css.main}>
                <header className={css.tHeader}>
                    <span className={`${css.tName}`}></span>
                    <span className={`${css.tName} ${css.tAlignCenter}`}>Количество вопросов</span>
                    <span className={`${css.tName} ${css.tAlignCenter}`}>Загруженных изображений</span>
                    <span className={`${css.tName} ${css.tAlignCenter}`}>Правильные ответы</span>
                    <span className={`${css.tName}`}></span>
                </header>

                <div className={css.tBody}>
                    {!props.testsVariantsList?.length
                        ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='300px' />
                        : props.testsVariantsList?.map((item) => {
                            return (
                                <div key={item.variantID} className={css.tRow}>
                                    <span className={`${css.tAlignCenter}`} style={{ fontSize: '24px' }}>
                                        {
                                            item.countCorrectAnswersIsNull === 0 && props.countQuestions === item.countQuestions && item.countQuestions === item.countQuestionsImgNotNull && item.countQuestions === item.countCorrectAnswersNotNull ? '✔' :
                                            item.countCorrectAnswersIsNull > 0 || props.countQuestions !== item.countQuestions || item.countQuestions !== item.countQuestionsImgNotNull || item.countQuestions !== item.countCorrectAnswersNotNull ? '⚠' : '✘'
                                        }
                                    </span>

                                    <div className={css.tRowPrimary}>
                                        <span className={`${css.tName}`}>{`Вариант ${item.variantNumber}`}</span>

                                        <span className={`${css.tName} ${css.tAlignCenter}`}>{item.countQuestions}</span>
                                        <span className={`${css.tName} ${css.tAlignCenter}`}>{item.countQuestionsImgNotNull}</span>
                                        
                                        <span className={`${css.tName} ${css.tAlignCenter}`}>
                                            <Tooltip title="Готовые / Пустые" placement='bottom'>
                                                {`${item.countCorrectAnswersNotNull} / ${item.countCorrectAnswersIsNull}`}
                                            </Tooltip>
                                        </span>

                                        <aside className={css.tBar}>
                                            {props.testsVariantsList?.length === item.variantNumber // Кнопка удалить (ТОЛЬКО ПОСЛЕДНЯЯ СТРОКА)
                                                && <Tooltip title='Удалить' placement='bottom'>
                                                    <div>
                                                        <Button variant='secondary' size='sm' onClick={() => props.openModalPasswordConfirm(true, item.variantID)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z" fill="currentColor" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {props.testsVariantsList?.length === item.variantNumber // Кнопка вопросы (ТОЛЬКО ПОСЛЕДНЯЯ СТРОКА)
                                                && <Tooltip title='Вопросы' placement='bottom'>
                                                    <NavLink to={`questions`} state={{ testID: props.testID, subject: props.subjectName, basisTraining: props.basisTrainingName, countQuestions: props.countQuestions, variantID: item.variantID, variantNumber: item.variantNumber }}>
                                                        <Button variant='secondary' size='sm' width={`${props.testsVariantsList?.length === item.variantNumber ? '78px' : undefined}`}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" fill="currentColor" />
                                                                <path d="M10.25 9.625C10.25 8.77087 10.9892 8 12 8C13.0108 8 13.75 8.77087 13.75 9.625C13.75 10.2116 13.4112 10.7484 12.8646 11.038C12.6027 11.1768 12.2205 11.3827 11.8927 11.7044C11.3217 12.2646 11 13.0309 11 13.8308V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V13.8308C13 13.5679 13.1057 13.3161 13.2933 13.132C13.3915 13.0357 13.5355 12.9459 13.801 12.8053C14.9448 12.1992 15.75 11.0147 15.75 9.625C15.75 7.57964 14.0267 6 12 6C9.97328 6 8.25 7.57964 8.25 9.625C8.25 10.1773 8.69772 10.625 9.25 10.625C9.80228 10.625 10.25 10.1773 10.25 9.625Z" fill="currentColor" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z" fill="currentColor" />
                                                            </svg>
                                                        </Button>
                                                    </NavLink>
                                                </Tooltip>
                                            }

                                            {props.testsVariantsList?.length !== item.variantNumber // Кнопка вопросы (ВСЕ СТРОКИ, КРОМЕ ПОСЛЕДНЕЙ)
                                                && <NavLink to={`questions`} state={{ testID: props.testID, subject: props.subjectName, basisTraining: props.basisTrainingName, countQuestions: props.countQuestions, variantID: item.variantID, variantNumber: item.variantNumber }}>
                                                    <Button variant='secondary' size='sm'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" fill="currentColor" />
                                                            <path d="M10.25 9.625C10.25 8.77087 10.9892 8 12 8C13.0108 8 13.75 8.77087 13.75 9.625C13.75 10.2116 13.4112 10.7484 12.8646 11.038C12.6027 11.1768 12.2205 11.3827 11.8927 11.7044C11.3217 12.2646 11 13.0309 11 13.8308V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V13.8308C13 13.5679 13.1057 13.3161 13.2933 13.132C13.3915 13.0357 13.5355 12.9459 13.801 12.8053C14.9448 12.1992 15.75 11.0147 15.75 9.625C15.75 7.57964 14.0267 6 12 6C9.97328 6 8.25 7.57964 8.25 9.625C8.25 10.1773 8.69772 10.625 9.25 10.625C9.80228 10.625 10.25 10.1773 10.25 9.625Z" fill="currentColor" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z" fill="currentColor" />
                                                        </svg>

                                                        <span>Вопросы</span>
                                                    </Button>
                                                </NavLink>
                                            }
                                        </aside>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

// Окно с подтверждением создания нового варианта
const CreateNewVariant: React.FC<ITestVariantsInterface> = (props) => {
    return (
        <Modal open={props.isVisibleModalCreateVariant}>
            <section className='modalPrimary' style={{ width: '450px' }}>
                <header className='modalHeader'>
                    <h5>Создание варианта...</h5>
                </header>

                <main className='modalContent'>
                    <div className='modalContainer'>
                        Вы действительно хотите создать новый вариант для теста - {`${props.subjectName} (${props.basisTrainingName})?`}
                    </div>

                    <footer className='modalFooter'>
                        <Button variant='secondary' size='md' onClick={() => props.setIsVisibleModalCreateVariant(false)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                            </svg>

                            <span>ОТМЕНА</span>
                        </Button>

                        <Button variant='primary' size='md' onClick={props.createVariant}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                            </svg>

                            <span>ДА</span>
                        </Button>
                    </footer>
                </main>
            </section>
        </Modal>
    )
}

export default TestVariants