import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppStateType } from '../../bll/store'
import Tests from './tests'
import { SelectOptionType } from '../UIComponents/Select/Select'
import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../bll/errorHandler'
import { clearTests, createEditTest, getTests, lockingTest } from '../../bll/tests/operations'
import { clearBasisTraining, clearSubjects, getBasisTraining, getSubjects } from '../../bll/dict/operations'
import Loader from '../UIComponents/Loader/Loader'

const TestsContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)
    
    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleModalCreateEditTest, setIsVisibleModalCreateEditTest] = React.useState<boolean>(false) // Видимость окна, создание (редактирование) теста

    const [actionName, setActionName] = React.useState<'create' | 'edit' | null>(null) // Действие, создание или редактирование оператора
    const [testID, setTestID] = React.useState<string>('') // Идентификатор редактируемого теста

    const [selectedSubject, setSelectedSubject] = React.useState<SelectOptionType | undefined>(undefined) // Выбранный предмет
    const [selectedBasisTraining, setSelectedBasisTraining] = React.useState<SelectOptionType | undefined>(undefined) // Выбранная основа обучения
    const [valueTestMinute, setValueTestMinute] = React.useState<string | number>('') // Значение, время теста в минутах
    const [valueTestPorog, setValueTestPorog] = React.useState<string | number>('') // Значение, порог теста

    const [isVisibleModalPasswordConfirm, setIsVisibleModalPasswordConfirm] = React.useState<boolean>(false) // Видимость окна подтверждения для ввода пароля
    
    const [selectedTestID, setSelectedTestID] = React.useState<string | undefined>() // Выбранный идентификатор теста
    const [actionLocking, setActionLocking] = React.useState<string | undefined>() // Действие теста (блокировка или разблокировка)
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string | undefined>() // Пароль оператора, для подтверждения

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getTests(), props.getSubjects(), props.getBasisTraining() ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0016, "ERROR")
            if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0017, "ERROR")
            if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0018, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearTests()
            props.clearSubjects()
            props.clearBasisTraining()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Открытие (Закрытие) окна, создание (редактирование) теста
    const openModalCreateEditTest = (
        isOpen: boolean,
        action: 'create' | 'edit' | null = null,
        testID: string = '',
        subject: SelectOptionType | undefined = undefined,
        basisTraining: SelectOptionType | undefined = undefined,
        testMinute: string | number = '',
        testPorog: string | number = '') => {

        setActionName(action)
        setTestID(testID)
        setSelectedSubject(subject)
        setSelectedBasisTraining(basisTraining)
        setValueTestMinute(testMinute)
        setValueTestPorog(testPorog)

        setIsVisibleModalCreateEditTest(isOpen)
    }

    // Кнопка применить (создать) в окне создания (редактирования) теста
    const applyButtonCreateEditTest = () => {
        let isFieldsError: boolean = false

        if (selectedSubject === undefined) { isFieldsError = true; notifiMsg(props.msg.warning.W0007, "WARNING") }
        if (selectedBasisTraining === undefined) { isFieldsError = true; notifiMsg(props.msg.warning.W0008, "WARNING") }
        if (valueTestMinute < 1) { isFieldsError = true; notifiMsg(props.msg.warning.W0009, "WARNING") }
        if (valueTestPorog < 1) { isFieldsError = true; notifiMsg(props.msg.warning.W0009, "WARNING") }

        if (!isFieldsError) {
            props.createEditTest(actionName, testID, selectedSubject?.id, selectedBasisTraining?.id, valueTestMinute, valueTestPorog).then((result: any) => {
                switch (result?.code) {
                    case 'OK': openModalCreateEditTest(false); break
                    case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Проверка времени в минутах
    const regexNumber = (value: string, name: 'minute' | 'porog') => {
        const re = /^[1-9][0-9]*$/
        if (value === '' || re.test(value)) { 
            switch (name) {
                case 'minute': setValueTestMinute(value); break;
                case 'porog': setValueTestPorog(value); break;
            }
        }
    }

    // Список предметов
    let subjectsOptions: SelectOptionType[] = []
    props.subjectsList?.map((item: any) => (
        subjectsOptions.push({ id: item.subjectID, label: item.subjectName })
    ))

    // Список основ обучения
    let basisTrainingOptions: SelectOptionType[] = []
    props.basisTrainingList?.map((item: any) => (
        basisTrainingOptions.push({ id: item.basisTrainingID, label: item.basisTrainingName })
    ))

    // Открытие окна подтверждения для ввода пароля
    const openModalPasswordConfirm = (isOpen: boolean, id: string = '', action: string = '') => {
        setSelectedTestID(id)
        setActionLocking(action)
        setValueLoginPassword('')

        setIsVisibleModalPasswordConfirm(isOpen)
    }

    // Блокировка или разблокировка теста от редактирования
    const lockTest = () => {
        props.lockingTest(selectedTestID, actionLocking, valueLoginPassword).then((result: any) => {
            switch (result?.code) {
                case 'OK': openModalPasswordConfirm(false); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Кнопка обновления списка тестов
    const updateListTests = () => {
        setIsVisibleLoader(true)

        props.getTests().then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }

            setIsVisibleLoader(false)
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleModalCreateEditTest, // Видимость окна, создание (редактирование) теста

        openModalCreateEditTest, // Открытие (Закрытие) окна, создание (редактирование) теста
        applyButtonCreateEditTest, // Кнопка применить (создать) в окне создания (редактирования) теста
        updateListTests, // Кнопка обновления списка тестов

        actionName, // Действие, создание или редактирование оператора

        selectedSubject, setSelectedSubject, // Выбранный предмет
        selectedBasisTraining, setSelectedBasisTraining, // Выбранная основа обучения
        valueTestMinute, setValueTestMinute, // Значение, время теста в минутах
        valueTestPorog, setValueTestPorog, // Значение, порог теста

        subjectsOptions, // Список предметов
        basisTrainingOptions, // Список основ обучения

        regexNumber, // Проверка времени в минутах

        isVisibleModalPasswordConfirm, // Видимость окна подтверждения для ввода пароля

        openModalPasswordConfirm, // Открытие окна подтверждения для ввода пароля
        valueLoginPassword, setValueLoginPassword, // Пароль оператора, для подтверждения

        lockTest, // Блокировка или разблокировка теста от редактирования
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <Tests {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        testsList: state.tests.testsList,
        subjectsList: state.dict.subjectsList,
        basisTrainingList: state.dict.basisTrainingList
    }
}

export default compose(connect(mapState, {
    getTests, clearTests, createEditTest, lockingTest, 
    getSubjects, clearSubjects,
    getBasisTraining, clearBasisTraining
}))(TestsContainer)