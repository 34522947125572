import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Examinator from './examinator'
import { AppStateType } from '../../../bll/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../../bll/errorHandler'
import Loader from '../../UIComponents/Loader/Loader'
import { addUserOnExam, clearAssignedUsers, clearExamInfo, clearUnassignedUsers, deleteUserOnExam, editExamAudience, editTimeIncrease, getAssignedUsers, getExamInfo, getUnassignedUsers, participantViolation } from '../../../bll/exams/exams.operations'
import { ActionModalType, ActionPasswordConfirmType } from './examinator.interface'
import { passwordConfirm } from '../../../bll/login/operations'

const ExaminatorContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleLoaderAccessParticipant, setIsVisibleLoaderAccessParticipant] = React.useState<boolean>(false) // Видимость загрузки, при открытии окна предоставление доступа к экзамену участнику
    const [isVisibleEditAudience, setIsVisibleEditAudience] = React.useState<boolean>(false) // Видимость окна редактирования номера аудитории
    const [isVisibleAccessParticipants, setIsVisibleAccessParticipants] = React.useState<boolean>(false) // Видимость окна предоставление доступа к экзамену участнику
    const [isVisibleModalPasswordConfirm, setIsVisibleModalPasswordConfirm] = React.useState<boolean>(false) // Видимость окна подтверждения для ввода пароля
    const [isVisibleTimeIncrease, setIsVisibleTimeIncrease] = React.useState<boolean>(false) // Видимость окна увеличения времени у участника
    const [isVisibleViolation, setIsVisibleViolation] = React.useState<boolean>(false) // Видимость окна удаления участника с экзамена за нарушение
    
    const [selectedUnassignedUserID, setSelectedUnassignedUserID] = React.useState<string>('') // Выбранный идентификатор пользователя в списке не назначенных
    
    const [selectedUserID, setSelectedUserID] = React.useState<string>('') // Идентификатор участника
    const [selectedExamParticipantID, setSelectedExamParticipantID] = React.useState<string>('') // Идентификатор доступа участника
    const [oldMinuteExamParticipant, setOldMinuteExamParticipant] = React.useState<string>('') // Текущее время участника

    const [valueSearchUnassignedUsers, setValueSearchUnassignedUsers] = React.useState<string>('') // Поиск не назначенных пользователей
    const [valueAudience, setValueAudience] = React.useState<string>('') // Значение номера аудитории в окне редактирования аудитории
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string>('') // Пароль оператора, для подтверждения
    const [valueTimeIncrease, setValueTimeIncrease] = React.useState<string | number>('') // Количество минут для увеличения времени у частника
    const [valueViolation, setValueViolation] = React.useState<string>('') // Краткий комментарий, удаление участника с экзамена за нарушение
    
    const [actionPasswordConfirm, setActionPasswordConfirm] = React.useState<ActionPasswordConfirmType | undefined>() // Действие, которое требуется подтвердить паролем

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getExamInfo(getLocation.state.examID) ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0032, "ERROR")
            // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0032, "ERROR")
            // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearExamInfo()
            props.clearAssignedUsers()

            
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        props.getAssignedUsers(getLocation.state.examID, props.examInfo?.audience)

        const interval = setInterval(() => {
            props.getAssignedUsers(getLocation.state.examID, props.examInfo?.audience)
        }, 20000)

        return () => {
            clearInterval(interval)
        }
    }, [props.examInfo?.audience]) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }


    // Открытие окна допуска пользователей
    const openAccessUser = (isOpen: boolean) => {
        if (!props.examInfo.audience) {
            notifiMsg(props.msg.warning.W0014, "WARNING")
        } else {
            setIsVisibleLoaderAccessParticipant(isOpen)
            setIsVisibleAccessParticipants(isOpen)

            if (isOpen) {
                props.getUnassignedUsers(getLocation.state.examID).then((result: any) => {
                    switch (result?.code) {
                        case 'OK':  break
                        case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                        default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                    }

                    setIsVisibleLoaderAccessParticipant(false)
                })
            } else {
                setValueSearchUnassignedUsers('')
                setSelectedUnassignedUserID('')

                props.clearUnassignedUsers()
            }
        }
    }

    // Открытие окна редактирования аудитории
    const openEditAudience = (isOpen: boolean) => {
        if (isOpen) {
            setValueAudience(props.examInfo.audience || '')
        } else {
            setValueAudience('')
        }
        
        setIsVisibleEditAudience(isOpen)
    }

    // Открытие окна подтверждения для ввода пароля
    const openModalPasswordConfirm = (isOpen: boolean, id: string = '', actionPassConfirm?: ActionPasswordConfirmType, valExamParticipantID: string = '', valOldMinuteExamParticipant: string = '') => {
        setSelectedExamParticipantID(valExamParticipantID)
        setOldMinuteExamParticipant(valOldMinuteExamParticipant)
        
        setSelectedUserID(id)
        setValueLoginPassword('')
        setActionPasswordConfirm(actionPassConfirm)

        setIsVisibleModalPasswordConfirm(isOpen)
    }

    // Кнопка применить в окне редактирования аудитории
    const applyNumberAudience = () => {
        if (valueAudience.trim().length === 0) {
            notifiMsg(props.msg.warning.W0015, "WARNING")
        } else {
            props.editExamAudience(getLocation.state.examID, valueAudience, props.examInfo?.audience).then((result: any) => {
                switch (result?.code) {
                    case 'OK': openEditAudience(false); break
                    case "AUDIENCE_NOT_EDIT": return notifiMsg(props.msg.warning.W0016, "WARNING")
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Кнопка предоставить доступ в окне доступа к экзамену
    const applyAddUserExam = () => {
        if (!selectedUnassignedUserID) {
            notifiMsg(props.msg.warning.W0017, "WARNING")
        } else {
            props.addUserOnExam(getLocation.state.examID, selectedUnassignedUserID, props.examInfo.audience).then((result: any) => {
                switch (result?.code) {
                    case 'OK': setSelectedUnassignedUserID(''); break
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Кнопка подтверждения в окне ввода пароля
    const applyPasswordConfirm = () => {
        if (selectedUserID) {
            props.passwordConfirm(valueLoginPassword).then((result: any) => {
                switch (result?.code) {
                    case 'OK':
                        switch (actionPasswordConfirm) {
                            case 'delete': // Удаление участника с экзамена (Если по ошибке был добален)
                                props.deleteUserOnExam(getLocation.state.examID, selectedUserID, props.examInfo?.audience).then((result: any) => {
                                    switch (result?.code) {
                                        case 'OK': openModalPasswordConfirm(false); break
                                        case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                                        default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                                    }
                                })
                                break

                            case 'minutes': // Добавление минут участнику экзамена
                                setIsVisibleTimeIncrease(true)
                                setIsVisibleModalPasswordConfirm(false)
                                break

                            case 'violation': // Удаление участника с экзамена, за нарушения правил проведения
                                setIsVisibleViolation(true)
                                setIsVisibleModalPasswordConfirm(false)
                                break
                        }
                        break

                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })

        }
    }

    // Кнопка закрыть или применить в окне увеления времени
    const modalTimeIncrease = (action: ActionModalType) => {
        switch (action) {
            case 'apply':
                props.editTimeIncrease(getLocation.state.examID, props.examInfo?.audience, selectedExamParticipantID, oldMinuteExamParticipant, valueTimeIncrease).then((result: any) => {
                    switch (result?.code) {
                        case 'OK': 
                            setValueTimeIncrease('')
                            setIsVisibleTimeIncrease(false)
                            openModalPasswordConfirm(false)
                            break

                        case "MINUTE_ERR_MAX": return notifiMsg(props.msg.warning.W0018, "WARNING")
                        case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                        default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                    }
                })

                break;
            
            case 'close':
                setValueTimeIncrease('')
                setIsVisibleTimeIncrease(false)
                openModalPasswordConfirm(false)
                break;
        }
    }

    // Кнопка закрыть или применить в окне удаления участника с экзамена за нарушение
    const modalViolation = (action: ActionModalType) => {
        switch (action) {
            case 'apply':
                if (valueViolation.length !== 0) {
                    props.participantViolation(getLocation.state.examID, props.examInfo?.audience, selectedExamParticipantID, valueViolation).then((result: any) => {
                        switch (result?.code) {
                            case 'OK': 
                                setValueViolation('')
                                setIsVisibleViolation(false)
                                openModalPasswordConfirm(false)
                                break
    
                            case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                            default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                        }
                    })
                } else { notifiMsg('Введите пожалуйста краткий комментарий!', "INFO") }
                break;
            
            case 'close':
                setValueViolation('')
                setIsVisibleViolation(false)
                openModalPasswordConfirm(false)
                break;
        }
    }

    // Проверка времени в минутах
    const regexNumber = (value: string) => {
        const re = /^[1-9]$/
        if (value === '' || re.test(value)) {
            setValueTimeIncrease(value)
        }
    }

    // Кнопка обновления списка участников экзамена
    const updateListParticipant = () => {
        setIsVisibleLoader(true)

        props.getAssignedUsers(getLocation.state.examID, props.examInfo?.audience).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }

            setIsVisibleLoader(false)
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        examID: getLocation.state.examID, // Идентификатор экзамена
        
        openAccessUser, // Открытие окна допуска пользователей
        openEditAudience, // Открытие окна редактирования аудитории
        openModalPasswordConfirm, // Открытие окна подтверждения для ввода пароля
        applyNumberAudience, // Кнопка применить в окне редактирования аудитории
        applyAddUserExam, // Кнопка предоставить доступ в окне доступа к экзамену
        applyPasswordConfirm, // Кнопка подтверждения в окне ввода пароля

        updateListParticipant, // Кнопка обновления списка участников экзамена
        modalTimeIncrease, // Кнопка закрыть или применить в окне увеления времени
        modalViolation, // Кнопка закрыть или применить в окне удаления участника с экзамена за нарушение

        isVisibleEditAudience, // Видимость окна редактирования номера аудитории
        isVisibleAccessParticipants, // Открытие окна допуска пользователей
        isVisibleLoaderAccessParticipant, // Видимость загрузки, при открытии окна предоставление доступа к экзамену участнику
        isVisibleModalPasswordConfirm, // Видимость окна подтверждения для ввода пароля
        isVisibleTimeIncrease, // Видимость окна увеличения времени у участника
        isVisibleViolation, // Видимость окна удаления участника с экзамена за нарушение

        selectedUnassignedUserID, setSelectedUnassignedUserID, // Выбранный идентификатор пользователя в списке не назначенных
        valueViolation, setValueViolation, // Краткий комментарий, удаление участника с экзамена за нарушение

        valueSearchUnassignedUsers, setValueSearchUnassignedUsers, // Поиск не назначенных пользователей
        valueAudience, setValueAudience, // Значение номера аудитории в окне редактирования аудитории
        valueTimeIncrease, // Количество минут для увеличения времени у частника
        setValueLoginPassword, // Пароль оператора, для подтверждения

        regexNumber, // Проверка времени в минутах
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <Examinator {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        examInfo: state.exams.examInfo,
        unassignedUsersList: state.exams.unassignedUsersList,
        assignedUsersList: state.exams.assignedUsersList,
    }
}

export default compose(connect(mapState, {
    getExamInfo, clearExamInfo, editExamAudience,
    getUnassignedUsers, clearUnassignedUsers,
    getAssignedUsers, clearAssignedUsers,
    addUserOnExam, deleteUserOnExam,
    passwordConfirm, editTimeIncrease, participantViolation
}))(ExaminatorContainer)