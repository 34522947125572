import React from 'react'

import Button from '../../UIComponents/Button/Button'
import Drawer from '../../UIComponents/Drawer/Drawer'
import Loader from '../../UIComponents/Loader/Loader'
import Empty from '../../UIComponents/Empty/Empty'

import { TestsTranslationScaleListType } from '../../../bll/tests/types'
import imgEmpty from './../../../images/empty_documents.svg'
import css from './translation-scale.module.scss'
import Input from '../../UIComponents/Input/Input'
import { BeatLoader } from 'react-spinners'

const TranslationScale: React.FC<any> = (props) => {
    return (
        <Drawer size={`500px`} isOpen={props.isOpen} placement='right'>
            <section className='drawerPrimary'>
                {props.isVisibleLoader
                    ? <Loader open={props.isVisibleLoader} color='#FF6700' title='Загрузка...' />
                    : <main className='drawerContent'>
                        <header className='drawerHeader' style={{ alignItems: 'center' }}>
                            <h4>Шкала перевода...</h4>

                            <Button variant='default' size='xs' onClick={() => props.onClose(false)}>
                                <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                                </svg>
                            </Button>
                        </header>

                        <section className='drawerContainer'>
                            <div className={css['tHeader']}>
                                <span className={css['tAlignCenter']}>Первичный балл</span>
                                <span>Тестовый балл</span>
                            </div>

                            <section className='drawerItems'>
                                {!props.localTranslationScale?.length
                                    ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='200px' />
                                    : props.localTranslationScale.map((item: TestsTranslationScaleListType) => {
                                        return (
                                            <div key={item.translationScaleID} className={css['tRow']}>
                                                <span className={css['tAlignCenter']}>{item.primaryBal}</span>

                                                <Input variant='primary' type='text' placeholder='Введите балл'
                                                    value={item.finalBal} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.regexTranslationScale(item.translationScaleID, e.target.value)}
                                                    suffix={
                                                        props.sendTranslationScaleID === item.translationScaleID && props.timerSendTranslationScaleID !== null 
                                                        ? <BeatLoader color="#FF6700" size={14} /> 
                                                        : <div>✔</div>
                                                    }
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </section>
                        </section>
                    </main>
                }
            </section>
        </Drawer>
    )
}

export default TranslationScale