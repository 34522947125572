import api from '../../api/apiExams'
import actions from './exams.actions'

// Получение экзаменов
export const getExams = () => async (dispatch: any) => {
    const responce = await api.getExams()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetExamsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение готовых тестов
export const getReadyTests = () => async (dispatch: any) => {
    const responce = await api.getReadyTests()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetReadyTestsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание (редактирование) экзамена
export const createEditExams = (action: string, examID: string, testID: string, examDate: string, isLockedExam: boolean) => async (dispatch: any) => {
    const responce = await api.createEditExams(action, examID, testID, examDate, isLockedExam)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getExams())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение не назначенных пользователей
export const getUnassignedUsers = (examID: string) => async (dispatch: any) => {
    const responce = await api.getUnassignedUsers(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetUnassignedUsersListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение назначенных пользователей
export const getAssignedUsers = (examID: string, audience?: string) => async (dispatch: any) => {
    const responce = await api.getAssignedUsers(examID, audience)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetAssignedUsersListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение назначенных пользователей
export const addUserOnExam = (examID: string, loginID: string, audience?: string) => async (dispatch: any) => {
    const responce = await api.addUserOnExam(examID, loginID, audience)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getAssignedUsers(examID, audience))
                dispatch(getUnassignedUsers(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление пользователей, с экзамена
export const deleteUserOnExam = (examID: string, loginID: string, audience?: string) => async (dispatch: any) => {
    const responce = await api.deleteUserOnExam(examID, loginID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getAssignedUsers(examID, audience))
                dispatch(getUnassignedUsers(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение информации об экзамене для Оператора экзаменатора и Участника тестирования
export const getExamInfo = (examID: string) => async (dispatch: any) => {
    const responce = await api.getExamInfo(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
            case 'EXAM_STARTED': // Если экзамен начат участником
                dispatch(actions.examsSetExamInfoAction(responce.data.itemsData))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Изменение аудитории Оператором экзаменатором
export const editExamAudience = (examID: string, audience: string, oldAudience: string) => async (dispatch: any) => {
    const responce = await api.editExamAudience(examID, audience, oldAudience)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getExamInfo(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание экзамена для участника
export const createExamParticipant = (examID: string) => async (dispatch: any) => {
    const responce = await api.createExamParticipant(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                //dispatch(getExamInfo(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение справочного материала по экзамену для участника тестирования
export const getReferenceMaterial = (examID: string) => async (dispatch: any) => {
    const responce = await api.getReferenceMaterial(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetReferenceMaterialAction(responce.data.itemsData))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение справочного материала по экзамену для участника тестирования
export const getQuestionsAndAnswers = (examID: string) => async (dispatch: any) => {
    const responce = await api.getQuestionsAndAnswers(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetQuestionsAndAnswersAction(responce.data.itemsData))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование ответов участника
export const updateAnswerParticipant = (examID: string, data: any) => async (dispatch: any) => {
    const responce = await api.updateAnswerParticipant(data)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getQuestionsAndAnswers(examID))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Завершение экзамена участником
export const endExamByParticipant = (examID: string) => async (dispatch: any) => {
    const responce = await api.endExamByParticipant(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                // dispatch(actions.examsSetReferenceMaterialAction(responce.data.itemsData))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение результата участника
export const getParticipantResult = (examID: string, loginID?: string) => async (dispatch: any) => {
    const responce = await api.getParticipantResult(examID, loginID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examsSetParticipantResultAction(responce.data.itemsData))
                return { code: responce.data.statusCode }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Увеличение минут у участника
export const editTimeIncrease = (examID: string, audience: string, examParticipantID: string, oldMinute: number, newMinute: number) => async (dispatch: any) => {
    const responce = await api.editTimeIncrease(examParticipantID, oldMinute, newMinute)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getAssignedUsers(examID, audience))
                dispatch(getUnassignedUsers(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление участника с экзамена за нарушение
export const participantViolation = (examID: string, audience: string, examParticipantID: string, valueViolation: string) => async (dispatch: any) => {
    const responce = await api.participantViolation(examParticipantID, valueViolation)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getAssignedUsers(examID, audience))
                dispatch(getUnassignedUsers(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение файлов отчета по экзамену
export const getReportFileList = (examID: string) => async (dispatch: any) => {
    const responce = await api.getReportFileList(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.examReportListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание excel отчетов по экзамену
export const createReportExam = (examID: string) => async (dispatch: any) => {
    const responce = await api.createReportExam(examID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getReportFileList(examID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}




// Очистка экзаменов
export const clearExams = () => async (dispatch: any) => {
    dispatch(actions.examsSetExamsListAction(undefined))
}

// Очистка готовых тестов
export const clearReadyTests = () => async (dispatch: any) => {
    dispatch(actions.examsSetReadyTestsListAction(undefined))
}

// Очистка не назначенных пользователей
export const clearUnassignedUsers = () => async (dispatch: any) => {
    dispatch(actions.examsSetUnassignedUsersListAction(undefined))
}

// Очистка назначенных пользователей
export const clearAssignedUsers = () => async (dispatch: any) => {
    dispatch(actions.examsSetAssignedUsersListAction(undefined))
}

// Очистка информации об экзамене для Оператора экзаменатора и Участника тестирования
export const clearExamInfo = () => async (dispatch: any) => {
    dispatch(actions.examsSetExamInfoAction(undefined))
}

// Очистка справочного материала по экзамену для участника тестирования
export const clearReferenceMaterial = () => async (dispatch: any) => {
    dispatch(actions.examsSetReferenceMaterialAction(undefined))
}

// Очистка справочного материала по экзамену для участника тестирования
export const clearQuestionsAndAnswers = () => async (dispatch: any) => {
    dispatch(actions.examsSetQuestionsAndAnswersAction(undefined))
}

// Очистка результата участника
export const clearParticipantResult = () => async (dispatch: any) => {
    dispatch(actions.examsSetParticipantResultAction(undefined))
}