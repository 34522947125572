import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import CorrectAnswers from './correct-answers'
import { AppStateType } from '../../../bll/store'
import errorHandler from '../../../bll/errorHandler'
import { clearCorrectAnswersByQuestions, createCorrectAnswersByQuestions, deleteCorrectAnswer, getCorrectAnswersByQuestions, updateCorrectAnswers } from '../../../bll/tests/operations'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import { CorrectAnswersListType } from '../../../bll/tests/types'


const CorrectAnswersContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)
    //props.testID, props.variantID, props.editQuestionID

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных

    const [timerSendCorrectAnswerID, setSimerSendCorrectAnswerID] = React.useState<number | null>(null) // Идентификатор таймера отправки данных на сервер
    const [sendCorrectAnswerID, setSendCorrectAnswerID] = React.useState<string | number | null>(null) // Идентификатор редактируемого ответа
    const [localCorrectAnswers, setLocalCorrectAnswers] = React.useState<CorrectAnswersListType[] | null>(null) // Локальный state правильных ответов по текущему вопросу

    React.useEffect(() => {
        if (props.isVisibleCorrectAnswers) {
            setIsVisibleLoader(true)

            Promise.all([ props.getCorrectAnswersByQuestions(props.editQuestionID) ]).then((result) => {
                let isGlobalError = false
    
                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })
    
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0019, "ERROR")
                // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
                // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0018, "ERROR")

                setIsVisibleLoader(false)
            })
        }

        return () => {
            props.clearCorrectAnswersByQuestions()
            setLocalCorrectAnswers(null)
        }
    }, [props.isVisibleCorrectAnswers]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.correctAnswersList) {
            setLocalCorrectAnswers(props.correctAnswersList)
        }
    }, [props.correctAnswersList])

    // Сравнить localCorrectAnswers с correctAnswers и вернуть true, если они равны
    const areCorrectAnswersEqual = React.useMemo(() => {
        return JSON.stringify(localCorrectAnswers) === JSON.stringify(props.correctAnswersList)
    }, [localCorrectAnswers, props.correctAnswersList])

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Создание ответа
    const createCorrectAnswer = () => {
        props.createCorrectAnswersByQuestions(props.testID, props.variantID, props.editQuestionID).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Удаление ответа
    const deleteCorrectAnswer = (correctAnswerID: string | number) => {
        props.deleteCorrectAnswer(props.testID, props.variantID, props.editQuestionID, correctAnswerID).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Проверка по регулярке, вводимость правильного ответа по вопросу
    const regexCorrectAnswer = (answerID: string | number, value: string) => {
        setSendCorrectAnswerID(answerID)

        const re: RegExp = new RegExp(props.regularAnswers.replace(/^\/|\/$/g, ''), 'g')
        if (value === '' || re.test(value)) {
            if (Array.isArray(localCorrectAnswers)) {
                const updatedAnswers = localCorrectAnswers.map((answer) => {
                    if (answer.correctAnswerID === answerID) {
                        return { ...answer, answer: value }
                    }
                    return answer
                })

                setLocalCorrectAnswers(updatedAnswers)

                // Если таймер уже запущен, то удаляем его
                if (timerSendCorrectAnswerID) clearTimeout(timerSendCorrectAnswerID)

                // Устанавливаем таймер на отправку данных через 2 секунды
                const timerID = window.setTimeout(() => {
                    sendDataToServer(updatedAnswers)
                    setSimerSendCorrectAnswerID(null)
                }, 3000)

                setSimerSendCorrectAnswerID(timerID)
            }
        }
    }

    // Отправляем данные на сервер
    const sendDataToServer = (data: any) => {
        props.updateCorrectAnswers(props.testID, props.variantID, props.editQuestionID, data).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })

        if (timerSendCorrectAnswerID) clearTimeout(timerSendCorrectAnswerID)
        if (sendCorrectAnswerID) setSendCorrectAnswerID(null)
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        areCorrectAnswersEqual, // Сравнить localCorrectAnswers с correctAnswers и вернуть true, если они равны
        
        isVisibleLoader, // Видимость загрузки, при получении данных
        localCorrectAnswers, // Локальный state правильных ответов по вопросу
        timerSendCorrectAnswerID, // Идентификатор таймера отправки данных на сервер
        sendCorrectAnswerID, // Идентификатор редактируемого ответа

        createCorrectAnswer, // Создание ответа
        deleteCorrectAnswer, // Удаление ответа
        regexCorrectAnswer, // Проверка по регулярке, вводимость правильного ответа по вопросу
    }

    return (
        <CorrectAnswers {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        correctAnswersList: state.tests.correctAnswersList,
    }
}

export default compose(connect(mapState, { 
    getCorrectAnswersByQuestions, clearCorrectAnswersByQuestions, 
    createCorrectAnswersByQuestions, deleteCorrectAnswer, updateCorrectAnswers
}))(CorrectAnswersContainer)