import React from 'react'
import css from './Drawer.module.scss'

interface DrawerProps {
    isOpen: boolean
    children: React.ReactNode
    onClose?: () => void
    size?: string
    placement?: 'top' | 'right' | 'bottom' | 'left'
    zIndex?: number
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children, size = '400px', placement = 'right', zIndex = 1000 }) => {
    const transformValues = {
        left: { open: 'translateX(0)', closed: 'translateX(-101%)' },
        right: { open: 'translateX(0)', closed: 'translateX(101%)' },
        top: { open: 'translateY(0)', closed: 'translateY(-101%)' },
        bottom: { open: 'translateY(0)', closed: 'translateY(101%)' }
    }
    
    const drawerWrapperStyle: React.CSSProperties = {
        zIndex: zIndex,
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'auto' : 'none',
    }

    const drawerWrapperContentStyle: React.CSSProperties = {
        zIndex: zIndex,
        width: ['left', 'right'].includes(placement) ? size : '100%',
        height: ['top', 'bottom'].includes(placement) ? size : '100%',
        transform: isOpen ? transformValues[placement].open : transformValues[placement].closed,
    }

    return (
        <div className={css['drawer-wrapper']} style={drawerWrapperStyle} onClick={onClose}>
            <div className={`${css['drawer-wrapper-content']} ${css[`drawer-${placement}`]}`} style={drawerWrapperContentStyle}>
                {children}
            </div>
        </div>
    )
}

export default Drawer