import React from 'react'

import css from './Notification.module.scss'
import Notification from './Notification'

export const NotificationContext = React.createContext<any>(undefined)

const NotificationProvider = (props: any) => {
    const [state, dispatch] = React.useReducer((state: any, action: any) => {
        switch (action.type) {
            case 'INFO': 
            case 'SUCCESS': 
            case 'WARNING': 
            case 'ERROR': return [...state, {...action.payload}]
                
            case 'REMOVE_NOTIFICATION': return state.filter((el: any) => el.id !== action.id)
            default: return state
        }
    }, [])

    return (
        <NotificationContext.Provider value={dispatch}>
            <div className={css.notificationWrapper}>
                {state.map((note: any) => {
                    return <Notification dispatch={dispatch} key={note.id} {...note} />
                })}
            </div>

            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider