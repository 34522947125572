import { ITestsAction } from "./interfaces"
import { CorrectAnswersListType, StatisticStructureType, TestsListType, TestsReferenceMaterialListType, TestsStructureListType, TestsTranslationScaleListType, TestsVariantQuestionsListType, TestsVariantsListType } from "./types"

let initialState = { // Инициализационный State
    testsList: [] as Array<TestsListType> | null,
    testsStructureList: [] as Array<TestsStructureListType> | null,
    testsReferenceMaterialList: [] as Array<TestsReferenceMaterialListType> | null,
    testsTranslationScaleList: [] as Array<TestsTranslationScaleListType> | null,
    testsVariantsList: [] as Array<TestsVariantsListType> | null,
    testsVariantQuestionsList: [] as Array<TestsVariantQuestionsListType> | null,
    correctAnswersList: [] as Array<CorrectAnswersListType> | null,
    
    statisticStructure: {} as StatisticStructureType | null
}


// Reducer тесты
export const testsReducer = (state = initialState, action: ITestsAction): typeof initialState => {
    switch (action.type) {
        case 'LOGIN__LOGOUT_APP': { return { ...state = initialState } }

        case 'TESTS__SET_TESTS_LIST': {
            return {
                ...state,
                testsList: action.testsList
            }
        }

        case 'TESTS__SET_TESTS_STRUCTURE_LIST': {
            return {
                ...state,
                testsStructureList: action.testsStructureList
            }
        }

        case 'TESTS__SET_TESTS_REFERENCE_MATERIAL_LIST': {
            return {
                ...state,
                testsReferenceMaterialList: action.testsReferenceMaterialList
            }
        }

        case 'TESTS__SET_TESTS_TRANSLATION_SCALE_LIST': {
            return {
                ...state,
                testsTranslationScaleList: action.testsTranslationScaleList
            }
        }

        case 'TESTS__SET_TESTS_VARIANTS_LIST': {
            return {
                ...state,
                testsVariantsList: action.testsVariantsList
            }
        }

        case 'TESTS__SET_TESTS_VARIANT_QUESTIONS_LIST': {
            return {
                ...state,
                testsVariantQuestionsList: action.testsVariantQuestionsList
            }
        }

        case 'TESTS__SET_CORRECT_ANSWERS_LIST': {
            return {
                ...state,
                correctAnswersList: action.correctAnswersList
            }
        }

        case 'TESTS__SET_STATISTIC_STRUCTURE': {
            return {
                ...state,
                statisticStructure: action.statisticStructure
            }
        }

        default: return state
    }
}