import api from './api'

export default class ApiOperators {

    // Получение списка операторов
    static async getOperators(): Promise<any> {
        try {
            return await api.get('/operators/getOperators')
        } catch (error) {
            return error
        }
    }

    // Создание (Редактирование) оператора
    static async createEditOperator(action: string, loginID: string, loginName: string, loginPassword: string, lastName: string, firstName: string, secondName: string, snils: string, phone: string, docSeries: string, docNumber: string, accessLevelID: number, isBlocked: boolean): Promise<any> {
        try {
            const data = {
                action: action,
                loginName: loginName,
                loginPassword: loginPassword,
                lastName: lastName,
                firstName: firstName,
                secondName: secondName,
                snils: snils,
                phone: phone,
                docSeries: docSeries,
                docNumber: docNumber,
                accessLevelID: accessLevelID,
                isBlocked: isBlocked
            }

            return await api.post(`/operators/createEditOperator?loginID=${loginID}`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

    // Загрузка файла с участниками
    static async uploadFileParticipants(file: any, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("participants", file)

        try {
            return await api.post(`/operators/uploadFileParticipants?fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }

    // Создание участников в автоматизированном режиме из файла
    static async createParticipants(data: any): Promise<any> {
        try {
            return await api.post(`/operators/createParticipants`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

}