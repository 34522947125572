import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import { changeVariantErrors, clearTestVariantQuestions, getTestVariantQuestions, uploadQuestion } from '../../bll/tests/operations'
import Loader from '../UIComponents/Loader/Loader'
import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import TestQuestions from './testQuestions'

const TestQuestionsContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleCorrectAnswers, setIsVisibleCorrectAnswers] = React.useState<boolean>(false) // Видимость компоненты правильные ответы
    
    const [editQuestionID, setEditQuestionID] = React.useState<string>('') // Идентификатор редактируемого вопроса
    const [regularAnswers, setRegularAnswers] = React.useState<string>('') // Регулярное выражение вопроса
    const [correctAnswersHeaderPrimary, setSorrectAnswersHeaderPrimary] = React.useState<string>('') // Заголовок основной в окне ввода правильных ответов по вопросу
    const [correctAnswersHeaderSecondary, setSorrectAnswersHeaderSecondary] = React.useState<string>('') // Заголовок вторичный в окне ввода правильных ответов по вопросу


    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ 
            props.getTestVariantQuestions(getLocation.state.testID, getLocation.state.variantID),
            props.changeVariantErrors(getLocation.state.testID, getLocation.state.variantID),
        ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0019, "ERROR")
            // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
            // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0018, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearTestVariantQuestions()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Загрузка файлов на сервер
    const fileUploader = (fileList: any, questionID: string, subjectID: number, basisTrainingID: number, variantID: string, previousFileName: string) => {
        fileList.forEach((file: any) => {
            props.uploadQuestion(file, getLocation.state.testID, questionID, subjectID, basisTrainingID, variantID, previousFileName, file.name).then((result: any) => {
                switch (result?.code) {
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0021, "ERROR")
                    case "ERR_FILE_NOT_FOUND": return notifiMsg(props.msg.error.E0022, "ERROR")
                    case "ERR_FILE_EXTNAME": return notifiMsg(props.msg.error.E0023, "ERROR")
                    case "ERR_FILE_SIZE": return notifiMsg(props.msg.error.E0024, "ERROR")
                    default: return errorHandler(result?.code, props.msg, navigate)
                }
            })
        })
    }

    // Открытие (Закрытие) окна по добавлению или удалению правильных ответов по вопросу
    const openCorrectAnswers = (isOpen: boolean, questionID: string = '', regular: string = '', headerPrimary: string = '', headerSecondary: string = '') => {
        setEditQuestionID(questionID)
        setRegularAnswers(regular)
        setSorrectAnswersHeaderPrimary(headerPrimary)
        setSorrectAnswersHeaderSecondary(headerSecondary)
        
        setIsVisibleCorrectAnswers(isOpen)
    }
    

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        testID: getLocation.state.testID,
        variantID: getLocation.state.variantID,
        subjectName: getLocation.state.subject,
        basisTrainingName: getLocation.state.basisTraining,
        countQuestions: getLocation.state.countQuestions,
        variantNumber: getLocation.state.variantNumber,

        fileUploader, // Загрузка файлов на сервер
        openCorrectAnswers, // Открытие (Закрытие) окна по добавлению или удалению правильных ответов по вопросу

        isVisibleCorrectAnswers, // Видимость компоненты правильные ответы
        editQuestionID, // Идентификатор редактируемого вопроса
        regularAnswers, // Регулярное выражение вопроса
        correctAnswersHeaderPrimary, // Заголовок основной в окне ввода правильных ответов по вопросу
        correctAnswersHeaderSecondary, // Заголовок вторичный в окне ввода правильных ответов по вопросу
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <TestQuestions {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        testsVariantQuestionsList: state.tests.testsVariantQuestionsList,
    }
}

export default compose(connect(mapState, {
    getTestVariantQuestions, clearTestVariantQuestions, uploadQuestion, changeVariantErrors
}))(TestQuestionsContainer)