import { IIsAppInitialized, IIsAuthorized, ILogout, IUserProfile } from "./interfaces"
import { UserProfileType } from "./types"


export const loginLogoutAction = (): ILogout => ({ 
    type: 'LOGIN__LOGOUT_APP' 
})

const loginIsAuthorizedAction = (isAuthorized: boolean): IIsAuthorized => ({ 
    type: 'LOGIN__IS_AUTHORIZED', isAuthorized
})

const loginIsAppInitializedAction = (isAppInitialized: boolean): IIsAppInitialized => ({ 
    type: 'LOGIN__IS_APP_INITIALIZED', isAppInitialized 
})

const loginSetUserProfileAction = (userProfile: UserProfileType | null): IUserProfile => ({ 
    type: 'LOGIN__SET_USER_PROFILE', userProfile
})


const loginActions = {
    loginLogoutAction, // Выход из системы
    loginIsAuthorizedAction, // Установка метки, что авторизация выполнена
    loginIsAppInitializedAction, // Установка метки, что инициализация приложения выполнена
    loginSetUserProfileAction, // Личные данные оператора
}

export default loginActions