import React from 'react'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import { SelectOptionType } from '../UIComponents/Select/Select'
import Loader from '../UIComponents/Loader/Loader'

import { AppStateType } from '../../bll/store'
import errorHandler from '../../bll/errorHandler'
import { ReadyTestsListTypes } from '../../bll/exams/exams.types'
import { clearExams, clearReadyTests, createEditExams, getExams, getReadyTests } from '../../bll/exams/exams.operations'
import Exams from './exams'

const ExamsContainer = (props: any) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleExamBlocked, setIsVisibleExamBlocked] = React.useState<boolean>(false) // Значение, закрыт экзамен или нет
    const [isVisibleModalCreateExam, setIsVisibleModalCreateExam] = React.useState<boolean>(false) // Видимость окна, создание экзамена
    const [isVisibleModalSelectDate, setIsVisibleModalSelectDate] = React.useState<boolean>(false) // Видимость окна выбора даты
    const [isVisibleModalUserAccess, setIsVisibleModalUserAccess] = React.useState<boolean>(false) // Видимость окна допуск к экзамену
    const [isVisibleDrawerReport, setIsVisibleDrawerReport] = React.useState<boolean>(false) // Видимость окна отчеты экзамена

    const [actionModalExam, setActionModalExam] = React.useState<'create' | 'edit' | null>(null) // Действие, создание или редактирование экзамена
    const [selectedTest, setSelectedTest] = React.useState<SelectOptionType | undefined>(undefined) // Выбранный тест в окне создания теста
    const [selectedDateToCalendar, setSelectedDateToCalendar] = React.useState<Date>(new Date()) // Выбранная дата в календаре
    const [valueDateStart, setValueDateStart] = React.useState<string>('') // Данные поля, старт экзамена
    const [getValueDateState, setValueDateState] = React.useState<Date | undefined>(undefined) // Выбранная дата в поле старт экзамена
    const [valueExamID, setValueExamID] = React.useState<string | undefined>('') // Идентификатор редактируемого экзамена
    const [valueExamName, setValueExamName] = React.useState<string | undefined>('') // Предмет и основа обуения в окне допуск к экзамену

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getExams(), props.getReadyTests() ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0028, "ERROR")
            if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0029, "ERROR")
            // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0018, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearExams()
            props.clearReadyTests()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Список готовых тестов
    let testsOptions: SelectOptionType[] = []
    props.readyTestsList?.map((item: ReadyTestsListTypes) => (
        testsOptions.push({ id: item.testID, label: `${item.subjectName} (${item.basisTrainingName})` })
    ))

    // Открытие окна, создание (редактирование) экзамена
    const openModalCreateEditExam = (isOpen: boolean, action: 'create' | 'edit' | null = null, test: SelectOptionType | undefined = undefined, dateData: Date | undefined = undefined, examID: string | undefined = undefined, examStatus: boolean = false) => {
        setSelectedTest(test)
        setActionModalExam(action)
        setValueExamID(examID)
        setIsVisibleExamBlocked(examStatus)
        
        if (dateData) { 
            setValueDateState(dateData)
            setValueDateStart(new Date(dateData).toLocaleString('default', {day: 'numeric', month: 'long', year: 'numeric'}))
        } else {
            setValueDateStart('')
            setValueDateState(undefined)
        }
        
        setIsVisibleModalCreateExam(isOpen)
    }

    // Открытие окна, выбор даты экзамена
    const openModalSelectDate = (isOpen: boolean) => {
        setSelectedDateToCalendar(new Date())
        setIsVisibleModalSelectDate(isOpen)
    }

    // Открытие окна допуск к экзамену
    const openModalUserAccess = (isOpen: boolean, examID: string | undefined = undefined, examName: string | undefined = undefined) => {
        setValueExamID(examID)
        setValueExamName(examName)
        setIsVisibleModalUserAccess(isOpen)
    }

    // Закрытие или открытие окна отчетов
    const openOrCloseReport = (isOpen: boolean, valExamID?: string, valExamName?: string) => {
        setValueExamID(valExamID)
        setValueExamName(valExamName)
        setIsVisibleDrawerReport(isOpen)
    }

    // Кнопка OK, в окне выбора даты
    const btnApplyDate = () => {
        setIsVisibleModalSelectDate(false)
        setValueDateState(selectedDateToCalendar)
        setValueDateStart(new Date(selectedDateToCalendar).toLocaleString('default', {day: 'numeric', month: 'long', year: 'numeric'}))

        setSelectedDateToCalendar(new Date())
    }

    // Кнопка создать, в окне создания (редактирования) экзамена
    const btnApplyModalCreateEditExam = () => {
        let isFieldsError: boolean = false

        if (selectedTest === undefined) { isFieldsError = true; notifiMsg(props.msg.warning.W0012, "WARNING") }
        if (getValueDateState === undefined) { isFieldsError = true; notifiMsg(props.msg.warning.W0013, "WARNING") }

        if (!isFieldsError) {
            props.createEditExams(actionModalExam, valueExamID, selectedTest?.id, moment(getValueDateState).format("YYYY-MM-DD"), isVisibleExamBlocked).then((result: any) => {
                switch (result?.code) {
                    case 'OK': openModalCreateEditExam(false); break
                    case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        actionModalExam, // Действие, создание или редактирование экзамена

        isVisibleModalCreateExam, // Видимость окна, создание экзамена
        isVisibleModalSelectDate, // Видимость окна выбора даты
        isVisibleModalUserAccess, // Видимость окна допуск к экзамену
        
        isVisibleExamBlocked, setIsVisibleExamBlocked, // Значение, закрыт экзамен или нет

        testsOptions, // Список готовых тестов
        valueExamID, // Идентификатор редактируемого экзамена
        valueExamName, // Предмет и основа обуения в окне допуск к экзамену
        selectedTest, setSelectedTest, // Выбранный тест в окне создания теста
        selectedDateToCalendar, setSelectedDateToCalendar, // Выбранная дата в календаре
        valueDateStart, setValueDateStart, setValueDateState, // Данные поля, старт экзамена

        openModalUserAccess, // Открытие окна допуск к экзамену
        openModalCreateEditExam, // Открытие окна, создание (редактирование) экзамена
        openModalSelectDate, // Открытие окна, выбор даты экзамена
        btnApplyDate, // Кнопка OK, в окне выбора даты
        btnApplyModalCreateEditExam, // Кнопка создать, в окне создания (редактирования) экзамена

        isVisibleDrawerReport,
        openOrCloseReport,
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <Exams {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        examsList: state.exams.examsList,
        readyTestsList: state.exams.readyTestsList,
    }
}

export default compose(connect(mapState, {
    getExams, clearExams, createEditExams,
    getReadyTests, clearReadyTests
}))(ExamsContainer)