import React from 'react'
import logo from './../../../../images/logo.png'
import css from './exam-end.module.scss'
import { IExamEnd } from './exam-end.interface'
import Button from '../../../UIComponents/Button/Button'
import { NavLink } from 'react-router-dom'

const ExamEnd: React.FC<IExamEnd> = (props) => {
    return (
        <div className={css['container']}>
            <header className={css['header']}>
                <img src={logo} alt="ФГБОУ ВО Вавиловский университет Платформа МегаТестер" />
                <span>Платформа МегаТестер<br />ФГБОУ ВО Вавиловский университет</span>
            </header>

            <main className={css['main']}>
                {props.isViolationParticipant
                    ? <h3 style={{ textAlign: 'center', lineHeight: '150%' }}>Вы были удалены с экзамена<br />За нарушение правил проведения</h3>
                    : props.isResultNotFound
                        ? <h3 style={{ textAlign: 'center', lineHeight: '150%' }}>По не предвиденным причинам<br />Ваш результат не найден</h3>
                        : <>
                            <h3>{props.participantResult?.lastName} {props.participantResult?.firstName} {props.participantResult?.secondName}</h3>
                            <h4>{props.participantResult?.subjectName}</h4>
                            <br/>
                            <h4>Номер Вашего варианта {props.participantResult?.variantNumber}{props.participantResult.basisTrainingID === 1 ? '00' : ''}</h4>
                            <h4>Длительность экзамена в минутах {props.participantResult?.durationExam}</h4>
                            {/* <h4>Ваш результат в баллах {props.participantResult?.finalBal}</h4> */}
                        </>
                }
            </main>

            <footer className={css['footer']}>
                {!props.isViolationParticipant && !props.isResultNotFound && <>
                    <div style={{ textAlign: 'center' }}>
                        <div>Время начала</div>
                        <div>{new Date(props.participantResult?.examDateTimeStart).toLocaleString('default', { hour: 'numeric', minute: 'numeric', second: 'numeric' })}</div>
                    </div>
                    
                    {/* <div style={{ textAlign: 'center' }}>{props.participantResult?.finalBal >= props.participantResult?.porog ? <div style={{ color: '#00CC99' }}>Экзамен<br/>успешно сдан</div> : <div style={{ color: '#EB5757' }}>Экзамен<br/>не сдан</div>}</div> */}

                    <div>
                        <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M145.208 126.212L133.933 128.877C131.405 129.492 129.423 131.405 128.877 133.934L126.485 143.979C125.187 149.445 118.217 151.154 114.595 146.849L94.1633 123.342C92.5233 121.429 93.4117 118.422 95.8717 117.807C107.967 114.869 118.832 108.104 126.827 98.4688C128.125 96.8971 130.448 96.6921 131.883 98.1271L147.053 113.297C152.247 118.49 150.402 124.982 145.208 126.212Z" fill="currentColor" fillOpacity="0.88" />
                            <path d="M18.45 126.212L29.725 128.877C32.2533 129.492 34.235 131.405 34.7817 133.934L37.1733 143.979C38.4717 149.445 45.4417 151.154 49.0633 146.849L69.495 123.342C71.135 121.429 70.2467 118.422 67.7867 117.807C55.6917 114.869 44.8267 108.104 36.8317 98.4688C35.5333 96.8971 33.21 96.6921 31.775 98.1271L16.605 113.297C11.4117 118.49 13.2567 124.982 18.45 126.212Z" fill="currentColor" fillOpacity="0.88" />
                            <path d="M82 13.667C55.555 13.667 34.1667 35.0553 34.1667 61.5003C34.1667 71.4087 37.105 80.497 42.1617 88.082C49.5417 99.0153 61.2267 106.737 74.825 108.719C77.1483 109.129 79.54 109.334 82 109.334C84.46 109.334 86.8517 109.129 89.175 108.719C102.773 106.737 114.458 99.0153 121.838 88.082C126.895 80.497 129.833 71.4087 129.833 61.5003C129.833 35.0553 108.445 13.667 82 13.667ZM102.91 59.997L97.2383 65.6687C96.2817 66.6253 95.735 68.4703 96.0767 69.837L97.7167 76.8753C99.015 82.4103 96.0767 84.597 91.1567 81.6587L84.3233 77.627C83.0933 76.8753 81.0433 76.8753 79.8133 77.627L72.98 81.6587C68.06 84.5287 65.1217 82.4103 66.42 76.8753L68.06 69.837C68.3333 68.5387 67.855 66.6253 66.8983 65.6687L61.09 59.997C57.7417 56.6487 58.835 53.3003 63.4817 52.5487L70.7933 51.3187C72.0233 51.1137 73.4583 50.0203 74.005 48.927L78.0367 40.8637C80.2233 36.4903 83.7767 36.4903 85.9633 40.8637L89.995 48.927C90.5417 50.0203 91.9767 51.1137 93.275 51.3187L100.587 52.5487C105.165 53.3003 106.258 56.6487 102.91 59.997Z" fill="currentColor" fillOpacity="0.88" />
                        </svg>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        <div>Дата экзамена</div>
                        <div>{new Date(props.participantResult?.examDateTimeStart).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                    </div>
                </>}
            </footer>

            <NavLink to='/main' style={{ width: '80%' }}>
                <Button variant='secondary' size='md' width={'100%'}>
                    <span>ВЕРНУТЬСЯ НА ГЛАВНУЮ СТРАНИЦУ</span>
                </Button>
            </NavLink>
        </div>
    )
}

export default ExamEnd