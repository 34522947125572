let initialState = {
    success: {

    },

    info: {
        I0001: "В доступе отказано!<br/>Не верный логин или пароль.",
        I0002: "В доступе отказано!<br/>Экзамен завершен.",
        I0003: "В доступе отказано!<br/>Вы удалены с экзамена, за нарушение.",
        I0004: "В доступе отказано!<br/>Вы завершили экзамен.",
    },

    warning: {
        W0001: "Для входа в личный кабинет, необходимо ввести <b>Логин пользователя</b> и <b>Пароль пользователя</b>.",
        W0002: "Логин пользователя не может быть <b>пустым</b> и должен содержать <b>больше 3х символов</b>.",
        W0003: "Пароль пользователя не может быть <b>пустым</b> и должен содержать <b>больше 6ти символов</b>.",
        W0004: "Необходимо выбрать из списка <b>уровень доступа</b> пользователя.",
        W0005: "Фамилия пользователя не может быть <b>пустым</b> и должна содержать <b>больше 1го символа</b>.",
        W0006: "Имя пользователя не может быть <b>пустым</b> и должна содержать <b>больше 1го символа</b>.",
        W0007: "Необходимо выбрать из списка <b>предмет</b>.",
        W0008: "Необходимо выбрать из списка <b>основу обучения</b>.",
        W0009: "Необходимо указать время <b>теста в минутах</b>. Первая цифра не может быть нулём.",
        W0010: "Необходимо выбрать <b>тип ответа</b> из списка.",
        W0011: "Перед изменением <b>типа ответа</b> необходимо удалить все правильные ответы для данного вопроса во всех вариантах, где они были введены по этому номеру.",
        W0012: "Необходимо выбрать из списка <b>тест</b>.",
        W0013: "Необходимо выбрать <b>дату начала экзамена</b>.",
        W0014: "Укажите номер аудитории, внизу справа.",
        W0015: "Необходимо ввести номер аудитории.",
        W0016: "Редактировать номер аудитории запрещено.",
        W0017: "Необходимо выбрать участника из списка, для того что бы предоставить ему доступ к текущему экзамену.",
        W0018: "Максимальное количество минут за раз, можно прибавить 9.",
        W0019: "СНИЛС не может быть <b>пустым</b>.",
        W0020: "Телефон не может быть <b>пустым</b>.",
        W0021: "Номер документа не может быть <b>пустым</b>.",
    },

    error: {
        ENULL: "Сервер не ответил на запрос.<br/>Необходимо обратится к <b>Администратору</b>.",
        PASS_CONFIRM: "Введённый Вами пароль не верный.",
        TEST_BLOCKING: "Администратор закрыл текущий тест, и никакие изменения в нём больше недопустимы.",

        E0001: "Непредвиденная ошибка.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0002: "Сервер <b>не доступен</b>.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0003: "Вы <b>не авторизованы</b>, необходимо повторно войти в систему.",
        E0004: "Ваша сессия более <b>не активна</b>, необходимо повторно войти в систему.",
        E0005: "<b>Дублирование</b> данных, возможно такая запись уже существует.",
        E0006: "Пользователь <b>заблокирован</b>.<br/>Обратитесь за уточнением информации к <b>Администратору</b>.",
        E0007: "Для <b>входа</b> в данный <b>раздел</b>, у Вас <b>отсутствует</b> соответствующий <b>уровень доступа</b>.",
        E0008: "Ошибка в строке запроса, при проверке пользователя.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0009: "Ошибка в строке запроса, при загрузке профиля пользователя.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0010: "Ошибка в строке запроса, при выходе из приложения.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0011: "Ошибка в строке запроса, при получении списка операторов системы.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0012: "Ошибка в строке запроса, при получении списка уровней доступа. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0013: "Тип запроса к серверу не определён. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0014: "Ошибка в строке запроса.<br/>Необходимо обратится к <b>Администратору</b>.",
        E0015: "Ошибка в передаваемых данных на сервер. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0016: "Ошибка в строке запроса, при получении списка тестов. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0017: "Ошибка в строке запроса, при получении списка предметов. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0018: "Ошибка в строке запроса, при получении списка основ обучения. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0019: "Ошибка в строке запроса, при получении структуры теста. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0020: "Ошибка в строке запроса, при получении типов ответа. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0021: "Ошибка в строке запроса, при отправке файла на сервер. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0022: "Файл не найден. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0023: "Недопустимое расширение файла. Разрешены файлы с расширением <b>*.jpeg</b> <b>*.jpg</b> <b>*.png</b>",
        E0024: "Файл не должен быть больше 10 мегабайт.",
        E0025: "Ошибка в строке запроса, при получении статистической информации по структуре теста. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0026: "Ошибка в строке запроса, при получении списка справочного материала. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0027: "Ошибка в строке запроса, при получении списка шкалы. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0028: "Ошибка в строке запроса, при получении списка экзаменов. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0029: "Ошибка в строке запроса, при получении списка готовых тестов. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0030: "Ошибка в строке запроса, при получении списка не распределенных пользователей. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0031: "Ошибка в строке запроса, при получении списка распределенных пользователей. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0032: "Ошибка в строке запроса, при получении данных с сервера. <br/>Необходимо обратится к <b>Администратору</b>.",
        E0033: "Недопустимое расширение файла. Разрешены файлы с расширением <b>*.xls</b> <b>*.xlsx</b>",
    }
}

const messagesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        default: return state
    }
}

export default messagesReducer