import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import { clearAnswerType, getAnswerType } from '../../bll/dict/operations'
import errorHandler from '../../bll/errorHandler'
import { AppStateType } from '../../bll/store'
import { clearStatisticStructure, clearTestStructure, createEditTestStructure, deleteTestStructure, getStatisticStructure, getTestStructure } from '../../bll/tests/operations'
import Loader from '../UIComponents/Loader/Loader'
import { NotificationContext } from '../UIComponents/Notification/NotificationProvider'
import TestStructure from './testStructure'

const TestStructureContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleModalCreateEditStructure, setIsVisibleModalCreateEditStructure] = React.useState<boolean>(false) // Видимость окна, создание (редактирование) структуры
    const [isVisibleModalPasswordConfirm, setIsVisibleModalPasswordConfirm] = React.useState<boolean>(false) // Видимость окна подтверждения для ввода пароля
    
    const [isVisibleDrawerReferenceMaterial, setIsVisibleDrawerReferenceMaterial] = React.useState<boolean>(false) // Видимость окна со справочным материалом
    const [isVisibleDrawerTranslationScale, setIsVisibleDrawerTranslationScale] = React.useState<boolean>(false) // Видимость окна со шкалой перевода

    const [actionName, setActionName] = React.useState<'create' | 'edit' | null>(null) // Действие, создание или редактирование структуры
    const [selectedAnswerTypeID, setSelectedAnswerTypeID] = React.useState<string | number>('') // Значение, идентификатор выбранного тип отета
    const [questionNumber, setQuestionNumber] = React.useState<string | number>('') // Значение, номер вопроса
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string | undefined>() // Пароль оператора, для подтверждения

    
    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ 
            props.getTestStructure(getLocation.state.testID), 
            props.getAnswerType(), 
            props.getStatisticStructure(getLocation.state.testID) ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0019, "ERROR")
            if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
            if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")

            setIsVisibleLoader(false)
        })

        return () => {
            props.clearTestStructure()
            props.clearStatisticStructure()
            props.clearAnswerType()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Открытие окна подтверждения для ввода пароля
    const openModalPasswordConfirm = (isOpen: boolean, id: string | number = '') => {
        setQuestionNumber(id)
        setValueLoginPassword('')

        setIsVisibleModalPasswordConfirm(isOpen)
    }

    // Удаление структуры
    const deleteStructure = () => {
        props.deleteTestStructure(getLocation.state.testID, questionNumber, valueLoginPassword).then((result: any) => {
            switch (result?.code) {
                case 'OK': openModalPasswordConfirm(false); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Открытие (Закрытие) окна, создание (редактирование) структуры
    const openModalCreateEditStructure = (
        isOpen: boolean, 
        action: 'create' | 'edit' | null = null, 
        answerTypeID: string | number = '',
        questNumber: string | number = '') => {
        
        setActionName(action)
        setSelectedAnswerTypeID(answerTypeID)
        setQuestionNumber(questNumber)

        setIsVisibleModalCreateEditStructure(isOpen)
    }

    // Кнопка применить (создать) в окне создания (редактирования) структуры
    const applyButtonCreateEditStructure = () => {
        let isFieldsError: boolean = false

        if (selectedAnswerTypeID === '') { isFieldsError = true; notifiMsg(props.msg.warning.W0010, "WARNING") }

        if (!isFieldsError) {
            props.createEditTestStructure(actionName, getLocation.state.testID, selectedAnswerTypeID, questionNumber, '').then((result: any) => {
                switch (result?.code) {
                    case 'OK': openModalCreateEditStructure(false); break
                    case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                    case "BLOCK_EDIT": return notifiMsg(props.msg.warning.W0011, "WARNING")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Изменение максимального бала
    const changeMaxBal = (questNumber: number | string, maxBal: 1 | 2) => {
        props.createEditTestStructure('bal', getLocation.state.testID, '', questNumber, maxBal).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ACTION_FILED": return notifiMsg(props.msg.error.E0013, "ERROR")
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        testID: getLocation.state.testID,
        subjectName: getLocation.state.subject,
        basisTrainingName: getLocation.state.basisTraining,

        isVisibleModalCreateEditStructure, // Видимость окна, создание (редактирование) структуры

        actionName, // Действие, создание или редактирование структуры
        selectedAnswerTypeID, setSelectedAnswerTypeID, // Значение, идентификатор выбранного тип отета

        openModalCreateEditStructure, // Открытие (Закрытие) окна, создание (редактирование) структуры
        applyButtonCreateEditStructure, // Кнопка применить (создать) в окне создания (редактирования) структуры

        isVisibleModalPasswordConfirm, // Видимость окна подтверждения для ввода пароля
        openModalPasswordConfirm, // Открытие окна подтверждения для ввода пароля
        valueLoginPassword, setValueLoginPassword, // Пароль оператора, для подтверждения

        changeMaxBal, // Изменение максимального бала
        deleteStructure, // Удаление структуры

        isVisibleDrawerReferenceMaterial, setIsVisibleDrawerReferenceMaterial, // Видимость окна со справочным материалом
        isVisibleDrawerTranslationScale, setIsVisibleDrawerTranslationScale, // Видимость окна со шкалой перевода
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <TestStructure {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        userProfile: state.auth.userProfile,
        testsStructureList: state.tests.testsStructureList,
        answerTypeList: state.dict.answerTypeList,
        statisticStructure: state.tests.statisticStructure,
    }
}

export default compose(connect(mapState, { 
    getTestStructure, clearTestStructure, createEditTestStructure, deleteTestStructure,
    getAnswerType, clearAnswerType, getStatisticStructure, clearStatisticStructure
}))(TestStructureContainer)