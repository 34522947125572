import api from '../../api/apiDict'
import actions from './actions'

// Получение списка уровней доступа
export const getAccessLevels = () => async (dispatch: any) => {
    const responce = await api.getAccessLevels()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.dictSetAccessLevelsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка предметов
export const getSubjects = () => async (dispatch: any) => {
    const responce = await api.getSubjects()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.dictSetSubjectsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка основ обучения
export const getBasisTraining = () => async (dispatch: any) => {
    const responce = await api.getBasisTraining()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.dictSetBasisTrainingListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка тип ответа
export const getAnswerType = () => async (dispatch: any) => {
    const responce = await api.getAnswerType()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.dictSetAnswerTypeListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// очистка списка уровней доступа
export const clearAccessLevels = () => async (dispatch: any) => {
    dispatch(actions.dictSetAccessLevelsListAction(null))
}

// Очистка списка предметов
export const clearSubjects = () => async (dispatch: any) => {
    dispatch(actions.dictSetSubjectsListAction(null))
}

// Очистка списка основ обучения
export const clearBasisTraining = () => async (dispatch: any) => {
    dispatch(actions.dictSetBasisTrainingListAction(null))
}

// Очистка списка тип ответа
export const clearAnswerType = () => async (dispatch: any) => {
    dispatch(actions.dictSetAnswerTypeListAction(null))
}