import axios from 'axios'

export const API_URL = `http://192.168.7.70:4000/testingApplicants/api`
// export const API_URL = `https://test.vavilovsar.ru/api`
// export const API_URL = `http://localhost:5003/api`

const api = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Для того что бы к каждому запросу цеплялись куки автоматически
})

api.interceptors.request.use((config: any) => {
    config.headers['API_KEY'] = `${localStorage.getItem('token')}`

    return config
})

export default api