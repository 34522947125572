import React from 'react'
import { Tooltip } from 'antd'

import Button from '../UIComponents/Button/Button'
import Input from '../UIComponents/Input/Input'
import Modal from '../UIComponents/Modal/Modal'
import { IModalConfirmPassword } from './modal-confirm-password.interface'

const ModalConfirmPassword: React.FC<IModalConfirmPassword> = (props) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState<boolean>(false) // Видимость пароля в текстовом виде
    const [loginPassword, setLoginPassword] = React.useState<string>('') // Пароль оператора, для подтверждения

    return (
        <Modal open={props.isOpen}>
            <section className='modalPrimary' style={{ width: '550px' }}>
                <header className='modalHeader'>
                    <h5>Ваш пароль для подтверждения...</h5>
                </header>

                <main className='modalContent'>
                    <form className='modalContainer' onKeyPress={(e) => {e.key === "Enter" && e.preventDefault()}}>
                        <Input variant='primary' type={isPasswordVisible ? 'text' : 'password'} 
                            placeholder='Пароль пользователя' paddingRight={8} value={loginPassword} 
                            
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setLoginPassword(e.target.value)
                                props.onLoginPasswordChange(e.target.value)
                            }}
                            
                            prefix={
                                <div style={{ width: '24px', height: '24px' }}>
                                    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.5 2.33334L22.1667 4.66667M13.2883 13.545L18.0833 8.75M18.0833 8.75L21.5833 12.25L25.6667 8.16667L22.1667 4.66667M18.0833 8.75L22.1667 4.66667M13.2883 13.545C13.8907 14.1394 14.3696 14.847 14.6974 15.6273C15.0252 16.4075 15.1954 17.2448 15.1982 18.0911C15.201 18.9373 15.0364 19.7758 14.7139 20.5582C14.3914 21.3406 13.9172 22.0514 13.3188 22.6498C12.7204 23.2482 12.0096 23.7224 11.2272 24.0449C10.4448 24.3674 9.60633 24.532 8.76007 24.5292C7.91381 24.5264 7.07647 24.3562 6.29626 24.0284C5.51604 23.7006 4.80838 23.2217 4.214 22.6193C3.04515 21.4091 2.39839 19.7883 2.41301 18.1058C2.42763 16.4234 3.10246 14.814 4.29216 13.6243C5.48187 12.4346 7.09125 11.7598 8.77368 11.7452C10.4561 11.7306 12.077 12.3773 13.2872 13.5462L13.2883 13.545Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            }

                            suffix={
                                <Tooltip placement='bottom' title={`${isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'}`}>
                                    <div>
                                        <Button variant='default' size='xs' onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                            {isPasswordVisible
                                                ? <svg width="24" height="24" viewBox="0 0 32 32" fill="currentColor"><path d="M9.911 23.839l1.393-2.518A7.997 7.997 0 018 14.857c0-1.411.375-2.804 1.089-4.018-2.786 1.429-5.107 3.679-6.804 6.304 1.857 2.875 4.482 5.286 7.625 6.696zm6.946-13.553A.87.87 0 0016 9.429c-2.982 0-5.429 2.446-5.429 5.429 0 .464.393.857.857.857s.857-.393.857-.857a3.723 3.723 0 013.714-3.714.87.87 0 00.857-.857zm6.482-3.411c0 .036 0 .125-.018.161-3.768 6.732-7.5 13.5-11.268 20.232l-.875 1.589a.592.592 0 01-.5.286c-.321 0-2.018-1.036-2.393-1.25a.57.57 0 01-.286-.5c0-.286.607-1.25.786-1.554-3.464-1.571-6.375-4.25-8.429-7.464a2.304 2.304 0 010-2.464c3.536-5.429 9.054-9.054 15.643-9.054 1.071 0 2.161.107 3.214.304l.964-1.732a.57.57 0 01.5-.286c.321 0 2 1.036 2.375 1.25a.562.562 0 01.286.482zM24 14.857a7.99 7.99 0 01-5.143 7.464l5-8.964c.089.5.143 1 .143 1.5zm8 2.286c0 .464-.125.839-.357 1.232-.554.911-1.25 1.786-1.946 2.589-3.5 4.018-8.321 6.464-13.696 6.464l1.321-2.357c5.196-.446 9.607-3.607 12.393-7.929-1.321-2.054-3.018-3.857-5.036-5.25l1.125-2c2.214 1.482 4.446 3.714 5.839 6.018.232.393.357.768.357 1.232z"></path></svg>
                                                : <svg width="24" height="24" viewBox="0 0 32 32" fill="currentColor"><path d="M29.714 17.143c-1.696-2.625-4.018-4.875-6.804-6.304a7.938 7.938 0 011.089 4.018c0 4.411-3.589 8-8 8s-8-3.589-8-8c0-1.411.375-2.804 1.089-4.018-2.786 1.429-5.107 3.679-6.804 6.304 3.054 4.714 7.982 8 13.714 8s10.661-3.286 13.714-8zm-12.857-6.857A.87.87 0 0016 9.429c-2.982 0-5.429 2.446-5.429 5.429 0 .464.393.857.857.857s.857-.393.857-.857c0-2.036 1.679-3.714 3.714-3.714a.87.87 0 00.857-.857zM32 17.143c0 .446-.143.857-.357 1.232-3.286 5.411-9.304 9.054-15.643 9.054S3.643 23.768.357 18.375C.143 18 0 17.589 0 17.143s.143-.857.357-1.232C3.643 10.518 9.661 6.857 16 6.857s12.357 3.661 15.643 9.054c.214.375.357.786.357 1.232z"></path></svg>
                                            }
                                        </Button>
                                    </div>
                                </Tooltip>
                            }
                        />
                    </form>

                    <footer className='modalFooter'>
                        <Button variant='secondary' size='md' onClick={() => { props.closeModalMethod(false); setLoginPassword('') }}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                            </svg>

                            <span>ОТМЕНА</span>
                        </Button>

                        <Button variant='primary' size='md' onClick={() => { props.confirmModalMethod(); setLoginPassword('') }}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                            </svg>

                            <span>ПОДТВЕРЖДАЮ</span>
                        </Button>
                    </footer>
                </main>
            </section>
        </Modal>
    )
}

export default ModalConfirmPassword