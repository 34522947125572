import api from '../../api/apiOperators'
import actions from './actions'

// Получение списка операторов
export const getOperators = () => async (dispatch: any) => {
    const responce = await api.getOperators()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.operatorsSetUsersListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание (Редактирование) оператора
export const createEditOperator = (action: string, loginID: string, loginName: string, loginPassword: string, lastName: string, firstName: string, secondName: string, snils: string, phone: string, docSeries: string, docNumber: string, accessLevelID: number, isBlocked: boolean) => async (dispatch: any) => {
    const responce = await api.createEditOperator(action, loginID, loginName, loginPassword, lastName, firstName, secondName, snils, phone, docSeries, docNumber, accessLevelID, isBlocked)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getOperators())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла с участниками
export const uploadFileParticipants = (file: any, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadFileParticipants(file, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.operatorsSetParticipantsFileListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание участников в автоматизированном режиме из файла
export const createParticipants = (data: any) => async (dispatch: any) => {
    const responce = await api.createParticipants(data)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getOperators())
                dispatch(clearParticipantsFile())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}




// Очистка списка операторов
export const clearOperators = () => async (dispatch: any) => {
    dispatch(actions.operatorsSetUsersListAction(null))
}

// Очистка списка участников из файла
export const clearParticipantsFile = () => async (dispatch: any) => {
    dispatch(actions.operatorsSetParticipantsFileListAction(null))
}