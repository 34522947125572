import React from 'react'
import { HashLoader } from 'react-spinners'

import css from './Loader.module.scss'

type MyType = {
    open: boolean,
    color?: string | undefined
    title?: string | undefined
    backdrop?: boolean
}

// Загрузчик
const Loader: React.FC<MyType> = ({ open, color, title, backdrop = false }) => {
    React.useEffect(() => {
        switch (open) {
            case true: document.body.style.overflow = 'hidden'; break
            case false: document.body.style.overflow = 'unset'; break
        }
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${css.wrapper} ${open ? css.open : css.close}`} style={{ position: `${backdrop ? 'fixed' : 'relative'}` }}>
            <div className={css.content}>
                <HashLoader loading={open} color={color} />
                {title && <span style={{ color: `${color}` }}>{title}</span>}
            </div>
        </div>
    )
}

export default Loader