import React from 'react'
import css from './Toogle.module.scss'

interface TooglePropsType {
    title?: string
    checked?: boolean | undefined
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

const Toogle: React.FC<TooglePropsType> = ({ title = 'Описание', checked, onChange }) => {
    return (
        <section className={css.myPopoverVertical}>
            {<span>{title}</span>}
            <input type="checkbox" 
            checked={checked} 
            onChange={onChange} 
            // onChange={(e) => console.log(e.target.checked)} 
            />
        </section>
    )
}

export default Toogle