import React from 'react'
import Button from '../../UIComponents/Button/Button'
import { Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import css from './participants.module.scss'
import { IParticipantExam } from './participant.interfaces'
import Modal from '../../UIComponents/Modal/Modal'

const Participant: React.FC<IParticipantExam> = (props) => {
    return (
        <>
            <ConfirmationStartExam {...props} />

            <header className='container-header'>
                <Tooltip title="Назад к экзаменам" placement='bottomLeft'>
                    <NavLink to='/main'>
                        <Button variant='secondary' size='sm'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.41421 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.41421L11.7071 17.2929C12.0976 17.6834 12.0976 18.3166 11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z" fill="currentColor" />
                            </svg>
                        </Button>
                    </NavLink>
                </Tooltip>

                <div className='container-header--title'>
                    <h4>{props.examInfo?.subjectName}</h4>
                    <h5>Экзамен от {new Date(props.examInfo?.examDateStart).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</h5>
                </div>
            </header>

            <main className='container-main'>
                <article className={`container-main--body ${css['content']}`}>
                    <h5>Перед прохождением экзамена внимательно прочтите следующую информацию!</h5>
                    
                    <span>✔ Каждое вступительное испытание (экзамен) Вы можете пройти только один раз.</span>
                    <span>✔ Время на прохождение экзамена даётся – {props.examInfo?.testTime} минут.</span>
                    <span>✔ Минимальное количество баллов, необходимое для учёта результатов экзамена - {props.examInfo?.porog}.</span>
                    <span>✔ Отвечая на вопрос, внимательно изучите критерии внесения ответов в зависимости от типа вопроса.</span>
                    <span>✔ Для экзаменов некоторых дисциплин Вам доступен справочный материал.</span>
                    <span>✔ Отвечать на вопросы Вы можете в любом порядке, ответы сохраняются автоматически, в случае необходимости Вы можете вернуться к вопросу и внести изменения в ответ.</span>
                    <span>✔ После внесения ответов на все задания экзамена, внимательно просмотрите Ваши ответы, и нажмите кнопку «Завершить экзамен».</span>
                    <span>✔ После окончания времени, отведённого на выполнение экзамена, он завершится.</span>
                    <span>✔ По окончании экзамена Вам необходимо подойти к экзаменатору и расписаться в результатах вступительного испытания.</span>
                    <br/>
                    <span>После того, как Вы ознакомитесь с информацией о экзамене, Вы можете начать его сдачу, нажав на кнопку ниже и подтвердив свою готовность. После этого экзамен начнется, и начнется обратный отсчет времени.</span>

                    <Button variant='primary' size='lg' width={'100%'} onClick={() => props.setIsVisibleConfirmation(true)}>
                        <span>ПРИСТУПИТЬ К СДАЧЕ ЭКЗАМЕНА</span>
                    </Button>
                </article>
            </main>
        </>
    )
}

// Окно подтверждения начать экзамен
const ConfirmationStartExam: React.FC<IParticipantExam> = (props) => {
    return (
        <Modal open={props.isVisibleConfirmation}>
            <section className='modalPrimary' style={{ width: '550px' }}>
                <header className='modalHeader'>
                    <h5>Подтверждение начать экзамен...</h5>
                </header>

                <main className='modalContent'>
                    <div className='modalContainer'>
                        <span>Пожалуйста, подтвердите свое согласие на начало экзамена, нажав кнопку "ПОДТВЕРЖДАЮ".</span>
                        <span>Желаем Вам удачи!</span>
                    </div>

                    <footer className='modalFooter'>
                        <Button variant='secondary' size='md' onClick={() => props.setIsVisibleConfirmation(false)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                            </svg>

                            <span>ОТМЕНА</span>
                        </Button>

                        <Button variant='primary' size='md' onClick={props.startExam}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                            </svg>

                            <span>ПОДТВЕРЖДАЮ</span>
                        </Button>
                    </footer>
                </main>
            </section>
        </Modal>
    )
}

export default Participant