import { DictActionTypes } from "./interfaces"
import { AccessLevelsListType, AnswerTypeListType, BasisTrainingListType, SubjectsListType } from "./types"


let initialState = { // Инициализационный State
    accessLevelsList: [] as Array<AccessLevelsListType> | null,
    subjectsList: [] as Array<SubjectsListType> | null,
    basisTrainingList: [] as Array<BasisTrainingListType> | null,
    answerTypeList: [] as Array<AnswerTypeListType> | null
}


// Reducer справочники
export const dictReducer = (state = initialState, action: DictActionTypes): typeof initialState => {
    switch (action.type) {
        case 'LOGIN__LOGOUT_APP': { return { ...state = initialState } }

        case 'DICT__SET_ACCESS_LEVELS': {
            return { ...state, accessLevelsList: action.accessLevelsList }
        }

        case 'DICT__SET_SUBJECTS': {
            return { ...state, subjectsList: action.subjectsList }
        }

        case 'DICT__SET_BASIS_TRAINING': {
            return { ...state, basisTrainingList: action.basisTrainingList }
        }

        case 'DICT__SET_ANSWER_TYPE': {
            return { ...state, answerTypeList: action.answerTypeList }
        }
        
        default: return state
    }
}