const errorHandler = (err: any, msg: any, navigate: any) => {
    switch (err) {
        case "ERR_UNEXPECTED": return msg.error.E0001 // Не предвиденная ошибка
        
        case "ERR_NETWORK": // Сервер не доступен
        case "ERR_SERVER_AVAILABLE": { 
            localStorage.removeItem('token')

            navigate('/')
            return msg.error.E0002 
        }

        case "ERR_NOT_AUTHORIZED": { // Пользователь не авторизован
            localStorage.removeItem('token')

            navigate('/')
            return msg.error.E0003
        }

        case "ERR_INACTIVITY_SESSEION": { // Сессия более не активна
            localStorage.removeItem('token')

            navigate('/')
            return msg.error.E0004
        }
        
        case "ERR_QUERY_DUPLICATE": return msg.error.E0005 // Дублирование записей
        
        case "OPERATOR_BLOCKED": { // Оператор заблокирован
            localStorage.removeItem('token')

            navigate('/')
            return msg.error.E0006
        }

        case "ACCESS_DENITED": { // Доступ запрещен
            navigate('/main')
            return msg.error.E0007
        }

        case "EXAM_CLOSED": { // Экзамен закрыт
            navigate('/main')
            return msg.info.I0002
        }

        case "EXAM_VIOLATION": { // Участник удален с экзамена за нарушение
            navigate('/main')
            return msg.info.I0003
        }

        case "EXAM_PARTICIPANT_END": { // Участник завершил экзамен
            navigate('/main')
            return msg.info.I0004
        }

        case "PASSWORD_CONFIRM": { // Ошибка подтверждение пароля оператором при каком либо действии
            return msg.error.PASS_CONFIRM
        }

        case "TEST_BLOCKING": { // Тест закрыт для редактирования
            return msg.error.TEST_BLOCKING
        }
        
        case "NULL": return msg.error.ENULL // Сервер не ответил на запрос

        // default: return "Не предвиденный код ошибки. Обратитесь к Администратору."
    }
}

export default errorHandler