import React from 'react'

import { Tooltip, Image } from 'antd'

import css from './reference-material.module.scss'

import Button from '../../UIComponents/Button/Button'
import Drawer from '../../UIComponents/Drawer/Drawer'
import Loader from '../../UIComponents/Loader/Loader'
import Empty from '../../UIComponents/Empty/Empty'
import imgEmpty from './../../../images/empty_documents.svg'
import Dropzone from 'react-dropzone'
import ModalConfirmPassword from '../../_modal-confirm-password/modal-confirm-password'

const ReferenceMaterial: React.FC<any> = (props) => {
    const [visibleImg, setVisibleImg] = React.useState<boolean>(false) // Открыто или закрыто окно, просмотр всех изображений
    const [visibleSingleImg, setVisibleSingleImg] = React.useState<boolean>(false) // Открыто или закрыто окно, просмотр индивидуальный
    const [imgPath, setImgPath] = React.useState<string>('') // Путь к изображению, для индивидуального предпросмотра изображения

    return (
        <>
            <ModalConfirmPassword
                isOpen={props.isVisibleModalPasswordConfirm}
                closeModalMethod={props.openModalPasswordConfirm}
                confirmModalMethod={props.deleteMaterial}
                onLoginPasswordChange={(value: string) => props.setValueLoginPassword(value)}
            />

            <Drawer size={`750px`} isOpen={props.isOpen} placement='right'>
                <section className='drawerPrimary'>
                    {props.isVisibleLoader
                        ? <Loader open={props.isVisibleLoader} color='#FF6700' title='Загрузка...' />
                        : <main className='drawerContent'>
                            <header className='drawerHeader' style={{ alignItems: 'center' }}>
                                <h4>Справочный материал...</h4>

                                <Button variant='default' size='xs' onClick={() => props.onClose(false)}>
                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                                    </svg>
                                </Button>
                            </header>

                            <section className='drawerContainer'>
                                <Dropzone onDropAccepted={(files) => props.fileUploader(files)} multiple={true}>
                                    {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                                        <section {...getRootProps({ className: isDragAccept ? `drawerItems ${css.dropZone}` : `drawerItems` })}>
                                            <input {...getInputProps()} />
                                            {!props.testsReferenceMaterialList?.length
                                                ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='200px' />
                                                : props.testsReferenceMaterialList.map((item: any) => {
                                                    return (
                                                        <div key={item.referenceMaterialID} className={css.tRow}>
                                                            <span className={`${css.tAlignCenter}`}>{item.fileNum}</span>

                                                            <div className={css.tRowPrimary}>
                                                                <div className={`${css.tName}`}>{item.imgName}</div>

                                                                <aside className={css.tBar}>
                                                                    <Tooltip title="Просмотр" placement='bottomLeft'>
                                                                        <div>
                                                                            <Button variant='secondary' size='sm' onClick={(e) => { setImgPath(item.imgPath); setVisibleSingleImg(true); e.stopPropagation() }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.0008 5.25C7.69339 5.25 4.03651 8.04374 2.74703 11.9202C2.72924 11.9736 2.72926 12.0318 2.74708 12.0852C4.03816 15.9589 7.69364 18.75 11.9991 18.75C16.3065 18.75 19.9634 15.9563 21.2529 12.0798C21.2707 12.0264 21.2706 11.9682 21.2528 11.9148C19.9617 8.04113 16.3063 5.25 12.0008 5.25ZM1.32372 11.4467C2.81097 6.97571 7.02822 3.75 12.0008 3.75C16.9711 3.75 21.1868 6.97271 22.6759 11.4405C22.7962 11.8015 22.7963 12.1922 22.6762 12.5533C21.1889 17.0243 16.9717 20.25 11.9991 20.25C7.02878 20.25 2.81314 17.0273 1.32404 12.5595C1.2037 12.1985 1.20359 11.8078 1.32372 11.4467ZM12 9.75C10.7574 9.75 9.75001 10.7574 9.75001 12C9.75001 13.2426 10.7574 14.25 12 14.25C13.2427 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2427 9.75 12 9.75ZM8.25001 12C8.25001 9.92893 9.92894 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92894 15.75 8.25001 14.0711 8.25001 12Z" fill="currentColor" />
                                                                                </svg>
                                                                            </Button>
                                                                        </div>
                                                                    </Tooltip>

                                                                    <Tooltip title="Удалить" placement='bottomRight'>
                                                                        <div>
                                                                            <Button variant='secondary' size='sm' onClick={(e) => { props.openModalPasswordConfirm(true, item.referenceMaterialID); e.stopPropagation() }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z" fill="currentColor" />
                                                                                </svg>
                                                                            </Button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </aside>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </section>
                                    )}
                                </Dropzone>

                                <section>
                                    Пожалуйста, переместите файлы изображений в область таблицы, либо щелкните по ней левой кнопкой мыши для загрузки файлов.
                                </section>

                                <footer className='drawerFooter'>
                                    <div style={{ display: 'flex', flexGrow: '1' }}>
                                        <Button variant='secondary' size='md' onClick={() => setVisibleImg(true)} width='100%' disabled={!props.testsReferenceMaterialList?.length}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.5 6C1.5 4.75736 2.50736 3.75 3.75 3.75H20.25C21.4926 3.75 22.5 4.75736 22.5 6V18C22.5 19.2426 21.4926 20.25 20.25 20.25H3.75C2.50736 20.25 1.5 19.2426 1.5 18V6ZM3 16.0607V18C3 18.4142 3.33579 18.75 3.75 18.75H20.25C20.6642 18.75 21 18.4142 21 18V16.0607L18.3107 13.3713C17.7249 12.7855 16.7751 12.7855 16.1893 13.3713L15.3107 14.25L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L10.0607 11.1213C9.47487 10.5355 8.52513 10.5355 7.93934 11.1213L3 16.0607ZM14.25 13.1893L11.1213 10.0607C9.94975 8.88909 8.05026 8.88908 6.87868 10.0607L3 13.9393V6C3 5.58579 3.33579 5.25 3.75 5.25H20.25C20.6642 5.25 21 5.58579 21 6V13.9393L19.3713 12.3107C18.1997 11.1391 16.3003 11.1391 15.1287 12.3107L14.25 13.1893ZM13.125 8.25C13.125 7.62868 13.6287 7.125 14.25 7.125C14.8713 7.125 15.375 7.62868 15.375 8.25C15.375 8.87132 14.8713 9.375 14.25 9.375C13.6287 9.375 13.125 8.87132 13.125 8.25Z" fill="currentColor" />
                                            </svg>

                                            <span>ПРОСМОТРЕТЬ СПРАВОЧНЫЙ МАТЕРИАЛ</span>
                                        </Button>
                                    </div>
                                </footer>
                            </section>
                        </main>
                    }
                </section>

                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup preview={{ visible: visibleImg, onVisibleChange: vis => setVisibleImg(vis) }}>
                        {!props.testsReferenceMaterialList ? 'УПС' : props.testsReferenceMaterialList.map((item: any) => {
                            return (
                                <Image key={item.referenceMaterialID} src={item.imgPath} />
                            )
                        })}
                    </Image.PreviewGroup>

                    <Image preview={{ visible: visibleSingleImg, src: imgPath, onVisibleChange: (value) => { setImgPath(''); setVisibleSingleImg(value) } }} />
                </div>
            </Drawer>
        </>
    )
}

export default ReferenceMaterial