import api from '../../api/apiTests'
import actions from './actions'

// Получение списка тестов
export const getTests = () => async (dispatch: any) => {
    const responce = await api.getTests()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetTestsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание (Редактирование) теста
export const createEditTest = (action: string, testID: string, subjectID: number, basisTrainingID: number, testMinutes: number, porog: number) => async (dispatch: any) => {
    const responce = await api.createEditTest(action, testID, subjectID, basisTrainingID, testMinutes, porog)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTests())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Закрытие (Открытие) от редактирования теста
export const lockingTest = (testID: string, action: string, loginPassword: string) => async (dispatch: any) => {
    const responce = await api.lockingTest(testID, action, loginPassword)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTests())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Получение структуры теста
export const getTestStructure = (testID: string) => async (dispatch: any) => {
    const responce = await api.getTestStructure(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetTestsStructureListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение статистической информации по структуре теста
export const getStatisticStructure = (testID: string) => async (dispatch: any) => {
    const responce = await api.getStatisticStructure(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetStatisticStructureAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание (Редактирование) структуры теста
export const createEditTestStructure = (action: string, testID: string, answerTypeID: number, questionNumber: number, maxBal: number) => async (dispatch: any) => {
    const responce = await api.createEditTestStructure(action, testID, answerTypeID, questionNumber, maxBal)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestStructure(testID))
                dispatch(getStatisticStructure(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление вопроса в структуре и все соответствующие вопросы в вариантах
export const deleteTestStructure = (testID: string, questionNumber: number, loginPassword: string) => async (dispatch: any) => {
    const responce = await api.deleteTestStructure(testID, questionNumber, loginPassword)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestStructure(testID))
                dispatch(getStatisticStructure(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение файлов по справочному материалу
export const getReferenceMaterial = (testID: string) => async (dispatch: any) => {
    const responce = await api.getReferenceMaterial(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetReferenceMaterialListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла по справочному материалу
export const uploadReferenceMaterial = (file: any, testID: string, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadReferenceMaterial(file, testID, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getReferenceMaterial(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление файла по справочному материалу
export const deleteReferenceMaterial = (testID: string, referenceMaterialID: string, loginPassword: string) => async (dispatch: any) => {
    const responce = await api.deleteReferenceMaterial(testID, referenceMaterialID, loginPassword)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getReferenceMaterial(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение шкалы перевода из первичного в тестовый балл
export const getTranslationScale = (testID: string) => async (dispatch: any) => {
    const responce = await api.getTranslationScale(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetTranslationScaleListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование шкалы, тестовый балл
export const updateTranslationScale = (testID: string, data: any) => async (dispatch: any) => {
    const responce = await api.updateTranslationScale(testID, data)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
            dispatch(getTranslationScale(testID))
            return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}




// Получение вариантов теста
export const getTestVariants = (testID: string) => async (dispatch: any) => {
    const responce = await api.getTestVariants(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetTestsVariantsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание варианта по тесту
export const createTestVariant = (testID: string) => async (dispatch: any) => {
    const responce = await api.createTestVariant(testID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestVariants(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление варианта и всех вопросов
export const deleteTestVariant = (testID: string, variantID: string, loginPassword: string) => async (dispatch: any) => {
    const responce = await api.deleteTestVariant(testID, variantID, loginPassword)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestVariants(testID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Исправление ошибок по варианту
export const changeVariantErrors = (testID: string, variantID: string) => async (dispatch: any) => {
    const responce = await api.changeVariantErrors(testID, variantID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestVariantQuestions(testID, variantID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Получение вопросов теста по варианту
export const getTestVariantQuestions = (testID: string, variantID: string) => async (dispatch: any) => {
    const responce = await api.getTestVariantQuestions(testID, variantID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetTestsVariantQuestionsListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла по вопросу
export const uploadQuestion = (file: any, testID: string, questionID: string, subjectID: number, basisTrainingID: number, variantID: string, previousFileName: string, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadQuestion(file, testID, questionID, subjectID, basisTrainingID, variantID, previousFileName, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getTestVariantQuestions(testID, variantID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение правильных ответов по вопросу
export const getCorrectAnswersByQuestions = (questionID: string) => async (dispatch: any) => {
    const responce = await api.getCorrectAnswersByQuestions(questionID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.testsSetCorrectAnswersListAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание правильного ответа по вопросу
export const createCorrectAnswersByQuestions = (testID: string, variantID: string, questionID: string) => async (dispatch: any) => {
    const responce = await api.createCorrectAnswersByQuestions(testID, variantID, questionID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCorrectAnswersByQuestions(questionID))
                dispatch(getTestVariantQuestions(testID, variantID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление правильных ответов
export const deleteCorrectAnswer = (testID: string, variantID: string, questionID: string, correctAnswerID: number) => async (dispatch: any) => {
    const responce = await api.deleteCorrectAnswer(testID, correctAnswerID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
            dispatch(getCorrectAnswersByQuestions(questionID))
            dispatch(getTestVariantQuestions(testID, variantID))
            return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование правильных ответов
export const updateCorrectAnswers = (testID: string, variantID: string, questionID: string, data: any) => async (dispatch: any) => {
    const responce = await api.updateCorrectAnswers(testID, variantID, questionID, data)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
            dispatch(getCorrectAnswersByQuestions(questionID))
            dispatch(getTestVariantQuestions(testID, variantID))
            return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}




// Очистка списка тестов
export const clearTests = () => async (dispatch: any) => {
    dispatch(actions.testsSetTestsListAction(null))
}

// Очистка структуры теста
export const clearTestStructure = () => async (dispatch: any) => {
    dispatch(actions.testsSetTestsStructureListAction(null))
}

// Очистка файлов по справочному материалу
export const clearReferenceMaterial = () => async (dispatch: any) => {
    dispatch(actions.testsSetReferenceMaterialListAction(null))
}

// Очистка шкалы перевода из первичного в тестовый балл
export const clearTranslationScale = () => async (dispatch: any) => {
    dispatch(actions.testsSetTranslationScaleListAction(null))
}

// Очистка вариантов теста
export const clearTestVariants = () => async (dispatch: any) => {
    dispatch(actions.testsSetTestsVariantsListAction(null))
}

// Очистка вопросов теста по варианту
export const clearTestVariantQuestions = () => async (dispatch: any) => {
    dispatch(actions.testsSetTestsVariantQuestionsListAction(null))
}

// Очистка правильных ответов по вопросу
export const clearCorrectAnswersByQuestions = () => async (dispatch: any) => {
    dispatch(actions.testsSetCorrectAnswersListAction(null))
}

// Очистка статистической информации по структуре теста
export const clearStatisticStructure = () => async (dispatch: any) => {
    dispatch(actions.testsSetStatisticStructureAction(null))
}