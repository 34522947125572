import React from 'react'
import { NavLink } from 'react-router-dom'

import { Tooltip } from 'antd'

import Button from '../UIComponents/Button/Button'
import Empty from '../UIComponents/Empty/Empty'
import { ITestQuestionsInterface } from './testQuestions.interface'
import css from './testQuestions.module.scss'
import imgEmpty from './../../images/empty_documents.svg'
import Dropzone from 'react-dropzone'
import CorrectAnswersContainer from './correct-answers/correct-answers.container'


const TestQuestions: React.FC<ITestQuestionsInterface> = (props) => {
    return (
        <>
            <CorrectAnswersContainer {...props} />

            <header className={css.header}>
                <Tooltip title="Назад к вариантам" placement='bottomLeft'>
                    <NavLink to='/main/tests/variants' state={{ testID: props.testID, subject: props.subjectName, basisTraining: props.basisTrainingName, countQuestions: props.countQuestions }}>
                        <Button variant='secondary' size='sm'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.41421 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.41421L11.7071 17.2929C12.0976 17.6834 12.0976 18.3166 11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z" fill="currentColor" />
                            </svg>
                        </Button>
                    </NavLink>
                </Tooltip>

                <div className={css.header_title}>
                    <h4>Вопросы по варианту {props.variantNumber}</h4>
                    <h5>{props.subjectName} ({props.basisTrainingName})</h5>
                </div>

                <aside>
                    {/* {props.countQuestions !== props.testsVariantQuestionsList?.length && <Button variant='secondary' size='sm' onClick={props.btnChangeVariantErrors}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 8V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Устранить ошибки</span>
                    </Button>} */}
                </aside>
            </header>

            <div className={css.main}>
                <div className={css.questionsList}>
                    {!props.testsVariantQuestionsList?.length
                        ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='300px' />
                        : props.testsVariantQuestionsList.map((item) => {
                            return (
                                <Dropzone onDropAccepted={(files) => props.fileUploader(files, item.questionID, item.subjectID, item.basisTrainingID, item.variantID, item.questionImgName)} multiple={false} key={item.questionID}>
                                    {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                                        <div {...getRootProps({ className: isDragAccept ? `${css.question} ${css.dropZone}` : `${css.question}` })}>
                                            <input {...getInputProps()} />
                                            {item.questionImgPath
                                                ? <img src={item.questionImgPath} alt={`Вопрос № ${item.questionNumber}`} />
                                                : <div className={css.question_dragInfo}>Перетащите в эту область изображение с вопросом и вариантами ответов<br />Или просто кликните левой кнопкой мыши по данной области, для выбора изображения</div>
                                            }

                                            <Button variant='primary' size='sm' onClick={(e) => { 
                                                props.openCorrectAnswers(true, item.questionID, item.answerRegular, `Вопрос № ${item.questionNumber} - (Макс. балл: ${item.maxBal})`, `${item.answerTypeName} (${item.answerTypeDescription})`); e.stopPropagation() }}>
                                                <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.2761 2.27612C22.7968 1.75542 22.7968 0.911225 22.2761 0.390525C21.7554 -0.130175 20.9112 -0.130175 20.3905 0.390525L8 12.781L2.27614 7.05722C1.75544 6.53652 0.911223 6.53652 0.390524 7.05722C-0.130175 7.57792 -0.130175 8.42213 0.390524 8.94283L7.05719 15.6095C7.57789 16.1302 8.42211 16.1302 8.94281 15.6095L22.2761 2.27612Z" fill="currentColor" />
                                                    <path d="M9.77616 7.89033L8.94283 7.05703C8.42213 6.53633 7.57791 6.53633 7.05721 7.05703C6.53651 7.57773 6.53651 8.42193 7.05721 8.94263L7.89054 9.77593C8.41124 10.2966 9.25546 10.2966 9.77616 9.77593C10.2968 9.25523 10.2968 8.41103 9.77616 7.89033Z" fill="currentColor" />
                                                    <path d="M29.4428 2.27612C29.9635 1.75542 29.9635 0.911225 29.4428 0.390525C28.9221 -0.130175 28.0779 -0.130175 27.5572 0.390525L15.1604 12.7873C14.637 12.3725 13.8742 12.4069 13.3905 12.8905C12.8698 13.4112 12.8698 14.2554 13.3905 14.7761L14.2239 15.6095C14.7446 16.1302 15.5888 16.1302 16.1095 15.6095L29.4428 2.27612Z" fill="currentColor" />
                                                </svg>

                                                <span>{`Ответы (Количество: ${item.countAnswers}) (Незаполненных: ${item.countAnswersIsNull})`}</span>
                                            </Button>

                                            <span className='dividerSecondary'></span>

                                            <div className={css.question_info}>
                                                <span>{`${item.answerTypeName} (${item.answerTypeDescription}) - (Макс. балл: ${item.maxBal})`}</span>
                                                <span>{`Вопрос № ${item.questionNumber}`}</span>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default TestQuestions