import { IAccessLevelsList, IAnswerTypeList, IBasisTrainingList, ISubjectsList } from "./interfaces"
import { AccessLevelsListType, AnswerTypeListType, BasisTrainingListType, SubjectsListType } from "./types"


const dictSetAccessLevelsListAction = (accessLevelsList: Array<AccessLevelsListType> | null): IAccessLevelsList => ({ 
    type: 'DICT__SET_ACCESS_LEVELS', accessLevelsList
})

const dictSetSubjectsListAction = (subjectsList: Array<SubjectsListType> | null): ISubjectsList => ({ 
    type: 'DICT__SET_SUBJECTS', subjectsList
})

const dictSetBasisTrainingListAction = (basisTrainingList: Array<BasisTrainingListType> | null): IBasisTrainingList => ({ 
    type: 'DICT__SET_BASIS_TRAINING', basisTrainingList
})

const dictSetAnswerTypeListAction = (answerTypeList: Array<AnswerTypeListType> | null): IAnswerTypeList => ({ 
    type: 'DICT__SET_ANSWER_TYPE', answerTypeList
})


const dictActions = {
    dictSetAccessLevelsListAction, // Уровни доступа
    dictSetSubjectsListAction, // Предметы
    dictSetBasisTrainingListAction, // Основы обучения
    dictSetAnswerTypeListAction, // Тип ответа
}

export default dictActions