import React from 'react'
import css from './Empty.module.scss'

interface EmptyType {
    image: any
    title?: string | undefined
    size?: string | number | undefined
    color?: string | undefined
    fontSize?: string | undefined
}

const Empty: React.FC<EmptyType> = ({ image, title, size = "200px", color = '#000', fontSize = '16px' }) => {
    return (
        <div className={css.wrapper}>
            <div className={css.container}>
                <img src={`${image}`} alt="Данные отсутствуют" style={{ height: `${size}` }} />
                <div className={css.title} style={{ color: `${color}`, fontSize: `${fontSize}` }}>{title}</div>
            </div>
        </div>
    )
}

export default Empty