import React from 'react'
import { NavLink } from 'react-router-dom'

import { Tooltip } from 'antd'

import css from './testStruecture.module.scss'
import Button from '../UIComponents/Button/Button'
import { TestStructureInterface } from './testStructure.interface'
import Empty from '../UIComponents/Empty/Empty'
import imgEmpty from './../../images/empty_documents.svg'
import Modal from '../UIComponents/Modal/Modal'
import ModalConfirmPassword from '../_modal-confirm-password/modal-confirm-password'
import ReferenceMaterialContainer from './reference-material/reference-material.container'
import TranslationScaleContainer from './translation-scale/translation-scale.container'

const TestStructure: React.FC<TestStructureInterface> = (props) => {
    return (
        <>
            <CreateEditTestStructure {...props} />
            
            <ReferenceMaterialContainer 
                testID={props.testID}
                isOpen={props.isVisibleDrawerReferenceMaterial} 
                onClose={(value: boolean) => props.setIsVisibleDrawerReferenceMaterial(value)}
            />

            <TranslationScaleContainer 
                testID={props.testID}
                isOpen={props.isVisibleDrawerTranslationScale}
                onClose={(value: boolean) => props.setIsVisibleDrawerTranslationScale(value)}
            />

            <ModalConfirmPassword 
                isOpen={props.isVisibleModalPasswordConfirm} 
                closeModalMethod={props.openModalPasswordConfirm} 
                confirmModalMethod={props.deleteStructure}
                onLoginPasswordChange={(value: string) => props.setValueLoginPassword(value)}
            />


            <header className={css.header}>
                <Tooltip title="Назад к тестам" placement='bottomLeft'>
                    <NavLink to='/main/tests'>
                        <Button variant='secondary' size='sm'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.41421 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.41421L11.7071 17.2929C12.0976 17.6834 12.0976 18.3166 11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z" fill="currentColor" />
                            </svg>
                        </Button>
                    </NavLink>
                </Tooltip>

                <div className={css.header_title}>
                    <h4>Структура теста</h4>
                    <h5>{props.subjectName} ({props.basisTrainingName})</h5>
                </div>

                <aside className={css['header-panel']}>
                    {props.userProfile.accessLevelID && props.userProfile.accessLevelID in { 1: 1, 2: 2 } &&
                        <>
                            <Tooltip title="Шкала перевода" placement='bottomRight'>
                                <div>
                                    <Button variant='secondary' size='sm' onClick={() => props.setIsVisibleDrawerTranslationScale(true)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 3C1.5 2.58579 1.83579 2.25 2.25 2.25H21.75C22.1642 2.25 22.5 2.58579 22.5 3C22.5 3.41421 22.1642 3.75 21.75 3.75H21V14.25C21 15.9069 19.6569 17.25 18 17.25H16.7906L17.9615 20.7628C18.0925 21.1558 17.8801 21.5805 17.4872 21.7115C17.0942 21.8425 16.6695 21.6301 16.5385 21.2372L16.2094 20.25H7.79057L7.46151 21.2372C7.33053 21.6301 6.90579 21.8425 6.51283 21.7115C6.11987 21.5805 5.9075 21.1558 6.03849 20.7628L7.20943 17.25H6C4.34315 17.25 3 15.9069 3 14.25V3.75H2.25C1.83579 3.75 1.5 3.41421 1.5 3ZM4.5 3.75V14.25C4.5 15.0784 5.17157 15.75 6 15.75H18C18.8284 15.75 19.5 15.0784 19.5 14.25V3.75H4.5ZM8.79057 17.25L8.29057 18.75H15.7094L15.2094 17.25H8.79057ZM15 6C15.4142 6 15.75 6.33579 15.75 6.75V12.75C15.75 13.1642 15.4142 13.5 15 13.5C14.5858 13.5 14.25 13.1642 14.25 12.75V6.75C14.25 6.33579 14.5858 6 15 6ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V12.75C12.75 13.1642 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75V9C11.25 8.58579 11.5858 8.25 12 8.25ZM9 10.5C9.41421 10.5 9.75 10.8358 9.75 11.25V12.75C9.75 13.1642 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.1642 8.25 12.75V11.25C8.25 10.8358 8.58579 10.5 9 10.5Z" fill="currentColor" />
                                        </svg>
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip title="Справочный материал" placement='bottomLeft'>
                                <div>
                                    <Button variant='secondary' size='sm' onClick={() => props.setIsVisibleDrawerReferenceMaterial(true)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.625 3C5.41789 3 5.25 3.16789 5.25 3.375V20.625C5.25 20.8321 5.41789 21 5.625 21H18.375C18.5821 21 18.75 20.8321 18.75 20.625V11.625C18.75 10.1753 17.5747 9 16.125 9H14.625C13.5895 9 12.75 8.16053 12.75 7.125V5.625C12.75 4.17525 11.5747 3 10.125 3H5.625ZM5.625 1.5C4.58947 1.5 3.75 2.33947 3.75 3.375V20.625C3.75 21.6605 4.58947 22.5 5.625 22.5H18.375C19.4105 22.5 20.25 21.6605 20.25 20.625V11.25C20.25 5.86522 15.8848 1.5 10.5 1.5H5.625ZM13.757 3.66785C14.0715 4.25019 14.25 4.91675 14.25 5.625V7.125C14.25 7.33211 14.4179 7.5 14.625 7.5H16.125C16.8332 7.5 17.4998 7.6785 18.0822 7.99296C17.2488 6.05549 15.6945 4.50123 13.757 3.66785ZM7.5 15C7.5 14.5858 7.83579 14.25 8.25 14.25H15.75C16.1642 14.25 16.5 14.5858 16.5 15C16.5 15.4142 16.1642 15.75 15.75 15.75H8.25C7.83579 15.75 7.5 15.4142 7.5 15ZM7.5 18C7.5 17.5858 7.83579 17.25 8.25 17.25H12C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75H8.25C7.83579 18.75 7.5 18.4142 7.5 18Z" fill="currentColor" />
                                        </svg>
                                    </Button>
                                </div>
                            </Tooltip>

                            <div className='dividerPrimary' style={{ margin: '10px 0' }}></div>

                            <Button variant='secondary' size='sm' onClick={() => props.openModalCreateEditStructure(true, 'create')}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 8V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span>Создать</span>
                            </Button>
                        </>
                    }
                </aside>
            </header>

            <div className={css.main}>
                <header className={css.tHeader}>
                    <span className={`${css.tName}`}>Тип ответа</span>
                    <span className={`${css.tName} ${css.tAlignCenter}`}>Макс. балл</span>
                    <span className={`${css.tName}`}></span>
                </header>

                <div className={css.tBody}>
                    {!props.testsStructureList?.length
                        ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='300px' />
                        : props.testsStructureList?.map((item) => {
                            return (
                                <div key={item.structureID} className={css.tRow}>
                                    <span className={`${css.tAlignCenter}`}>{item.questionNumber}</span>

                                    <div className={css.tRowPrimary}>
                                        <div className={`${css.tName}`}>
                                            <span>{`${item.answerTypeName}`}</span>
                                            <span className={css.tName_secondary}>{item.answerTypeDescription}</span>
                                        </div>

                                        <div className={`${css.tCheck}`}>
                                            <Tooltip title={item.maxBal === 1 ? 'Текущий' : 'Установить'} placement='left'>
                                                <div>
                                                    <Button variant={item.maxBal === 1 ? 'primary' : 'default'} size='sm' width='42px'
                                                        onClick={() => { item.maxBal !== 1 && props.changeMaxBal(item.questionNumber, 1) }}
                                                    >1</Button>
                                                </div>
                                            </Tooltip>

                                            <Tooltip title={item.maxBal === 2 ? 'Текущий' : 'Установить'} placement='bottomRight'>
                                                <div>
                                                    <Button variant={item.maxBal === 2 ? 'primary' : 'default'} size='sm' width='42px'
                                                        onClick={() => { item.maxBal !== 2 && props.changeMaxBal(item.questionNumber, 2) }}
                                                    >2</Button>
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <aside className={css.tBar}>
                                            {props.testsStructureList?.length === item.questionNumber // Кнопка удалить (ТОЛЬКО ПОСЛЕДНЯЯ СТРОКА)
                                                && <Tooltip title='Удалить' placement='bottom'>
                                                    <div>
                                                        <Button variant='secondary' size='sm' onClick={() => props.openModalPasswordConfirm(true, item.questionNumber)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z" fill="currentColor" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {props.testsStructureList?.length === item.questionNumber // Кнопка изменить (ТОЛЬКО ПОСЛЕДНЯЯ СТРОКА)
                                                && <Tooltip title='Изменить' placement='bottom'>
                                                    <div>
                                                        <Button variant='secondary' size='sm' width={`${props.testsStructureList?.length === item.questionNumber ? '88px' : undefined}`}
                                                            onClick={() => props.openModalCreateEditStructure(true, 'edit', item.answerTypeID, item.questionNumber)}>

                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M20.6705 3.3295C20.2312 2.89017 19.5188 2.89017 19.0795 3.3295L17.9223 4.48667L19.5133 6.07766L20.6705 4.9205C21.1098 4.48116 21.1098 3.76884 20.6705 3.3295ZM18.4527 7.13832L16.8617 5.54733L8.46085 13.9482C8.02029 14.3887 7.69644 14.9321 7.51857 15.5292L7.11458 16.8854L8.47078 16.4814C9.0679 16.3036 9.61129 15.9797 10.0519 15.5391L18.4527 7.13832ZM18.0188 2.26884C19.044 1.24372 20.706 1.24372 21.7312 2.26884C22.7563 3.29397 22.7563 4.95603 21.7312 5.98116L11.1125 16.5998C10.4957 17.2166 9.73498 17.67 8.899 17.919L6.21411 18.7188C5.95019 18.7974 5.6644 18.7251 5.46967 18.5303C5.27494 18.3356 5.20259 18.0498 5.28121 17.7859L6.08099 15.101C6.33001 14.265 6.7834 13.5043 7.40019 12.8875L18.0188 2.26884ZM5.25 6.74999C4.42157 6.74999 3.75 7.42156 3.75 8.24999V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H15.75C16.5784 20.25 17.25 19.5784 17.25 18.75V14C17.25 13.5858 17.5858 13.25 18 13.25C18.4142 13.25 18.75 13.5858 18.75 14V18.75C18.75 20.4068 17.4069 21.75 15.75 21.75H5.25C3.59315 21.75 2.25 20.4068 2.25 18.75V8.24999C2.25 6.59314 3.59315 5.24999 5.25 5.24999H10C10.4142 5.24999 10.75 5.58578 10.75 5.99999C10.75 6.4142 10.4142 6.74999 10 6.74999H5.25Z" fill="currentColor" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {props.testsStructureList?.length !== item.questionNumber // Кнопка изменить (ВСЕ СТРОКИ, КРОМЕ ПОСЛЕДНЕЙ)
                                                && <Button variant='secondary' size='sm' onClick={() => props.openModalCreateEditStructure(true, 'edit', item.answerTypeID, item.questionNumber)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.6705 3.3295C20.2312 2.89017 19.5188 2.89017 19.0795 3.3295L17.9223 4.48667L19.5133 6.07766L20.6705 4.9205C21.1098 4.48116 21.1098 3.76884 20.6705 3.3295ZM18.4527 7.13832L16.8617 5.54733L8.46085 13.9482C8.02029 14.3887 7.69644 14.9321 7.51857 15.5292L7.11458 16.8854L8.47078 16.4814C9.0679 16.3036 9.61129 15.9797 10.0519 15.5391L18.4527 7.13832ZM18.0188 2.26884C19.044 1.24372 20.706 1.24372 21.7312 2.26884C22.7563 3.29397 22.7563 4.95603 21.7312 5.98116L11.1125 16.5998C10.4957 17.2166 9.73498 17.67 8.899 17.919L6.21411 18.7188C5.95019 18.7974 5.6644 18.7251 5.46967 18.5303C5.27494 18.3356 5.20259 18.0498 5.28121 17.7859L6.08099 15.101C6.33001 14.265 6.7834 13.5043 7.40019 12.8875L18.0188 2.26884ZM5.25 6.74999C4.42157 6.74999 3.75 7.42156 3.75 8.24999V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H15.75C16.5784 20.25 17.25 19.5784 17.25 18.75V14C17.25 13.5858 17.5858 13.25 18 13.25C18.4142 13.25 18.75 13.5858 18.75 14V18.75C18.75 20.4068 17.4069 21.75 15.75 21.75H5.25C3.59315 21.75 2.25 20.4068 2.25 18.75V8.24999C2.25 6.59314 3.59315 5.24999 5.25 5.24999H10C10.4142 5.24999 10.75 5.58578 10.75 5.99999C10.75 6.4142 10.4142 6.74999 10 6.74999H5.25Z" fill="currentColor" />
                                                    </svg>

                                                    <span>Изменить</span>
                                                </Button>
                                            }
                                        </aside>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <footer className={css['stat-info']}>
                    <span>{`Количество вопросов: ${props.testsStructureList?.length}`}</span>
                    <span>{`Время в минутах: ${props.statisticStructure?.testTime}`}</span>
                    <span>{`Порог прохождения: ${props.statisticStructure?.porog}`}</span>
                    <span>{`Сумма макс. баллов: ${props.statisticStructure?.sumMaxBal || 0}`}</span>
                </footer>
            </div>
        </>
    )
}

// Создание (Редактирование) структуры
const CreateEditTestStructure: React.FC<TestStructureInterface> = (props) => {
    return (
        <Modal open={props.isVisibleModalCreateEditStructure}>
            <section className='modalPrimary' style={{ width: '800px' }}>
                <header className='modalHeader'>
                    <h5>
                        {props.actionName === 'create' ? 'Создание структуры' : props.actionName === 'edit' ? 'Редактирование структуры' : ''}
                    </h5>
                </header>

                <main className='modalContent'>
                    <div className='modalContainer'>
                        <div className={css.modalTBody}>
                        {!props.answerTypeList?.length
                            ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='200px' />
                            : props.answerTypeList.map((item) => {
                                return (
                                    <Tooltip key={item.answerTypeID} title="Кликните для выбора" placement='topRight'>
                                        <div className={css.modalTRow} onClick={() => props.setSelectedAnswerTypeID(item.answerTypeID)} style={{ background: `${props.selectedAnswerTypeID === item.answerTypeID ? '#32D732' : '#FF6700'}` }}>
                                            <span className={`${css.tAlignCenter}`}>
                                                {props.selectedAnswerTypeID === item.answerTypeID ? '✔' : item.answerTypeID}
                                            </span>

                                            <div className={css.modalTRowPrimary}>
                                                <div className={`${css.tName}`}>
                                                    <span>{`${item.answerTypeName}`}</span>
                                                    <span className={css.tName_secondary}>{item.answerTypeDescription}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                )
                            })
                        }
                        </div>
                    </div>

                    <footer className='modalFooter'>
                        <Button variant='secondary' size='md' onClick={() => props.openModalCreateEditStructure(false)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                            </svg>

                            <span>ОТМЕНА</span>
                        </Button>

                        <Button variant='primary' size='md' onClick={props.applyButtonCreateEditStructure}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                            </svg>

                            <span>{props.actionName === 'create' ? 'СОЗДАТЬ' : props.actionName === 'edit' ? 'ПРИМЕНИТЬ' : ''}</span>
                        </Button>
                    </footer>
                </main>
            </section>
        </Modal>
    )
}

export default TestStructure