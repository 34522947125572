import axios from 'axios'

import { API_URL } from '../../api/api'
import api from '../../api/apiAuth'
import actions from './actions'

// Авторизация оператора
export const auth = (login: string, password: string) => async (dispatch: any) => {
    const responce = await api.auth(login, password)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                localStorage.setItem('token', responce.data.apiToken)
                dispatch(actions.loginIsAuthorizedAction(true))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Проверка авторизации оператора
export const checkAuth = () => async (dispatch: any) => {
    const responce = await axios.get<any>(`${API_URL}/auth/checkAuth`, { headers: { "API_KEY": localStorage['token'] }, withCredentials: true })

    switch (responce.data.statusCode) {
        case 'OK': // Все ОК
            dispatch(actions.loginIsAuthorizedAction(true))
            return { code: 'OK' }

        default: 
            localStorage.removeItem('token')
            return { code: responce.data.statusCode }
    }
}

// Выход оператора из приложения
export const logout = () => async (dispatch: any) => {
    const responce = await api.logout()
    
    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                localStorage.removeItem('token')
                dispatch(actions.loginLogoutAction())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение данных профиля оператора
export const getMyProfile = () => async (dispatch: any) => {
    const responce = await api.getMyProfile()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.loginIsAppInitializedAction(true))
                dispatch(actions.loginSetUserProfileAction(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание (Редактирование) оператора
export const passwordConfirm = (pass: string) => async (dispatch: any) => {
    const responce = await api.passwordConfirm(pass)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Обнуление хранилища
export const clearStore = () => async (dispatch: any) => {
    localStorage.removeItem('token')
    dispatch(actions.loginLogoutAction())
}
