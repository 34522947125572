import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ExamStart from './exam-start'
import { AppStateType } from '../../../../bll/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationContext } from '../../../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../../../bll/errorHandler'
import Loader from '../../../UIComponents/Loader/Loader'
import { clearExamInfo, clearQuestionsAndAnswers, clearReferenceMaterial, endExamByParticipant, getExamInfo, getQuestionsAndAnswers, getReferenceMaterial, updateAnswerParticipant } from '../../../../bll/exams/exams.operations'
import { QuestionsAndAnswersTypes } from '../../../../bll/exams/exams.types'

const ExamStartContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)
    
    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleEndExam, setIsVisibleEndExam] = React.useState<boolean>(false) // Видимость окна подтверждения завершения экзамена участником

    const [timerSendCorrectAnswerID, setSimerSendCorrectAnswerID] = React.useState<number | null>(null) // Идентификатор таймера отправки данных на сервер
    const [sendCorrectAnswerID, setSendCorrectAnswerID] = React.useState<string | number | null>(null) // Идентификатор редактируемого ответа
    const [localCorrectAnswers, setLocalCorrectAnswers] = React.useState<QuestionsAndAnswersTypes[] | null>(null) // Локальный state правильных ответов по текущему вопросу

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ 
            props.getExamInfo(getLocation.state?.examID), 
            props.getReferenceMaterial(getLocation.state?.examID),
            props.getQuestionsAndAnswers(getLocation.state?.examID),
        ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING' && item.code !== 'EXAM_STARTED' && item.code !== 'EXAM_PARTICIPANT_END') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'EXAM_PARTICIPANT_END') navigate('/main/exam/end', { state: { examID: getLocation.state.examID } })
            
            if (result[0]?.code === 'ERR_QUERY_STRING' || result[1]?.code === 'ERR_QUERY_STRING' || result[2]?.code === 'ERR_QUERY_STRING') {
                notifiMsg(props.msg.error.E0032, "ERROR")
            }
            
            if (result[0]?.code === 'OK' || result[0]?.code === 'EXAM_STARTED') setIsVisibleLoader(false)
        })

        return () => {
            props.clearExamInfo()
            props.clearReferenceMaterial()
            props.clearQuestionsAndAnswers()

            setLocalCorrectAnswers(null)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => { // Автоматическое обновление информации по экзамену каждые 10 секунд
        const interval = setInterval(() => {
            Promise.all([props.getExamInfo(getLocation.state?.examID)]).then((result) => {
                let isGlobalError = false

                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING' && item.code !== 'EXAM_STARTED' && item.code !== 'EXAM_PARTICIPANT_END') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })

                if (result[0]?.code === 'EXAM_PARTICIPANT_END') navigate('/main/exam/end', { state: { examID: getLocation.state.examID } })
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0032, "ERROR")
                // if (result[0]?.code === 'OK' || result[0]?.code === 'EXAM_STARTED') setIsVisibleLoader(false)
            })
        }, 10000)

        return () => {
            clearInterval(interval)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => { // Записываем вопросы и ответы в локальное хранилище
        if (props.questionsAndAnswers) {
            setLocalCorrectAnswers(props.questionsAndAnswers)
        }
    }, [props.questionsAndAnswers])

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // // Сравнить localCorrectAnswers с correctAnswers и вернуть true, если они равны
    // const areCorrectAnswersEqual = React.useMemo(() => {
    //     return JSON.stringify(localCorrectAnswers) === JSON.stringify(props.questionsAndAnswers)
    // }, [localCorrectAnswers, props.questionsAndAnswers])
    
    // Проверка по регулярке, вводимость правильного ответа по вопросу
    const regexCorrectAnswer = (regexString: string, answerID: string | number, value: string) => {
        setSendCorrectAnswerID(answerID)

        const re: RegExp = new RegExp(regexString.replace(/^\/|\/$/g, ''), 'g')
        if (value === '' || re.test(value)) {
            if (Array.isArray(localCorrectAnswers)) {
                const updatedAnswers = localCorrectAnswers.map((answer) => {
                    if (answer.examParticipantAnswerID === answerID) {
                        return { ...answer, answer: value }
                    }
                    return answer
                })

                setLocalCorrectAnswers(updatedAnswers)

                // Если таймер уже запущен, то удаляем его
                if (timerSendCorrectAnswerID) clearTimeout(timerSendCorrectAnswerID)

                // Устанавливаем таймер на отправку данных через 2 секунды
                const timerID = window.setTimeout(() => {
                    sendDataToServer(updatedAnswers)
                    setSimerSendCorrectAnswerID(null)
                }, 2000)

                setSimerSendCorrectAnswerID(timerID)
            }
        }
    }

    // Отправляем данные на сервер
    const sendDataToServer = (data: any) => {
        props.updateAnswerParticipant(getLocation.state?.examID, data).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                case "FIELD_DATA_ERROR": return notifiMsg(props.msg.error.E0015, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })

        if (timerSendCorrectAnswerID) clearTimeout(timerSendCorrectAnswerID)
        if (sendCorrectAnswerID) setSendCorrectAnswerID(null)
    }

    // Кнопка завершить экзамен по требованию участника
    const applyEndExam = () => {
        props.endExamByParticipant(getLocation.state?.examID).then((result: any) => {
            switch (result?.code) {
                case 'OK': setIsVisibleEndExam(false); navigate('/main/exam/end', { state: { examID: getLocation.state.examID } }); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    
    // Свойства передаваемые в компоненту
    const propsToComponent = {
        regexCorrectAnswer, // Проверка по регулярке, вводимость правильного ответа по вопросу
        localCorrectAnswers, // Локальный state правильных ответов по вопросу
        sendCorrectAnswerID, // Идентификатор редактируемого ответа
        timerSendCorrectAnswerID, // Идентификатор таймера отправки данных на сервер

        isVisibleEndExam, setIsVisibleEndExam, // Видимость окна подтверждения завершения экзамена участником
        applyEndExam, // Кнопка завершить экзамен по требованию участника
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <ExamStart {...props} {...propsToComponent} />
            }
        </>
    )
}

const mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        examInfo: state.exams.examInfo,
        referenceMaterial: state.exams.referenceMaterial,
        questionsAndAnswers: state.exams.questionsAndAnswers,
    }
}

export default compose(connect(mapState, {
    getExamInfo, clearExamInfo, getReferenceMaterial, clearReferenceMaterial, 
    getQuestionsAndAnswers, clearQuestionsAndAnswers, updateAnswerParticipant, endExamByParticipant
}))(ExamStartContainer)