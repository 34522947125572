import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppStateType } from '../../../bll/store'
import { ReportExamType } from './reportExam.type'
import ReportExam from './reportExam'
import { createReportExam, getReportFileList } from '../../../bll/exams/exams.operations'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../../bll/errorHandler'

const ReportExamContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных

    React.useEffect(() => {
        if (props.isOpen) {
            setIsVisibleLoader(true)

            Promise.all([ props.getReportFileList(props.examID) ]).then((result) => {
                let isGlobalError = false
    
                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })
    
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0030, "ERROR")
                // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0031, "ERROR")
                // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")
    
                setIsVisibleLoader(false)
            })
        }

        return () => {
            // props.clearExams()
            // props.clearReadyTests()
        }
    }, [props.isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }



    // Кнопка создать новые отчеты
    const createReport = () => {
        props.createReportExam(props.examID).then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "RESULT_NOT_FOUND": return notifiMsg('Результаты по экзамену не найдены!', "WARNING")
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent: ReportExamType = {
        isVisibleLoader,
        examReportList: props.examReportList,

        isOpen: props.isOpen,
        onClose: props.onClose,

        examName: props.examName,

        createReport
    }

    return <ReportExam {...propsToComponent} />
}

const mapState = (state: AppStateType) => {
    return {
        examReportList: state.exams.examReportList
    }
}

export default compose(connect(mapState, { createReportExam, getReportFileList }))(ReportExamContainer)