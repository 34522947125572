import api from './api'

export default class ApiAuth {

    // Авторизация
    static async auth(login: string, password: string): Promise<any> {
        try {
            return await api.post('/auth/login', { login, password })
        } catch (error) {
            return error
        }
    }

    // Выход оператора из приложения
    static async logout(): Promise<any> {
        try {
            return await api.post('/auth/logout')
        } catch (error) {
            return error
        }
    }

    // Получение данных профиля оператора
    static async getMyProfile(): Promise<any> {
        try {
            return await api.get('/auth/getMyProfile')
        } catch (error) {
            return error
        }
    }

    // Проверка пароля текущего пользователя
    static async passwordConfirm(pass: string): Promise<any> {
        try {
            return await api.get(`/auth/passwordConfirm?loginPassword=${pass}`)
        } catch (error) {
            return error
        }
    }

}