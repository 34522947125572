import api from './api'

export default class ApiDict { // Справочники

    // Уровни доступа
    static async getAccessLevels(): Promise<any> {
        try {
            return await api.get('/dict/getAccessLevels')
        } catch (error) {
            return error
        }
    }
    
    // Предметы
    static async getSubjects(): Promise<any> {
        try {
            return await api.get('/dict/getSubjects')
        } catch (error) {
            return error
        }
    }

    // Основы обучения
    static async getBasisTraining(): Promise<any> {
        try {
            return await api.get('/dict/getBasisTraining')
        } catch (error) {
            return error
        }
    }

    // Тип ответа
    static async getAnswerType(): Promise<any> {
        try {
            return await api.get('/dict/getAnswerType')
        } catch (error) {
            return error
        }
    }

}