import React from 'react'

import { Tooltip } from 'antd'

import Button from '../UIComponents/Button/Button'
import Input from '../UIComponents/Input/Input'

import { LoginInterface } from './login.interface'
import logo from './../../images/logo.png'
import css from './login.module.scss'

const Login: React.FC<LoginInterface> = (props) => {
    return (
        <section className={css.main}>
            <header className={css.header}>
                <img src={logo} alt="ФГБОУ ВО Вавиловский университет Платформа МегаТестер" />
                <span>Платформа МегаТестер<br />ФГБОУ ВО Вавиловский университет</span>
            </header>

            <form className={css.form} onKeyPress={props.onKeyPressInput}>
                <Input variant='primary' type='text' placeholder='Логин пользователя'
                    value={props.valueLogin} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setValueLogin(e.target.value)}
                    prefix={
                        <div style={{ width: '24px', height: '24px' }}>
                            <svg width="24" height="24" viewBox="0 0 28 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.8281 22.05C24.2386 20.6537 23.3832 19.3853 22.3094 18.3156C21.2389 17.2428 19.9707 16.3875 18.575 15.7969C18.5625 15.7906 18.55 15.7875 18.5375 15.7813C20.4844 14.375 21.75 12.0844 21.75 9.5C21.75 5.21875 18.2813 1.75 14 1.75C9.71875 1.75 6.25 5.21875 6.25 9.5C6.25 12.0844 7.51563 14.375 9.4625 15.7844C9.45 15.7906 9.4375 15.7938 9.425 15.8C8.025 16.3906 6.76875 17.2375 5.69063 18.3188C4.61784 19.3893 3.76252 20.6574 3.17188 22.0531C2.59163 23.4195 2.2787 24.8845 2.25 26.3688C2.24917 26.4021 2.25502 26.4353 2.26721 26.4663C2.2794 26.4974 2.29768 26.5257 2.32098 26.5496C2.34428 26.5735 2.37212 26.5924 2.40286 26.6054C2.43361 26.6183 2.46664 26.625 2.5 26.625H4.375C4.5125 26.625 4.62188 26.5156 4.625 26.3813C4.6875 23.9688 5.65625 21.7094 7.36875 19.9969C9.14063 18.225 11.4938 17.25 14 17.25C16.5063 17.25 18.8594 18.225 20.6313 19.9969C22.3438 21.7094 23.3125 23.9688 23.375 26.3813C23.3781 26.5188 23.4875 26.625 23.625 26.625H25.5C25.5334 26.625 25.5664 26.6183 25.5971 26.6054C25.6279 26.5924 25.6557 26.5735 25.679 26.5496C25.7023 26.5257 25.7206 26.4974 25.7328 26.4663C25.745 26.4353 25.7508 26.4021 25.75 26.3688C25.7188 24.875 25.4094 23.4219 24.8281 22.05V22.05ZM14 14.875C12.5656 14.875 11.2156 14.3156 10.2 13.3C9.18438 12.2844 8.625 10.9344 8.625 9.5C8.625 8.06563 9.18438 6.71563 10.2 5.7C11.2156 4.68438 12.5656 4.125 14 4.125C15.4344 4.125 16.7844 4.68438 17.8 5.7C18.8156 6.71563 19.375 8.06563 19.375 9.5C19.375 10.9344 18.8156 12.2844 17.8 13.3C16.7844 14.3156 15.4344 14.875 14 14.875Z" />
                            </svg>
                        </div>
                    } 
                />

                <Input variant='primary' type={props.isPasswordVisible ? 'text' : 'password'} placeholder='Пароль пользователя' paddingRight={8}
                    value={props.valuePassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setValuePasword(e.target.value)}
                    prefix={
                        <div style={{ width: '24px', height: '24px' }}>
                            <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.5 2.33334L22.1667 4.66667M13.2883 13.545L18.0833 8.75M18.0833 8.75L21.5833 12.25L25.6667 8.16667L22.1667 4.66667M18.0833 8.75L22.1667 4.66667M13.2883 13.545C13.8907 14.1394 14.3696 14.847 14.6974 15.6273C15.0252 16.4075 15.1954 17.2448 15.1982 18.0911C15.201 18.9373 15.0364 19.7758 14.7139 20.5582C14.3914 21.3406 13.9172 22.0514 13.3188 22.6498C12.7204 23.2482 12.0096 23.7224 11.2272 24.0449C10.4448 24.3674 9.60633 24.532 8.76007 24.5292C7.91381 24.5264 7.07647 24.3562 6.29626 24.0284C5.51604 23.7006 4.80838 23.2217 4.214 22.6193C3.04515 21.4091 2.39839 19.7883 2.41301 18.1058C2.42763 16.4234 3.10246 14.814 4.29216 13.6243C5.48187 12.4346 7.09125 11.7598 8.77368 11.7452C10.4561 11.7306 12.077 12.3773 13.2872 13.5462L13.2883 13.545Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    }

                    suffix={
                        <Tooltip placement='bottom' title={`${props.isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'}`}>
                            <div>
                                <Button variant='default' size='xs' onClick={() => props.setIsPasswordVisible(!props.isPasswordVisible)}>
                                    {props.isPasswordVisible
                                        ? <svg width="24" height="24" viewBox="0 0 32 32" fill="currentColor"><path d="M9.911 23.839l1.393-2.518A7.997 7.997 0 018 14.857c0-1.411.375-2.804 1.089-4.018-2.786 1.429-5.107 3.679-6.804 6.304 1.857 2.875 4.482 5.286 7.625 6.696zm6.946-13.553A.87.87 0 0016 9.429c-2.982 0-5.429 2.446-5.429 5.429 0 .464.393.857.857.857s.857-.393.857-.857a3.723 3.723 0 013.714-3.714.87.87 0 00.857-.857zm6.482-3.411c0 .036 0 .125-.018.161-3.768 6.732-7.5 13.5-11.268 20.232l-.875 1.589a.592.592 0 01-.5.286c-.321 0-2.018-1.036-2.393-1.25a.57.57 0 01-.286-.5c0-.286.607-1.25.786-1.554-3.464-1.571-6.375-4.25-8.429-7.464a2.304 2.304 0 010-2.464c3.536-5.429 9.054-9.054 15.643-9.054 1.071 0 2.161.107 3.214.304l.964-1.732a.57.57 0 01.5-.286c.321 0 2 1.036 2.375 1.25a.562.562 0 01.286.482zM24 14.857a7.99 7.99 0 01-5.143 7.464l5-8.964c.089.5.143 1 .143 1.5zm8 2.286c0 .464-.125.839-.357 1.232-.554.911-1.25 1.786-1.946 2.589-3.5 4.018-8.321 6.464-13.696 6.464l1.321-2.357c5.196-.446 9.607-3.607 12.393-7.929-1.321-2.054-3.018-3.857-5.036-5.25l1.125-2c2.214 1.482 4.446 3.714 5.839 6.018.232.393.357.768.357 1.232z"></path></svg>
                                        : <svg width="24" height="24" viewBox="0 0 32 32" fill="currentColor"><path d="M29.714 17.143c-1.696-2.625-4.018-4.875-6.804-6.304a7.938 7.938 0 011.089 4.018c0 4.411-3.589 8-8 8s-8-3.589-8-8c0-1.411.375-2.804 1.089-4.018-2.786 1.429-5.107 3.679-6.804 6.304 3.054 4.714 7.982 8 13.714 8s10.661-3.286 13.714-8zm-12.857-6.857A.87.87 0 0016 9.429c-2.982 0-5.429 2.446-5.429 5.429 0 .464.393.857.857.857s.857-.393.857-.857c0-2.036 1.679-3.714 3.714-3.714a.87.87 0 00.857-.857zM32 17.143c0 .446-.143.857-.357 1.232-3.286 5.411-9.304 9.054-15.643 9.054S3.643 23.768.357 18.375C.143 18 0 17.589 0 17.143s.143-.857.357-1.232C3.643 10.518 9.661 6.857 16 6.857s12.357 3.661 15.643 9.054c.214.375.357.786.357 1.232z"></path></svg>
                                    }
                                </Button>
                            </div>
                        </Tooltip>
                    }
                />

                <Button variant='primary' type='button' onClick={props.onClickInput}>ВОЙТИ</Button>
            </form>
        </section>
    )
}

export default Login