import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import errorHandler from '../../../bll/errorHandler'
import { addUserOnExam, clearAssignedUsers, clearUnassignedUsers, deleteUserOnExam, getAssignedUsers, getUnassignedUsers } from '../../../bll/exams/exams.operations'
import { AppStateType } from '../../../bll/store'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import UserAccess from './user-access'

const UserAccessContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных

    const [valueSearchAssignedUsers, setValueSearchAssignedUsers] = React.useState<string>('') // Поиск назначенных пользователей
    const [valueSearchUnassignedUsers, setValueSearchUnassignedUsers] = React.useState<string>('') // Поиск не назначенных пользователей

    const [selectedUnassignedUserID, setSelectedUnassignedUserID] = React.useState<string>('') // Выбранный идентификатор пользователя в списке не назначенных
    const [selectedAssignedUserID, setSelectedAssignedUserID] = React.useState<string>('') // Выбранный идентификатор пользователя в списке назначенных

    React.useEffect(() => {
        if (props.isOpen) {
            setIsVisibleLoader(true)

            Promise.all([ props.getUnassignedUsers(props.examID), props.getAssignedUsers(props.examID) ]).then((result) => {
                let isGlobalError = false
    
                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })
    
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0030, "ERROR")
                if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0031, "ERROR")
                // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")
    
                setIsVisibleLoader(false)
            })
        }

        return () => {
            props.clearUnassignedUsers()
            props.clearAssignedUsers()

            setValueSearchAssignedUsers('')
            setValueSearchUnassignedUsers('')
            
            setSelectedAssignedUserID('')
            setSelectedUnassignedUserID('')
        }
    }, [props.isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Назначение пользователя на экзамен
    const addUser = () => {
        if (selectedUnassignedUserID) { 
            props.addUserOnExam(props.examID, selectedUnassignedUserID).then((result: any) => {
                switch (result?.code) {
                    case 'OK': setSelectedUnassignedUserID(''); break
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })

        }
    }

    // Удаление пользователя с экзамена
    const deleteUser = () => {
        if (selectedAssignedUserID) { 
            props.deleteUserOnExam(props.examID, selectedAssignedUserID).then((result: any) => {
                switch (result?.code) {
                    case 'OK': setSelectedAssignedUserID(''); break
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                    default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
                }
            })
        }
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleLoader, // Видимость загрузки, при получении данных

        valueSearchAssignedUsers, setValueSearchAssignedUsers, // Поиск назначенных пользователей
        valueSearchUnassignedUsers, setValueSearchUnassignedUsers, // Поиск не назначенных пользователей

        selectedUnassignedUserID, setSelectedUnassignedUserID, // Выбранный идентификатор пользователя в списке не назначенных
        selectedAssignedUserID, setSelectedAssignedUserID, // Выбранный идентификатор пользователя в списке назначенных

        addUser, // Назначение пользователя на экзамен
        deleteUser, // Удаление пользователя с экзамена
    }

    return (
        <UserAccess {...props} {...propsToComponent} />
    )
}

const mapState = (state: AppStateType) => {
    return {
        unassignedUsersList: state.exams.unassignedUsersList,
        assignedUsersList: state.exams.assignedUsersList,
    }
}

export default compose(connect(mapState, {
    getUnassignedUsers, clearUnassignedUsers,
    getAssignedUsers, clearAssignedUsers, 
    addUserOnExam, deleteUserOnExam
}))(UserAccessContainer)