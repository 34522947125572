import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ReferenceMaterial from './reference-material'
import { AppStateType } from '../../../bll/store'
import { clearReferenceMaterial, deleteReferenceMaterial, getReferenceMaterial, uploadReferenceMaterial } from '../../../bll/tests/operations'
import errorHandler from '../../../bll/errorHandler'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'

const ReferenceMaterialContainer: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleModalPasswordConfirm, setIsVisibleModalPasswordConfirm] = React.useState<boolean>(false) // Видимость окна подтверждения для ввода пароля
    
    const [selectedReferenceMaterialID, setSelectedReferenceMaterialID] = React.useState<string | undefined>() // Выбранный идентификатор варианта
    const [valueLoginPassword, setValueLoginPassword] = React.useState<string | undefined>() // Пароль оператора, для подтверждения

    React.useEffect(() => {
        if (props.isOpen) {
            setIsVisibleLoader(true)

            Promise.all([ props.getReferenceMaterial(props.testID) ]).then((result) => {
                let isGlobalError = false
    
                result.forEach(item => {
                    if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING') {
                        if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                    }
                })
    
                if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0026, "ERROR")
                // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0020, "ERROR")
                // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")
    
                setIsVisibleLoader(false)
            })
        }

        return () => {
            props.clearReferenceMaterial()
        }
    }, [props.isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Открытие окна подтверждения для ввода пароля
    const openModalPasswordConfirm = (isOpen: boolean, id: string = '') => {
        setSelectedReferenceMaterialID(id)
        setValueLoginPassword('')

        setIsVisibleModalPasswordConfirm(isOpen)
    }
    
    // Загрузка файлов на сервер
    const fileUploader = (fileList: any) => {
        fileList.forEach((file: any) => {
            props.uploadReferenceMaterial(file, props.testID, file.name).then((result: any) => {
                switch (result?.code) {
                    case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0021, "ERROR")
                    case "ERR_FILE_NOT_FOUND": return notifiMsg(props.msg.error.E0022, "ERROR")
                    case "ERR_FILE_EXTNAME": return notifiMsg(props.msg.error.E0023, "ERROR")
                    case "ERR_FILE_SIZE": return notifiMsg(props.msg.error.E0024, "ERROR")
                    default: return errorHandler(result?.code, props.msg, navigate)
                }
            })
        })
    }

    // Удаление справочного материала
    const deleteMaterial = () => {
        props.deleteReferenceMaterial(props.testID, selectedReferenceMaterialID, valueLoginPassword).then((result: any) => {
            switch (result?.code) {
                case 'OK': openModalPasswordConfirm(false); break
                case "ERR_QUERY_STRING": return notifiMsg(props.msg.error.E0014, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }
        })
    }

    
    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleLoader, // Видимость загрузки, при получении данных
        fileUploader, // Загрузка файлов на сервер

        isVisibleModalPasswordConfirm, // Видимость окна подтверждения для ввода пароля
        openModalPasswordConfirm, // Открытие окна подтверждения для ввода пароля
        valueLoginPassword, setValueLoginPassword, // Пароль оператора, для подтверждения

        deleteMaterial, // Удаление справочного материала
    }

    return (
        <ReferenceMaterial {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        testsReferenceMaterialList: state.tests.testsReferenceMaterialList
    }
}

export default compose(connect(mapState, { 
    getReferenceMaterial, clearReferenceMaterial, uploadReferenceMaterial, deleteReferenceMaterial
}))(ReferenceMaterialContainer)