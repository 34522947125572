import { ActionTypes } from "./exams.interfaces"
import { AssignedUsersListTypes, ExamInfoTypes, ExamReportListTypes, ExamsListTypes, ParticipantResultTypes, QuestionsAndAnswersTypes, ReadyTestsListTypes, ReferenceMaterialTypes, UnassignedUsersListTypes } from "./exams.types"

let initialState = { // Инициализационный State
    examsList: [] as ExamsListTypes[] | undefined,
    readyTestsList: [] as ReadyTestsListTypes[] | undefined,
    unassignedUsersList: [] as UnassignedUsersListTypes[] | undefined,
    assignedUsersList: [] as AssignedUsersListTypes[] | undefined,
    examInfo: {} as ExamInfoTypes | undefined,
    referenceMaterial: [] as ReferenceMaterialTypes[] | undefined,
    questionsAndAnswers: [] as QuestionsAndAnswersTypes[] | undefined,
    participantResult: {} as ParticipantResultTypes | undefined,
    examReportList: {} as ExamReportListTypes[] | undefined,
}

// Reducer экзамены
export const examsReducer = (state = initialState, action: ActionTypes): typeof initialState => {
    switch (action.type) {
        case 'LOGIN__LOGOUT_APP': { // Выход из приложения
            return { ...state = initialState } 
        }

        case 'EXAMS__SET_EXAMS_LIST': { // Список экзаменов
            return { ...state, examsList: action.examsList }
        }

        case 'EXAMS__SET_READY_TESTS_LIST': { // Список готовых тестов
            return { ...state, readyTestsList: action.readyTestsList }
        }

        case 'EXAMS__SET_UNASSIGNED_USERS_LIST': { // Список не назначенных пользователей
            return { ...state, unassignedUsersList: action.unassignedUsersList }
        }

        case 'EXAMS__SET_ASSIGNED_USERS_LIST': { // Список назначенных пользователей
            return { ...state, assignedUsersList: action.assignedUsersList }
        }

        case 'EXAMS__SET_EXAM_INFO': { // Информация об экзамене для Оператора экзаменатора и Участника тестирования
            return { ...state, examInfo: action.examInfo }
        }

        case 'exams__set_reference_material': { // Справочный материал по экзамену
            return { ...state, referenceMaterial: action.referenceMaterial }
        }

        case 'exams__set_questions_and_answers': { // Список вопросов и ответов участника по экзамену
            return { ...state, questionsAndAnswers: action.questionsAndAnswers }
        }

        case 'exams__set_participant_result': { // Результат участника
            return { ...state, participantResult: action.participantResult }
        }

        case 'exams__set_reports_list': { // Отчеты по экзамену
            return { ...state, examReportList: action.examReportList }
        }

        default: return state
    }
}