import React from 'react'

import { Tooltip } from 'antd'

import Button from '../../UIComponents/Button/Button'
import Drawer from '../../UIComponents/Drawer/Drawer'
import Empty from '../../UIComponents/Empty/Empty'
import Input from '../../UIComponents/Input/Input'
import Loader from '../../UIComponents/Loader/Loader'
import imgEmpty from './../../../images/empty_documents.svg'
import { ICorrectAnswers } from './correct-answers.module'
import { BeatLoader } from 'react-spinners'

// Правильные ответы по вопросу
const CorrectAnswers: React.FC<ICorrectAnswers> = (props) => {
    return (
        <Drawer size={`750px`} isOpen={props.isVisibleCorrectAnswers} placement='right'>
            <section className='drawerPrimary'>
                {props.isVisibleLoader
                    ? <Loader open={props.isVisibleLoader} color='#FF6700' title='Загрузка...' />
                    : <main className='drawerContent'>
                        <header className='drawerHeader' style={{ flexDirection: 'column' }}>
                            <h4>{props.correctAnswersHeaderPrimary}</h4>
                            <h5>{props.correctAnswersHeaderSecondary}</h5>
                        </header>

                        <section className='drawerContainer'>
                            <section className='drawerItems'>
                                {!props.localCorrectAnswers?.length
                                    ? <Empty image={imgEmpty} title='Данные отсутствуют...' color='#fff' size='200px' />
                                    : props.localCorrectAnswers.map((item) => {
                                        return (
                                            <div key={item.correctAnswerID} className='drawer-items-item_1'>
                                                <Input variant='primary' type='text' placeholder='Введите правильный ответ'
                                                    value={item.answer} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.regexCorrectAnswer(item.correctAnswerID, e.target.value)}
                                                    prefix={<Tooltip title="Порядковый номер ответа" placement='left'>{`#${item.answerNumber}`}</Tooltip>}
                                                    suffix={
                                                        props.sendCorrectAnswerID === item.correctAnswerID && props.timerSendCorrectAnswerID !== null 
                                                        ? <BeatLoader color="#FF6700" size={14} /> 
                                                        : <div>✔</div>
                                                    }
                                                />

                                                <Tooltip title="Удалить ответ" placement='left'>
                                                    <div>
                                                        <Button variant='default' size='xs' onClick={() => props.deleteCorrectAnswer(item.correctAnswerID)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z" fill="currentColor" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z" fill="currentColor" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </section>

                            <footer className='drawerFooter'>
                                <Button variant='secondary' size='md' onClick={() => props.openCorrectAnswers(false)}>
                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
                                    </svg>

                                    <span>ЗАКРЫТЬ</span>
                                </Button>

                                <Button variant='primary' size='md' onClick={props.createCorrectAnswer}>
                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.41421 17C7.63316 17.7811 6.36683 17.781 5.58579 17L6.29289 16.2929L5.58579 17L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L7 15.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z" fill="currentColor" />
                                    </svg>

                                    <span>ДОБАВИТЬ ОТВЕТ</span>
                                </Button>
                            </footer>
                        </section>
                    </main>
                }
            </section>
        </Drawer>
    )
}

export default CorrectAnswers