import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './styles/antd-styles.scss'
import './styles/index.scss'
import './styles/shared.scss'
import './styles/calendar-styles.scss'

import { store } from './bll/store'
import NotificationProvider from './components/UIComponents/Notification/NotificationProvider'
import LoginContainer from './components/login/login.container'
import MainContainer from './components/main/main.container'
import ParticipantResultContainer from './components/exams/examinator/participant-result/participant-result.container'

const root = ReactDOM.createRoot(document.getElementById('myApp') as HTMLElement)

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <NotificationProvider>
                <Routes>
                    <Route path='/' element={<LoginContainer />} />
                    <Route path='/main/*' element={<MainContainer />} />

                    <Route path='/main/exam/participant/:id' element={<ParticipantResultContainer />} />
                </Routes>
            </NotificationProvider>
        </Provider>
    </BrowserRouter>
)