import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Participant from './participant'
import { AppStateType } from '../../../bll/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationContext } from '../../UIComponents/Notification/NotificationProvider'
import errorHandler from '../../../bll/errorHandler'
import { clearExamInfo, createExamParticipant, getExamInfo } from '../../../bll/exams/exams.operations'
import Loader from '../../UIComponents/Loader/Loader'

const ParticipantContainer: React.FC<any> = (props) => {
    const getLocation = useLocation()
    const navigate = useNavigate()
    const notification = React.useContext(NotificationContext)

    const [isVisibleLoader, setIsVisibleLoader] = React.useState<boolean>(false) // Видимость загрузки, при получении данных
    const [isVisibleConfirmation, setIsVisibleConfirmation] = React.useState<boolean>(false) // Видимость окна подтверждения начать экзамен

    React.useEffect(() => {
        setIsVisibleLoader(true)

        Promise.all([ props.getExamInfo(getLocation.state?.examID) ]).then((result) => {
            let isGlobalError = false

            result.forEach(item => {
                if (item.code !== 'OK' && item.code !== 'ERR_QUERY_STRING' && item.code !== 'EXAM_STARTED') {
                    if (!isGlobalError) notifiMsg(errorHandler(item.code, props.msg, navigate), "ERROR"); isGlobalError = true
                }
            })

            if (result[0]?.code === 'EXAM_STARTED') startExam()
            if (result[0]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0032, "ERROR")
            // if (result[1]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0032, "ERROR")
            // if (result[2]?.code === 'ERR_QUERY_STRING') notifiMsg(props.msg.error.E0025, "ERROR")

            if (result[0]?.code === 'OK') setIsVisibleLoader(false)
        })

        return () => {
            props.clearExamInfo()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Системное сообщение
    const notifiMsg = (message: string, type: 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR', duration: number = 8000) => {
        notification({
            type: type,
            payload: {
                id: 1 + Math.random() * (100 - 1),
                type: type,
                duration: duration,
                message: message
            }
        })
    }

    // Кнопка приступить к сдаче экзамена
    const startExam = () => {
        props.createExamParticipant(getLocation.state.examID).then((result: any) => {
            switch (result?.code) {
                case 'OK': navigate('start', { state: { examID: getLocation.state.examID } }); break
                case "ERR_QUERY_STRING": return notifiMsg(`Ошибка при создании экзамена.<br/>Необходимо обратится к <b>Администратору</b>.`, "ERROR")
                default: return notifiMsg(errorHandler(result?.code, props.msg, navigate), "ERROR")
            }

            setIsVisibleLoader(false)
        })
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        isVisibleConfirmation, setIsVisibleConfirmation, // Видимость окна подтверждения начать экзамен

        startExam, // Кнопка приступить к сдаче экзамена
    }

    return (
        <>
            {isVisibleLoader
                ? <Loader open={isVisibleLoader} color='#FF6700' title='Загрузка...' />
                : <Participant {...props} {...propsToComponent} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        examInfo: state.exams.examInfo,
    }
}

export default compose(connect(mapState, {
    getExamInfo, clearExamInfo, createExamParticipant
}))(ParticipantContainer)