import React from 'react'
// import puppeteer from 'puppeteer'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'


import { IParticipantResult } from './participant-result.interface'
import css from './participant-result.module.scss'
import logo from './../../../../images/logo.png'

const ParticipantResult: React.FC<IParticipantResult> = (props) => {
    // const generatePDF = () => {
    //     const doc = new jsPDF('p', 'mm', 'a4')

    //     doc.addFileToVFS("Roboto.ttf", '');
    //     doc.addFont("Roboto.ttf", "Roboto", "normal");
    //     doc.setFont("Roboto");

    //     // doc.addImage(logo, 'png', 10, 20, 50, 50)
    //     doc.text("ФГБОУ ВО Вавиловский университет", 20, 20)        
    //     doc.save('example.pdf')
    // };

    // const generatePDF = async () => {
    //     const browser = await puppeteer.launch()
    //     const page = await browser.newPage()
    //     await page.setContent('<h1>Привет, это мой первый PDF документ, созданный с помощью Puppeteer!</h1>')
    //     await page.pdf({ path: 'example.pdf', format: 'A4' })
    //     await browser.close()
    // }    

    React.useEffect(() => {
        document.body.style.overflow = 'auto'
        // document.body.style.background = '#fff'

        const myBG = document.getElementById('myApp')
        // if (myBG) myBG.style.background = '#fff'

        return () => {
            document.body.style.overflow = 'hidden'
            if (myBG) myBG.style.background = '#EBEBEB'
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className={css['container']}>
            {/* <button onClick={generatePDF}>Создать PDF</button> */}
            <header className={css['header']}>
                <img src={logo} alt="ФГБОУ ВО Вавиловский университет Платформа МегаТестер" />
                <h3>ФГБОУ ВО Вавиловский университет</h3>
                <h3>Платформа МегаТестер</h3>
            </header>


            <main className={css['main']}>
                {props.isViolationParticipant
                    ? <h3 style={{ textAlign: 'center', lineHeight: '150%' }}>Вы были удалены с экзамена<br />За нарушение правил проведения</h3>
                    : props.isResultNotFound
                        ? <h3 style={{ textAlign: 'center', lineHeight: '150%' }}>По не предвиденным причинам<br />Ваш результат не найден</h3>
                        : <>
                            <h3>БЛАНК ОТВЕТА</h3>

                            <article className={css['fio']}>
                                <h4>{props.participantResult?.lastName} {props.participantResult?.firstName} {props.participantResult?.secondName}</h4>
                                <div className={css['title']}>(Ф.И.О. участника)</div>
                            </article>

                            <article className={css['code']}>
                                <h5>{props.participantResult.snils}</h5>
                                <div className={css['title']}>(Уникальный код / СНИЛС участника)</div>
                            </article>

                            <article className={css['subjectName']}>
                                <h4>{props.participantResult?.subjectName}</h4>
                                <div className={css['title']}>(вступительного испытания (экзамена) по дисциплине/направлению подготовки)</div>
                            </article>

                            <article className={css['otherData']}>
                                <section className={css['dataItems']}>
                                    <div>Дата экзамена: {new Date(props.participantResult?.examDateTimeStart).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                                    <div>Время начала экзамена: {new Date(props.participantResult?.examDateTimeStart).toLocaleString('default', { hour: 'numeric', minute: 'numeric' })}</div>
                                    <div>Длительность экзамена в минутах: {props.participantResult?.durationExam}</div>
                                </section>

                                <section className={css['dataItems']}>
                                    <div>Номер варианта: {props.participantResult?.variantNumber}{props.participantResult.basisTrainingID === 1 ? '00' : ''}</div>
                                    <div>Номер аудитории: {props.participantResult?.audience}</div>
                                </section>
                            </article>

                            <article className={css['tableAnswers']}>
                                <section className={css['tableHeader']}>
                                    <section>Номер вопроса</section>
                                    <section>Ответ</section>
                                </section>

                                <section className={css['tableBody']}>
                                    {!props.participantResult.answersList?.length
                                        ? ''
                                        : props.participantResult.answersList?.map((item, index) => {
                                            return (
                                                <section key={index} className={`${css['tableRow']}`} style={{ marginTop: `${(item?.questionNumber === 13 || item?.questionNumber === 44 || item?.questionNumber === 75) && '120px'}` }}>
                                                    <section>{item?.questionNumber}</section>
                                                    <section>{item?.answer}</section>
                                                </section>
                                            )
                                        })
                                    }
                                </section>
                            </article>

                            <footer className={`${css['footer']} ${css['print-footer']}`}>
                                <div>Абитуриент</div>

                                <div className={css['footer-signature']}>
                                    <div>(фамилия и инициалы)</div>
                                </div>

                                <div className={css['footer-signature']} style={{ textAlign: 'center' }}>
                                    <div>(подпись)</div>
                                </div>
                            </footer>
                        </>
                }
            </main>
        </section>
    )
}

export default ParticipantResult