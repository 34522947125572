import React from 'react'
import ReactDom from 'react-dom'

import css from './Modal.module.scss'

type MyType = {
    open: boolean,
    children: React.ReactNode
}

// Модальное окно
const Modal: React.FC<MyType> = ({ open, children }) => {
    React.useEffect(() => {
        switch (open) {
            case true: document.body.style.overflow = 'hidden'; break
            case false: document.body.style.overflow = 'unset'; break
        }
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

    return ReactDom.createPortal(
        <div className={`${css.wrapper} ${open ? css.open : css.close}`}>
            <div className={css.modal}>
                {children}
            </div>
        </div>, document.body
    )
}

export default Modal