import api from './api'

export default class ApiTests {

    // Получение списка тестов
    static async getTests(): Promise<any> {
        try {
            return await api.get('/tests/getTests')
        } catch (error) {
            return error
        }
    }

    // Создание (Редактирование) теста
    static async createEditTest(action: string, testID: string, subjectID: number, basisTrainingID: number, testMinutes: number, porog: number): Promise<any> {
        try {
            const data = {
                action: action,
                subjectID: subjectID,
                basisTrainingID: basisTrainingID,
                testMinutes: testMinutes,
                porog: porog
            }

            return await api.post(`/tests/createEditTest?testID=${testID}`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

    // Закрытие (Открытие) от редактирования теста
    static async lockingTest(testID: string, action: string, loginPassword: string): Promise<any> {
        try {
            return await api.put(`/tests/lockingTest?testID=${testID}&action=${action}&loginPassword=${loginPassword}`)
        } catch (error) {
            return error
        }
    }



    // Получение структуры теста
    static async getTestStructure(testID: string): Promise<any> {
        try {
            return await api.get(`/tests/getTestStructure?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Получение статистической информации по структуре теста
    static async getStatisticStructure(testID: string): Promise<any> {
        try {
            return await api.get(`/tests/getStatisticStructure?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Создание (Редактирование) структуры теста
    static async createEditTestStructure(action: string, testID: string, answerTypeID: number, questionNumber: number, maxBal: number): Promise<any> {
        try {
            const data = {
                action: action,
                testID: testID,
                answerTypeID: answerTypeID,
                questionNumber: questionNumber,
                maxBal: maxBal
            }

            return await api.post(`/tests/createEditTestStructure`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

    // Удаление вопроса в структуре и все соответствующие вопросы в вариантах
    static async deleteTestStructure(testID: string, questionNumber: number, loginPassword: string): Promise<any> {
        try {
            return await api.delete(`/tests/deleteTestStructure?testID=${testID}&questionNumber=${questionNumber}&loginPassword=${loginPassword}`)
        } catch (error) {
            return error
        }
    }

    // Получение файлов по справочному материалу
    static async getReferenceMaterial(testID: string): Promise<any> {
        try {
            return await api.get(`/tests/getReferenceMaterial?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Загрузка файла по справочному материалу
    static async uploadReferenceMaterial(file: any, testID: string, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("referenceMaterial", file)

        try {
            return await api.put(`/tests/uploadReferenceMaterial?testID=${testID}&fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }

    // Удаление файла по справочному материалу
    static async deleteReferenceMaterial(testID: string, referenceMaterialID: string, loginPassword: string): Promise<any> {
        try {
            return await api.delete(`/tests/deleteReferenceMaterial?testID=${testID}&referenceMaterialID=${referenceMaterialID}&loginPassword=${loginPassword}`)
        } catch (error) {
            return error
        }
    }

    // Получение шкалы перевода из первичного в тестовый балл
    static async getTranslationScale(testID: string): Promise<any> {
        try {
            return await api.get(`/tests/getTranslationScale?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование шкалы, тестовый балл
    static async updateTranslationScale(testID: string, data: any): Promise<any> {
        try {
            return await api.put(`/tests/updateTranslationScale?testID=${testID}`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }



    // Получение вариантов
    static async getTestVariants(testID: string): Promise<any> {
        try {
            return await api.get(`/tests/getTestVariants?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Создание варианта по тесту
    static async createTestVariant(testID: string): Promise<any> {
        try {
            return await api.post(`/tests/createTestVariant?testID=${testID}`)
        } catch (error) {
            return error
        }
    }

    // Удаление варианта и всех вопросов
    static async deleteTestVariant(testID: string, variantID: string, loginPassword: string): Promise<any> {
        try {
            return await api.delete(`/tests/deleteTestVariant?testID=${testID}&variantID=${variantID}&loginPassword=${loginPassword}`)
        } catch (error) {
            return error
        }
    }

    // Исправление ошибок по варианту
    static async changeVariantErrors(testID: string, variantID: string): Promise<any> {
        try {
            return await api.put(`/tests/changeVariantErrors?testID=${testID}&variantID=${variantID}`)
        } catch (error) {
            return error
        }
    }



    // Получение вопросов теста по варианту
    static async getTestVariantQuestions(testID: string, variantID: string): Promise<any> {
        try {
            return await api.get(`/tests/getTestVariantQuestions?testID=${testID}&variantID=${variantID}`)
        } catch (error) {
            return error
        }
    }

    // Загрузка файла по вопросу
    static async uploadQuestion(file: any, testID: string, questionID: string, subjectID: number, basisTrainingID: number, variantID: string, previousFileName: string, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("question", file)

        try {
            return await api.put(`/tests/uploadQuestion?testID=${testID}&questionID=${questionID}&subjectID=${subjectID}&basisTrainingID=${basisTrainingID}&variantID=${variantID}&previousFileName=${previousFileName}&fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }

    // Получение правильных ответов по вопросу
    static async getCorrectAnswersByQuestions(questionID: string): Promise<any> {
        try {
            return await api.get(`/tests/getCorrectAnswersByQuestions?questionID=${questionID}`)
        } catch (error) {
            return error
        }
    }

    // Создание правильного ответа по вопросу
    static async createCorrectAnswersByQuestions(testID: string, variantID: string, questionID: string): Promise<any> {
        try {
            return await api.post(`/tests/createCorrectAnswersByQuestions?testID=${testID}&variantID=${variantID}&questionID=${questionID}`)
        } catch (error) {
            return error
        }
    }

    // Удаление правильных ответов
    static async deleteCorrectAnswer(testID: string, correctAnswerID: number): Promise<any> {
        try {
            return await api.delete(`/tests/deleteCorrectAnswer?testID=${testID}&correctAnswerID=${correctAnswerID}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование правильных ответов
    static async updateCorrectAnswers(testID: string, variantID: string, questionID: string, data: any): Promise<any> {
        try {
            return await api.put(`/tests/updateCorrectAnswers?testID=${testID}&variantID=${variantID}&questionID=${questionID}`, { data }, { headers: { 'Content-Type': 'application/json' }})
        } catch (error) {
            return error
        }
    }

}