import { ParticipantsFileType, UsersListType } from './types';
import { IParticipantsList, IUsersList } from "./interfaces"


const operatorsSetUsersListAction = (usersList: Array<UsersListType> | null): IUsersList => ({ 
    type: 'OPERATORS__SET_USERS', usersList 
})

const operatorsSetParticipantsFileListAction = (participantsList: Array<ParticipantsFileType> | null): IParticipantsList => ({ 
    type: 'OPERATORS__SET_PARTICIPANTS', participantsList 
})


const operatorsActions = {
    operatorsSetUsersListAction, // Операторы
    operatorsSetParticipantsFileListAction
}

export default operatorsActions