import { ICorrectAnswersList, IStatisticStructure, ITestsList, ITestsReferenceMaterialList, ITestsStructureList, ITestsTranslationScaleList, ITestsVariantQuestionsList, ITestsVariantsList } from "./interfaces"
import { CorrectAnswersListType, StatisticStructureType, TestsListType, TestsReferenceMaterialListType, TestsStructureListType, TestsTranslationScaleListType, TestsVariantQuestionsListType, TestsVariantsListType } from './types'


const testsSetTestsListAction = (testsList: Array<TestsListType> | null): ITestsList => ({ 
    type: 'TESTS__SET_TESTS_LIST', testsList 
})

const testsSetTestsStructureListAction = (testsStructureList: Array<TestsStructureListType> | null): ITestsStructureList => ({ 
    type: 'TESTS__SET_TESTS_STRUCTURE_LIST', testsStructureList 
})

const testsSetReferenceMaterialListAction = (testsReferenceMaterialList: Array<TestsReferenceMaterialListType> | null): ITestsReferenceMaterialList => ({ 
    type: 'TESTS__SET_TESTS_REFERENCE_MATERIAL_LIST', testsReferenceMaterialList 
})

const testsSetTranslationScaleListAction = (testsTranslationScaleList: Array<TestsTranslationScaleListType> | null): ITestsTranslationScaleList => ({ 
    type: 'TESTS__SET_TESTS_TRANSLATION_SCALE_LIST', testsTranslationScaleList 
})

const testsSetTestsVariantsListAction = (testsVariantsList: Array<TestsVariantsListType> | null): ITestsVariantsList => ({ 
    type: 'TESTS__SET_TESTS_VARIANTS_LIST', testsVariantsList 
})

const testsSetTestsVariantQuestionsListAction = (testsVariantQuestionsList: Array<TestsVariantQuestionsListType> | null): ITestsVariantQuestionsList => ({ 
    type: 'TESTS__SET_TESTS_VARIANT_QUESTIONS_LIST', testsVariantQuestionsList 
})

const testsSetCorrectAnswersListAction = (correctAnswersList: Array<CorrectAnswersListType> | null): ICorrectAnswersList => ({ 
    type: 'TESTS__SET_CORRECT_ANSWERS_LIST', correctAnswersList 
})



const testsSetStatisticStructureAction = (statisticStructure: StatisticStructureType | null): IStatisticStructure => ({ 
    type: 'TESTS__SET_STATISTIC_STRUCTURE', statisticStructure 
})


const testsActions = {
    testsSetTestsListAction, // Тесты
    testsSetTestsStructureListAction, // Структура теста
    testsSetReferenceMaterialListAction, // Файлы справочного материала
    testsSetTranslationScaleListAction, // Шкала перевода из первичного в тестовый балл
    testsSetTestsVariantsListAction, // Варианты теста
    testsSetTestsVariantQuestionsListAction, // Вопросы по варианту теста
    testsSetCorrectAnswersListAction, // Правильные ответы по вопросу

    testsSetStatisticStructureAction, // Статистика по структуре теста
}

export default testsActions