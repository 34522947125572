import React from 'react'

import css from './Input.module.scss'

type MyType = {
    id?: string | undefined,
    name?: string | undefined,
    placeholder?: string | undefined,
    prefix?: React.ReactNode,
    suffix?: React.ReactNode,
    // onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    type?: React.HTMLInputTypeAttribute | undefined,
    variant: 'primary' | 'secondary' | 'default',
    size?: 'lg' | 'md' | 'sm' | 'xs',
    width?: string | number | undefined,
    value?: string | number | readonly string[] | undefined,
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
    autoComplete?: string | undefined,
    paddingRight?: string | number | undefined
    readOnly?: boolean | undefined
    disabled?: boolean | undefined
}

const Input: React.FC<MyType> = ({ id, name, placeholder, prefix, suffix, width, type, variant, size = 'lg', value, onChange, autoComplete = 'off', paddingRight, readOnly = false, disabled = false }) => {
    return (
        <div className={`${
            (variant === 'primary' && `${css.primary} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`) || 
            (variant === 'secondary' && `${css.secondary} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`) ||
            (variant === 'default' && `${css.default} ${size === 'lg' ? css.lg : size === 'md' ? css.md : size === 'sm' ? css.sm : size === 'xs' ? css.xs : ''}`)
            }`} style={{ width: width, paddingRight: paddingRight }}>
            
            {prefix}
            <input id={id} name={name} type={type} placeholder={placeholder} value={value} onChange={onChange} autoComplete={autoComplete} readOnly={readOnly} disabled={disabled} />
            {suffix}
        </div>
    )
}

export default Input